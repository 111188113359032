import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import validator from "validator";
import moment from "moment";
import {
  isPositiveBRL,
  pad,
  getAxiosAuth,
  fromBRL,
  toBRLValueOnly,
  getTokenClientId,
  getTimestamp, 
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import { MESES } from "../../../options";
import { FormErrMsg, Monetary, AutocompleteInput, BtnModalFormPatrimonio } from "../../../components";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";

function BtnModalFormContribuicao(props) {
  const clienteId = getTokenClientId();
  const [aberto, setaAberto] = useState(false);
  const [cascade, setCascade] = useState(false);
  const [dia, setaDia] = useState("");
  const [mes, setaMes] = useState(props.mes.toString());
  const [ano, setaAno] = useState(props.ano.toString());
  const [dataErro, setaDataErro] = useState(null);
  const [descricao, setaDescricao] = useState("");
  const [descricaoErro, setaDescricaoErro] = useState(null);
  const [valor, setaValor] = useState("");
  const [valorErro, setaValorErro] = useState(null);
  const [recorrente, setaRecorrente] = useState(false);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [enviarFormulario, setaEnviarFormulario] = useState(null);
  const [continuarInserindo, setaContinuarInserindo] = useState(false);
  const diaEl = useRef(null);
  const descricaoEl = useRef(null);
  const [sugestoes, setaSugestoes] = useState([]);
  const [patrimonios, setaPatrimonios] = useState([]);
  const [patrimonio, setaPatrimonio] = useState("");
  const [projetoId, setaProjetoId] = useState(null);
  const [atualizaPatrimonios, setaAtualizaPatrimonios] = useState(false);
  const [tagsOpcoes, setaTagsOpcoes] = useState("");
  const [tags, setaTags] = useState([]);
  const [wasPatrimonioUuid, setWasPatrimonioUuid] = useState(false)


  // --- eventos da lista de sugestoes ----
  useEffect(() => {
    const getSugestoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/sugestoes/contribuicoes`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        logFront(err, "getSugestoes()");
        return;
      }

      if (response && response.data && "sugestoes" in response.data) {
        setaSugestoes(response.data.sugestoes);
      }
    };

    if (aberto) {
      getSugestoes();
    }
  }, [enviarFormulario, aberto]);

  // --- eventos para o modal ---
  const limparFormulario = () => {
    // limpando mensagens de erro
    setaDataErro(null);
    setaDescricaoErro(null);
    setaValorErro(null);
    setaFormularioValido(null);
    setaFormularioErro("");
    setaEnviarFormulario(null);

    // limpando campos
    setaContinuarInserindo(false);
    setaDescricao("");
    setaValor("");
    setaMes(props.mes.toString());
    setaAno(props.ano.toString());
    setaRecorrente(false);
    // tags
    setaTags([]);
  };

  const popularFormularioEditar = () => {
    setaDia(props.dia.toString());
    setaMes(props.mes.toString());
    setaAno(props.ano.toString());
    setaDescricao(props.descricao);
    setaProjetoId(props.projetoId);
    setaValor(toBRLValueOnly(props.valor));
    setaRecorrente(!!props.recorrente);
    if (props.patrimonioId) {
      setaPatrimonio(props.patrimonioId.toString());
    }

    if (props.tags && Array.isArray(props.tags) && props.tags.length >= 1) {
      setaTags(props.tags.map((tag) => ({ label: tag.nome, value: tag.id })));
    }
  };

  const toggle = e => {
    e && e.stopPropagation();
    setaAberto(!aberto);

    if (!aberto) {
      // limpando estados para props de entrada
      limparFormulario();

      if (props.id) {
        popularFormularioEditar();
      }
    }
    setWasPatrimonioUuid(false);
  };

  useEffect(() => {
    if (aberto) {
      diaEl.current.focus();
    }
  }, [aberto]);

  useEffect(() => {
    if (continuarInserindo) {
      descricaoEl.current.focus();
    }
  }, [continuarInserindo]);

  // --- eventos para o formulário ---
  const onSubmitKeepInsertingForm = e => {
    setaContinuarInserindo(true);

    return onSubmitForm(e);
  };

  const handleSubmitCascade = (e) => {
    setaContinuarInserindo(false);
    setCascade(true);

    return onSubmitForm(e);
  }


  const onSubmitCloseForm = e => {
    setaContinuarInserindo(false);

    return onSubmitForm(e);
  };

  const onSubmitForm = e => {
    e.preventDefault();

    // validando campos
    const dataValida = validaData();
    const descricaoValida = validaDescricao();
    const valorValido = validaValor();

    if (dataValida && descricaoValida && valorValido) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioErro("Verifique os campos acima.");
      setaFormularioValido(false);
    }
  };

  useEffect(() => {
    const postContribuicao = async (axiosInstance, dados) => {
      const uri = `/clientes/${clienteId}/contribuicoes`;
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err, response);
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          // alert(err.message);
          return err.message;
        }
      }

      // limpa ou fecha modal
      if (continuarInserindo) {
        limparFormulario();
      } else {
        setaAberto(false);
      }

      props.onAdd && props.onAdd();
    };

    const putContribuicao = async (axiosInstance, dados, contribuicaoId) => {
      const uri = `/clientes/${clienteId}/contribuicoes/${contribuicaoId}`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err, response);
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          // alert(err.message);
          return err.message;
        }
      }

      setaAberto(false);
      props.onEdit && props.onEdit(props.id);
    };

    const salvaDados = async () => {
      let anoPad = pad(ano, 4);
      let mesPad = pad(mes, 2);
      let diaPad = pad(dia, 2);
      const dataContribuicao = `${anoPad}-${mesPad}-${diaPad}`;
      const valorLimpo = fromBRL(valor);

      // preparando dados para enviar
      const dados = {
        dataContribuicao,
        descricao,
        valor: valorLimpo,
        recorrente,
        patrimonioId: patrimonio || null,
        tags:
        tags.map((tag) => ({
          id: tag.__isNew__ ? null : tag.value,
          nome: tag.label,
        })) || null,
        cascade,
        projetoId
      };

      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      if (props.id) {
        await putContribuicao(axiosInstance, dados, props.id);
      } else {
        await postContribuicao(axiosInstance, dados);
      }
    };

    if (formularioValido) {
      salvaDados();
    }
  }, [enviarFormulario]);

  // ---- eventos para os campos de data ----
  const validaData = () => {
    if (validator.isEmpty(dia, { ignore_whitespace: true })) {
      setaDataErro("Dia obrigatório.");
      return false;
    }

    if (validator.isEmpty(ano, { ignore_whitespace: true })) {
      setaDataErro("Ano é obrigatório.");
      return false;
    }

    if (!validator.isInt(dia, { min: 1, max: 31 })) {
      setaDataErro("Dia inválido.");
      return false;
    }

    if (mes < 1 || mes > 12) {
      setaDataErro("Mês inválido.");
      return false;
    }

    if (!validator.isInt(ano)) {
      setaDataErro("Ano inválido.");
      return false;
    }

    let anoPad = pad(ano, 4);
    let mesPad = pad(mes, 2);
    let diaPad = pad(dia, 2);

    const data = `${anoPad}-${mesPad}-${diaPad}`;
    if (!moment(data, "YYYY-MM-DD", true).isValid()) {
      setaDataErro("Data inválida.");
      return false;
    }

    setaDataErro(false);
    return true;
  };

  useEffect(() => {
    if (dataErro !== null) {
      validaData();
    }
  }, [dia, mes, ano]);

  // --- eventos para o campo Dia ---
  const onChangeDia = e => {
    setaDia(e.target.value);
  };

  const onBlurDia = () => {
    validaData();
  };

  // --- eventos para o campo Mes ---
  const onChangeMes = e => {
    setaMes(e.target.value);
  };

  // --- eventos para o campo Ano ---
  const onChangeAno = e => {
    setaAno(e.target.value);
  };

  const onBlurAno = e => {
    validaData();
  };

  // --- eventos para o campo Descrição ---
  const validaDescricao = () => {
    if (validator.isEmpty(descricao, { ignore_whitespace: true })) {
      setaDescricaoErro("Descrição obrigatória.");
      return false;
    }

    if (!validator.isLength(descricao, { min: 3 })) {
      setaDescricaoErro("Descrição muito curta. Mínimo 3 caracteres.");
      return false;
    }

    if (!validator.isLength(descricao, { max: 255 })) {
      setaDescricaoErro("Descrição muito longa. Máximo 255 caracteres.");
      return false;
    }

    setaDescricaoErro(false);
    return true;
  };

  const onChangeDescricao = e => {
    setaDescricao(e.target.value);
  };

  const onBlurDescricao = () => {
    validaDescricao();
  };

  useEffect(() => {
    if (descricaoErro !== null) {
      validaDescricao();
    }
  }, [descricao]);

  // --- eventos para o campo Valor ---
  const validaValor = () => {
    if (validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaValorErro("Valor obrigatório.");
      return false;
    }

    if (!isPositiveBRL(valor)) {
      setaValorErro("Valor mometário inválido.");
      return false;
    }

    setaValorErro(false);
    return true;
  };

  const onChangeValor = e => {
    setaValor(e.target.value);
  };

  const onBlurValor = () => {
    validaValor();
  };

  useEffect(() => {
    if (valorErro !== null) {
      validaValor();
    }
  }, [valor]);

   // --- eventos para o campo Patrimônio ---
   const getPatrimonios = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/patrimonios`;
    let response;
    let dados = {
      filtroMes: mes,
      filtroAno: ano,
      wasPatrimonioUuid: wasPatrimonioUuid,
      patrimonioVinculadoUuid: props.patrimonioUuid
    };

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (err.response) {
        response = err.response;
      }
      console.error(err, response);
      logFront(err, "getPatrimonios()");
      return;
    }

    if (response && response.data && "patrimonios" in response.data) {
      const novosPatrimonios = response.data.patrimonios.map((p) => {
        const dataPatrimonio = new Date(p.dataPatrimonio);
        const mesPatrimonio = dataPatrimonio.getUTCMonth() + 1; // Adiciona 1 para corresponder a 1 a 12
        const anoPatrimonio = dataPatrimonio.getUTCFullYear();

        if (!(anoPatrimonio === parseInt(ano, 10) && mesPatrimonio === parseInt(mes, 10))) {
          setaPatrimonio(p.id.toString());
        }

        return {
          id: p.id.toString(),
          descricao: p.descricao,
          uuid: p.uuid.toString(),
          dataPatrimonio: p.dataPatrimonio,
          patrimonioAproximado: !(anoPatrimonio === parseInt(ano, 10) && mesPatrimonio === parseInt(mes, 10)),
        };
      });
      
      setaPatrimonios(novosPatrimonios);
      if (patrimonio && !novosPatrimonios.find((p) => p.id === patrimonio)) {
        setaPatrimonio("");
      }
    }
  }
   useEffect(() => {
    if (aberto || atualizaPatrimonios) {
      getPatrimonios();
    }
  }, [wasPatrimonioUuid,enviarFormulario, aberto, mes, ano, atualizaPatrimonios]);

  useEffect(() => {
    if (atualizaPatrimonios) {
      setaPatrimonio(patrimonios.find(p => p.descricao === atualizaPatrimonios).id);
    }
  }, [patrimonios])



  // --- eventos para o campo Recorrente ---
  const onChangeRecorrente = e => {
    setaRecorrente(e.target.checked);
  };

  //TAGS
  useEffect(() => {
    const getTagsOpcoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/tags`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        logFront(err, "getTagsOpcoes()");
        return;
      }

      if (response && response.data && "tags" in response.data) {
        const novasTagsOpcoes = response.data.tags.map((tag) => ({
          value: tag.id,
          label: tag.nome,
        }));

        setaTagsOpcoes(novasTagsOpcoes);
      }
    };

    if (aberto) {
      getTagsOpcoes();
    }
  }, [enviarFormulario, aberto]);

  const onChangeTags = (newValue, actionMeta) => {
    if (newValue === null) {
      newValue = [];
    }
    setaTags(newValue);
  };

  //TAGS
  useEffect(() => {
    const getTagsOpcoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/tags`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        logFront(err, "getTagsOpcoes()");
        return;
      }

      if (response && response.data && "tags" in response.data) {
        const novasTagsOpcoes = response.data.tags.map((tag) => ({
          value: tag.id,
          label: tag.nome,
        }));

        setaTagsOpcoes(novasTagsOpcoes);
      }
    };

    if (aberto) {
      getTagsOpcoes();
    }
  }, [enviarFormulario, aberto]);

  //PATRIMONIOS
  useEffect(() => {
    if (atualizaPatrimonios) {
      setaPatrimonio(patrimonios.find(p => p.descricao === atualizaPatrimonios).id);
    }
  }, [enviarFormulario,patrimonios])

  const onChangePatrimonio =  async (e) => {
    setaPatrimonio(e.target.value);
    setWasPatrimonioUuid(true);
  };

  const onClickBtnAdicionarPatrimono = (e) => {
    setaAtualizaPatrimonios(e);
  };


  // --- elementos ---
  const trigger = props.id ? (
    <Button size="sm" style={{ marginRight: 5 }} onClick={toggle}>
      <i className="fas fa-pen" />
    </Button>
  ) : (
    <Button color="primary" size="sm" className="float-right" onClick={toggle}>
      <i className="fas fa-plus" /> Adicionar
    </Button>
  );

  return (
    <Fragment>
      {trigger}
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>
          {props.id ? "Editar Investimento Mensal" : "Adicionar Investimento Mensal"}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <Row form>
              <Col xs={3}>
                <FormGroup>
                  <Label>Dia</Label>
                  <Input
                    type="text"
                    value={dia}
                    onChange={onChangeDia}
                    onBlur={onBlurDia}
                    valid={dataErro === false}
                    invalid={!!dataErro}
                    innerRef={diaEl}
                  />
                  <FormErrMsg>{dataErro}</FormErrMsg>
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label>Mês</Label>
                  <Input
                    type="select"
                    value={mes}
                    onChange={onChangeMes}
                    valid={dataErro === false}
                    invalid={!!dataErro}
                    disabled={recorrente === true || !!projetoId}
                  >
                    {MESES.map((mes, i) => (
                      <option value={i + 1} key={`mes_opt_${i}`}>
                        {mes}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col xs={3}>
                <Label>Ano</Label>
                <Input
                  type="text"
                  value={ano}
                  onChange={onChangeAno}
                  onBlur={onBlurAno}
                  valid={dataErro === false}
                  invalid={!!dataErro}
                  disabled={recorrente === true || !!projetoId}
                />
              </Col>
            </Row>
            <FormGroup>
              <Label>Descrição</Label>
              <AutocompleteInput
                type="text"
                disabled={!!projetoId}
                value={descricao}
                onChange={onChangeDescricao}
                onBlur={onBlurDescricao}
                valid={descricaoErro === false}
                invalid={!!descricaoErro}
                maxLength={255}
                innerRef={descricaoEl}
                options={sugestoes}
              />
              <FormErrMsg>{descricaoErro}</FormErrMsg>
            </FormGroup>
            <Row form>
              <Col sm={8}>
                <FormGroup>
                  <Label>Valor</Label>
                  <Monetary
                    value={valor}
                    onChange={onChangeValor}
                    onBlur={onBlurValor}
                    valid={valorErro === false}
                    invalid={!!valorErro}
                  />
                  <FormErrMsg>{valorErro}</FormErrMsg>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      disabled={!!projetoId}
                      checked={!!recorrente}
                      onChange={onChangeRecorrente}
                    />
                    <Label check>Recorrente</Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            {!projetoId && (<>
              <FormGroup>
                <Label>Tags</Label>
                <CreatableSelect
                  isMulti
                  options={tagsOpcoes}
                  onChange={onChangeTags}
                  value={tags}
                  placeholder="Selecione..."
                  noOptionsMessage={() => "Crie uma tag!"}
                  disabled={!!projetoId}
                  formatCreateLabel={(t) => (
                    <span>
                      Criar nova tag <strong>{t}</strong>...
                    </span>
                  )}
                ></CreatableSelect>
              </FormGroup>
              <FormGroup>
                <Label>Vinculada a um patrimônio</Label>
                <Row>
                  <Col sm={10}>
                    <Input
                      type="select"
                      value={patrimonio}
                      onChange={onChangePatrimonio}
                    >
                      <option value="">
                        {props.patrimonioUuid ? "Nenhum" : "Selecione"}
                      </option>
                      {patrimonios.map((p) => (
                        <option value={p.id} key={`patrimonio_${p.id}`}>
                          {p.descricao.length > 30 ? `${p.descricao.substring(0, 30)}...` : p.descricao}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col sm={2}>
                    <BtnModalFormPatrimonio
                      mes={mes}
                      ano={ano}
                      onAdd={onClickBtnAdicionarPatrimono}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </>)}
            {formularioValido === false && (
              <Alert color="danger">
                Não foi possível salvar contribuição. {formularioErro}
              </Alert>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          {!props.id && (
            <Fragment>
              <Button color="primary" onClick={onSubmitCloseForm}>
                Salvar e fechar
              </Button>
              <Button color="primary" onClick={onSubmitKeepInsertingForm}>
                Salvar e adicionar outra
              </Button>
            </Fragment>
          )}
          {props.id && (
            <Button color="primary" onClick={onSubmitCloseForm}>
              Salvar
            </Button>
          )}
          {props.id && props.recorrente && !props.projetoId && (
            <Button color="primary" onClick={handleSubmitCascade}>
              Salvar Para Todas as Futuras
            </Button>
          )}
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalFormContribuicao.propTypes = {
  mes: PropTypes.number.isRequired,
  ano: PropTypes.number.isRequired,
  dia: PropTypes.number,
  descricao: PropTypes.string,
  recorrente: PropTypes.bool,
  valor: PropTypes.number,
  id: PropTypes.number,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  patrimonioId: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.object),
};
//console
export default withRouter(BtnModalFormContribuicao);
