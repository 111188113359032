import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Alert,
} from "reactstrap";
import {
  FormErrMsg,
  FieldCPF,
  FieldDate,
  FieldTelefoneFixo,
  FieldTelefoneCelular,
} from "../../../components";
import { TextMask, InputAdapter } from "react-text-mask-hoc";
import {
  getAxiosAuth,
  toISO8601,
  fromISO8601,
  isDateValid,
  getTimestamp,
  getClienteId,
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import {
  SEXO,
  ESTADOS_BRASILEIROS_OPTS,
  TIPOS_TELEFONE,
} from "../../../options";
import validator from "validator";
import validarCpf from "validar-cpf";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import ReactImagePickerEditor from 'react-image-picker-editor';
import 'react-image-picker-editor/dist/index.css'

function MeuCadastro(props) {
  const clienteId = getClienteId();
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [nomeCompleto, setaNomeCompleto] = useState("");
  const [nomeCompletoErro, setaNomeCompletoErro] = useState(null);
  const [cpf, setaCpf] = useState("");
  const [cpfErro, setaCpfErro] = useState(null);
  const [dataNascimento, setaDataNascimento] = useState("");
  const [dataNascimentoErro, setaDataNascimentoErro] = useState(null);
  const [ocupacao, setaOcupacao] = useState("");
  const [sexo, setaSexo] = useState(0);
  const [sexoErro, setaSexoErro] = useState(null);
  const [cep, setaCep] = useState("");
  const [endereco, setaEndereco] = useState("");
  const [numero, setaNumero] = useState("");
  const [complemento, setaComplemento] = useState("");
  const [bairro, setaBairro] = useState("");
  const [cidade, setaCidade] = useState("");
  const [uf, setaUf] = useState("");
  const [enviarFormulario, setaEnviarFormulario] = useState();
  const [telefones, setaTelefones] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [initialAvatar, setInitialAvatar] = useState(null);

  const NOVO_TELEFONE = { tipo: "Celular", numero: "" };

  const axiosInstance = getAxiosAuth(() => {
    props.history.push("/login");
  });

  const onSubmitForm = (e) => {
    e.preventDefault();

    const nomeCompletoValido = validaNomeCompleto();
    const cpfValido = validaCpf();
    const dataNascimentoValida = validaDataNascimento();
    const sexoValido = validaSexo();

    if (
      clienteId &&
      nomeCompletoValido &&
      cpfValido &&
      dataNascimentoValida &&
      sexoValido
    ) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  useEffect(() => {
    const putDados = async () => {
      const dataNascimentoISO8601 = toISO8601(dataNascimento);

      const telefonesSalvar = telefones.filter(
        (telefone) =>
          !validator.isEmpty(telefone.numero, { ignore_whitespace: true })
      );

      const dados = {
        nomeCompleto,
        cpf,
        dataNascimento: dataNascimentoISO8601,
        ocupacao,
        sexo,
        cep,
        endereco,
        numero,
        complemento,
        bairro,
        cidade,
        uf,
        telefones: telefonesSalvar,
        avatar
      };

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
          logFront(err, "putDados()");
        } else {
          
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
    
          console.error(err);
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        window.location.reload();
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";
        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        
        const responseApp = await logApp(axiosInstance, response);
          await logClient(axiosInstance, null, response, responseApp.data.trace_id, uri, response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
    
        // alert(response.message);
        return response.message;
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      putDados();
    }
  }, [enviarFormulario]);

  useEffect(() => {
    const obtemDados = async () => {
      const uri = `/clientes/${clienteId}`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
    
          // alert(err.message);
          return err.message;
        }
      }

      const dados = response.data;

      setaNomeCompleto(dados.nomeCompleto || "");
      setaCpf(dados.cpf || "");
      setaDataNascimento(fromISO8601(dados.dataNascimento) || "");
      setaOcupacao(dados.ocupacao || "");
      setaSexo(dados.sexo || 0);
      setaCep(dados.cep || "");
      setaEndereco(dados.endereco || "");
      setaNumero(dados.numero || "");
      setaComplemento(dados.complemento || "");
      setaBairro(dados.bairro || "");
      setaCidade(dados.cidade || "");
      setaUf(dados.uf || "");
      setInitialAvatar(dados.avatar 
        ? `data:image/jpeg;base64,${dados.avatar}` 
        : null
      )

      if (dados.telefones.length === 0) {
        setaTelefones([{ ...NOVO_TELEFONE }]);
      } else {
        setaTelefones(dados.telefones);
      }
    };

    obtemDados();
  }, []);

  const validaNomeCompleto = () => {
    if (validator.isEmpty(nomeCompleto, { ignore_whitespace: true })) {
      setaNomeCompletoErro("Nome completo obrigatório.");
      return false;
    }

    setaNomeCompletoErro(false);
    return true;
  };

  const onChangeNomeCompleto = (e) => {
    setaNomeCompleto(e.target.value);
  };

  const onBlurNomeCompleto = () => {
    validaNomeCompleto();
  };

  const validaCpf = () => {
    if (
      !validator.isEmpty(cpf, { ignore_whitespace: true }) &&
      !validarCpf(cpf)
    ) {
      setaCpfErro("CPF inválido.");
      return false;
    }

    setaCpfErro(false);
    return true;
  };

  const onChangeCpf = (e) => {
    setaCpf(e.target.value);
  };

  const onBlurCpf = () => {
    validaCpf();
  };

  const validaDataNascimento = () => {
    if (
      !validator.isEmpty(dataNascimento, { ignore_whitespace: true }) &&
      !isDateValid(dataNascimento)
    ) {
      setaDataNascimentoErro("Data inválida.");
      return false;
    }

    setaDataNascimentoErro(false);
    return true;
  };

  const onChangeDataNascimento = (e) => {
    setaDataNascimento(e.target.value);
  };

  const onBlurDataNascimento = () => {
    validaDataNascimento();
  };

  const onChangeOcupacao = (e) => {
    setaOcupacao(e.target.value);
  };

  const validaSexo = () => {
    if (sexo !== SEXO.MASCULINO && sexo !== SEXO.FEMININO) {
      setaSexoErro("Campo obrigatório.");
      return false;
    }

    setaSexoErro(false);
    return true;
  };

  const onChangeSexo = (valor, e) => {
    setaSexo(valor);
  };

  const onChangeCep = (e) => {
    setaCep(e.target.value);
  };

  const onChangeEndereco = (e) => {
    setaEndereco(e.target.value);
  };

  const onChangeNumero = (e) => {
    setaNumero(e.target.value);
  };

  const onChangeComplemento = (e) => {
    setaComplemento(e.target.value);
  };

  const onChangeBairro = (e) => {
    setaBairro(e.target.value);
  };

  const onChangeCidade = (e) => {
    setaCidade(e.target.value);
  };

  const onChangeUf = (selectedOption) => {
    setaUf(selectedOption.value);
  };

  const onClickBtnAdicionarTelefone = (e) => {
    e.preventDefault();

    const novosTelefones = [...telefones, { ...NOVO_TELEFONE }];

    setaTelefones(novosTelefones);
  };

  const onClickBtnRemoverTelefone = (idx, e) => {
    e.preventDefault();

    let novosTelefones = [...telefones];

    if (novosTelefones.length === 1) {
      novosTelefones = [{ ...NOVO_TELEFONE }];
    } else {
      novosTelefones.splice(idx, 1);
    }

    setaTelefones(novosTelefones);
  };

  const onChangeTelefoneTipo = (idx, e) => {
    if (!telefones[idx]) return;

    let novosTelefones = [...telefones];

    novosTelefones[idx].tipo = e.target.value;

    setaTelefones(novosTelefones);
  };

  const onChangeTelefoneNumero = (idx, e) => {
    if (!telefones[idx]) return;

    let novosTelefones = [...telefones];

    novosTelefones[idx].numero = e.target.value;

    setaTelefones(novosTelefones);
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <Row form>
          <Col md={12} lg={12} className="d-flex align-items-stretch">
            <Card style={{ flexGrow: 1 }}>
              <CardHeader>
                <i className="far fa-edit" />
                <strong>Dados pessoais</strong>
              </CardHeader>
              <CardBody>
                <Row form>
                  <FormGroup>
                    <Label htmlFor="nome">
                      Avatar
                    </Label>
                    <ReactImagePickerEditor
                      config={{
                        borderRadius: '50%',
                        width: 150,
                        height: 150,
                        objectFit: 'cover',
                        compressInitial: null,
                        hideDownloadBtn: true,
                        hideEditBtn: true
                      }}
                      imageSrcProp={initialAvatar}
                      imageChanged={(value) => setAvatar(value)}
                    />
                  </FormGroup>
                </Row>
                <Row form>
                  <Col sm={6} md={6} lg={6} xl={3}>
                    <FormGroup>
                      <Label htmlFor="nome" className="required">
                        Nome
                      </Label>
                      <Input
                        type="text"
                        id="nome"
                        placeholder="Nome completo"
                        value={nomeCompleto}
                        onChange={onChangeNomeCompleto}
                        onBlur={onBlurNomeCompleto}
                        valid={nomeCompletoErro === false}
                        invalid={!!nomeCompletoErro}
                      />
                      {nomeCompletoErro && (
                        <FormErrMsg>{nomeCompletoErro}</FormErrMsg>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={3}>
                    <FormGroup>
                      <Label htmlFor="cpf">CPF</Label>
                      <FieldCPF
                        id="cpf"
                        className="form-control"
                        value={cpf}
                        onChange={onChangeCpf}
                        onBlur={onBlurCpf}
                        valid={cpfErro === false}
                        invalid={!!cpfErro}
                      />
                      {cpfErro && <FormErrMsg>{cpfErro}</FormErrMsg>}
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={3}>
                    <FormGroup>
                      <Label htmlFor="nascimento">Data de nascimento</Label>
                      <FieldDate
                        value={dataNascimento}
                        onChange={onChangeDataNascimento}
                        onBlur={onBlurDataNascimento}
                        valid={dataNascimentoErro === false}
                        invalid={!!dataNascimentoErro}
                      />
                      {dataNascimentoErro && (
                        <FormErrMsg>{dataNascimentoErro}</FormErrMsg>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={3}>
                    <FormGroup>
                      <Label htmlFor="ocupacao">Ocupação</Label>
                      <Input
                        type="text"
                        id="ocupacao"
                        value={ocupacao}
                        onChange={onChangeOcupacao}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm={6} md={6} lg={6} xl={4}>
                    <FormGroup>
                      <Label className="required">Sexo</Label>
                      <FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="sexo"
                            id="sexo-m"
                            value={SEXO.MASCULINO}
                            checked={sexo === SEXO.MASCULINO}
                            onChange={onChangeSexo.bind(this, SEXO.MASCULINO)}
                          />
                          <Label check htmlFor="sexo-m">
                            Masculino
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Input
                            type="radio"
                            name="sexo"
                            id="sexo-f"
                            value={SEXO.FEMININO}
                            checked={sexo === SEXO.FEMININO}
                            onChange={onChangeSexo.bind(this, SEXO.FEMININO)}
                          />
                          <Label check htmlFor="sexo-f">
                            Feminino
                          </Label>
                        </FormGroup>
                        {sexoErro && <FormErrMsg>{sexoErro}</FormErrMsg>}
                      </FormGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row form>
          <Col>
            <Card>
              <CardHeader>
                <i className="fas fa-map-marker" />
                <strong>Endereço</strong>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col sm={3} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <Label htmlFor="cep">CEP</Label>
                      <TextMask
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        Component={InputAdapter}
                        className="form-control"
                        id="cep"
                        placeholder="99999-999"
                        value={cep}
                        onChange={onChangeCep}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={9} md={10} lg={10} xl={5}>
                    <FormGroup>
                      <Label htmlFor="endereco">Endereço</Label>
                      <Input
                        type="text"
                        id="endereco"
                        placeholder="Logradouro"
                        value={endereco}
                        onChange={onChangeEndereco}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={3} md={2} lg={2} xl={1}>
                    <FormGroup>
                      <Label htmlFor="numero">Número</Label>
                      <Input
                        type="text"
                        id="numero"
                        value={numero}
                        onChange={onChangeNumero}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={9} md={5} lg={5} xl={3}>
                    <FormGroup>
                      <Label htmlFor="complemento">Complemento</Label>
                      <Input
                        type="text"
                        id="complemento"
                        value={complemento}
                        onChange={onChangeComplemento}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={5} lg={5} xl={2}>
                    <FormGroup>
                      <Label htmlFor="bairro">Bairro</Label>
                      <Input
                        type="text"
                        id="bairro"
                        value={bairro}
                        onChange={onChangeBairro}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={3}>
                    <FormGroup>
                      <Label htmlFor="cidade">Cidade</Label>
                      <Input
                        type="text"
                        id="cidade"
                        value={cidade}
                        onChange={onChangeCidade}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={2}>
                    <FormGroup>
                      <Label htmlFor="estado">Estado</Label>
                      <Select
                        value={ESTADOS_BRASILEIROS_OPTS.find(
                          (opcao) => opcao.value === uf
                        )}
                        options={ESTADOS_BRASILEIROS_OPTS}
                        placeholder="Selecione"
                        onChange={onChangeUf}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row form>
          <Col>
            <Card>
              <CardHeader>
                <i className="fas fa-phone" />
                <strong>Telefones</strong>
              </CardHeader>
              <CardBody>
                {telefones.map((telefone, idx) => (
                  <Row
                    form
                    key={`telefone_${idx}`}
                    style={{ marginBottom: 15 }}
                  >
                    <Col xs={6} sm={2} lg={2} xl={2}>
                      <Input
                        type="select"
                        value={telefone.tipo}
                        onChange={onChangeTelefoneTipo.bind(this, idx)}
                      >
                        {TIPOS_TELEFONE.map((tipo) => (
                          <option key={`telefone_${idx}_${tipo}`}>
                            {tipo}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col xs={6} sm={3} lg={3} xl={2}>
                      {telefone.tipo === "Celular" ? (
                        <FieldTelefoneCelular
                          className="form-control"
                          placeholder="(99) 99999-9999"
                          value={telefone.numero}
                          onChange={onChangeTelefoneNumero.bind(this, idx)}
                        />
                      ) : (
                        <FieldTelefoneFixo
                          className="form-control"
                          placeholder="(99) 9999-9999"
                          value={telefone.numero}
                          onChange={onChangeTelefoneNumero.bind(this, idx)}
                        />
                      )}
                    </Col>
                    <Col xs={6} sm={2} lg={2}>
                      <ButtonGroup>
                        <Button
                          color="primary"
                          onClick={onClickBtnAdicionarTelefone}
                        >
                          <i className="fas fa-plus" />
                        </Button>
                        <Button
                          color="secondary"
                          onClick={onClickBtnRemoverTelefone.bind(this, idx)}
                        >
                          <i className="fas fa-minus" />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row form style={{ paddingBottom: 20 }}>
          <Col>
            {formularioValido === false && (
              <Alert color="danger" style={{ display: "inline-block" }}>
                Não foi possível salvar. {formularioErro}
              </Alert>
            )}
            <Button color="primary" style={{ display: "block" }}>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default withRouter(MeuCadastro);
