import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Jumbotron, Button, Container, Table, Alert } from "reactstrap";
import { getAxiosAuth, getTokenClientId, toBRL, logFront } from "../../../utils";
import BtnModalRemoverMeta from "./BtnModalRemoverMeta";
import moment from "moment";
import "moment/locale/pt-br";
import { GraficoAumentarReceitas } from "../../../components";

function AumentarReceitas(props) {
  const [carregandoDados, setaCarregandoDados] = useState(true);
  const [erroObterDados, setaErroObterDados] = useState(false);
  const [carregouDados, setaCarregouDados] = useState(false);
  const [receitas, setaReceitas] = useState();
  const [valorMetaTotal, setaValorMetaTotal] = useState();
  const [mesesNomes, setaMesesNomes] = useState([]);
  const [totalReceitasMedia, setaTotalReceitasMedia] = useState(0.0);

  let salvouSucesso = false;

  if (props.history && props.history.location && props.history.location.state) {
    salvouSucesso = props.history.location.state.salvouSucesso;
  }

  useEffect(() => {
    if (salvouSucesso) {
      toast("Meta salva com sucesso!");
    }
  }, []);

  const clickIniciarInputHandler = (event) => {
    event.stopPropagation();
    const { history } = props;
    history.push("/equilibre/aumentar-receitas/especifique");
  };

  const getMetaAtual = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/aumento`;

    let dataInicioProjeto,
      response = await axiosInstance.get(uri);

    if (response && response.status === 200) {
      setaCarregouDados(true);
      setaValorMetaTotal(response.data.valorMetaTotal);
      dataInicioProjeto = response.data.inicio;
    }

    return dataInicioProjeto;
  };

  const processaReceitas = (receitasLiquidas) => {
    const receitas = receitasLiquidas.reduce((acc, receita) => {
      let registro = acc.find(
        (registro) =>
          registro.ano === receita.ano && registro.mes === receita.mes
      );

      if (registro) {
        registro.valor += receita.valor_liquido;
      } else {
        acc.push({
          ano: receita.ano,
          mes: receita.mes,
          valor: receita.valor_liquido,
        });
      }
      return acc;
    }, []);

    setaReceitas(receitas);

    let meses = receitasLiquidas.reduce((acc, receita) => {
      let registro = acc.find(
        (registro) =>
          registro.ano === receita.ano && registro.mes === receita.mes
      );

      if (!registro) {
        acc.push({ ano: receita.ano, mes: receita.mes });
      }
      return acc;
    }, []);

    let mesesNomes = meses.map((data) =>
      moment()
        .date(1)
        .month(data.mes - 1)
        .year(data.ano)
        .format("MMMM")
    );
    setaMesesNomes(mesesNomes);

    if (meses.length > 0) {
      let totalReceitasMedia =
        receitas.reduce((acc, receita) => receita.valor + acc, 0.0) /
        meses.length;
      setaTotalReceitasMedia(totalReceitasMedia);
    }
  };

  const getReceitas = async (dataInicioProjeto) => {
    let filtroDataFinal = moment().endOf("month").format("YYYY-MM-DD");
    let filtroDataInicial = moment
      .max(moment(dataInicioProjeto), moment().date(1).subtract(6, "M"))
      .format("YYYY-MM-DD");

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();

    const uri = `/clientes/${clienteId}/relatorios/dre`;
    const dados = {
      filtroDataInicial,
      filtroDataFinal,
    };

    let response = await axiosInstance.get(uri, { params: dados });

    if (
      response &&
      response.status === 200 &&
      "receitasLiquidas" in response.data
    ) {
      processaReceitas(response.data.receitasLiquidas);
    }
  };

  const getDados = async () => {
    setaErroObterDados(false);
    setaCarregandoDados(true);

    setaCarregouDados(false);
    setaReceitas(null);
    setaValorMetaTotal(null);

    let dataInicioProjeto;

    try {
      dataInicioProjeto = await getMetaAtual();
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return;
      }
      console.error(error);
      logFront(error, "getDados()");
      setaErroObterDados(error.response ? error.response.status : true);
    } finally {
      setaCarregandoDados(false);
    }

    setaCarregandoDados(true);
    try {
      await getReceitas(dataInicioProjeto);
    } catch (error) {
      console.error(error);
      logFront(error, "setaCarregandoDados()");
      setaErroObterDados(error.response ? error.response.status : true);
    } finally {
      setaCarregandoDados(false);
    }
  };

  useEffect(() => {
    getDados();
  }, []);

  const deleteMetaInputHandler = () => {
    toast("Meta removida com sucesso!");
    getDados();
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 2999 }}
      />

      {carregandoDados && (
        <Container>
          <div className="shimmer line br animate"></div>
          <div className="shimmer line br animate"></div>
          <div className="shimmer line br animate"></div>
          <div className="shimmer chart br animate"></div>
        </Container>
      )}

      {!carregandoDados && !erroObterDados && !carregouDados && (
        <Container>
          <Jumbotron>
            <h1 className="display-4">Aumentar receitas</h1>
            <p className="lead">
              Equilibre o seu padrão de vida e aumente a sua capacidade de
              poupança!
            </p>
            <hr className="my-2" />
            <p>
              Defina agora um meta para as suas receitas e começe a monitorar.
            </p>
            <p className="lead">
              <Button
                size="lg"
                color="primary"
                onClick={clickIniciarInputHandler}
              >
                Definir meta
              </Button>
            </p>
          </Jumbotron>
        </Container>
      )}

      {!carregandoDados && !erroObterDados && carregouDados && (
        <Container>
          <h4 className="text-center mb-3">Aumentar Receitas</h4>

          <Table size="sm" striped bordered>
            <thead>
              <tr>
                <th></th>
                {mesesNomes.map((mesNome) => (
                  <th className="text-right" key={`col_${mesNome}`}>
                    {mesNome}
                  </th>
                ))}
                <th className="text-right table-warning">Média</th>
                <th
                  className="text-right text-nowrap table-primary"
                  style={{ minWidth: "20%", width: 200 }}
                >
                  Meta
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="align-middle">Total receitas líquidas</th>
                {receitas &&
                  receitas.map((receita, idx) => (
                    <td
                      className="align-middle text-nowrap text-right"
                      key={`col_total_mes_${idx}`}
                    >
                      {toBRL(receita.valor)}
                    </td>
                  ))}
                <td className="align-middle text-nowrap text-right table-warning">
                  {toBRL(totalReceitasMedia)}
                </td>
                <td className="align-middle text-nowrap text-right table-primary">
                  {toBRL(valorMetaTotal)}
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="text-right mb-3">
            <Button color="primary" onClick={clickIniciarInputHandler}>
              Editar meta
            </Button>
            <BtnModalRemoverMeta onDelete={deleteMetaInputHandler} />
          </div>

          <GraficoAumentarReceitas
            valorMetaTotal={valorMetaTotal}
            receitasMensais={receitas}
          />
        </Container>
      )}

      {erroObterDados && (
        <>
          <Alert color="danger" style={{ display: "inline-block" }}>
            Não foi possível obter suas despesas.
            {erroObterDados === true
              ? " Problema com a conexão."
              : ` Problema interno no servidor (código ${erroObterDados}).`}
          </Alert>
          <p>
            <Button onClick={() => getDados()} color="primary">Tentar novamente</Button>
          </p>
        </>
      )}
    </div>
  );
}

export default withRouter(AumentarReceitas);
