import React, { useState, useRef, useEffect, Fragment } from "react";
import { Container, Table, Badge } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { getTokenClientId, getAxiosAuth, logApp, logClient, logFront } from "../../../utils";
import { withRouter } from "react-router-dom";
import BtnModalFormTag from "./BtnModalFormTag";
import BtnModalRemoverTag from "./BtnModalRemoverTag";

function Tags(props) {
  const clienteId = getTokenClientId();
  const [tags, setaTags] = useState([]);
  const [inseriu, setaInseriu] = useState(null);
  const [atualizou, setaAtualizou] = useState(null);
  const [removeu, setaRemoveu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isFirstRun = useRef(true);

  const onAdd = () => {
    setaInseriu(new Date());
  };

  const onEdit = () => {
    setaAtualizou(new Date());
  };

  const onDelete = () => {
    setaRemoveu(new Date());
  };

  const getTags = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/tags`;
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
        console.error(err, response);
        logFront(err, "getTags()");
      } else {
        console.error(err);
        
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
  
        // alert(err.message);
        return err.message;
      } 
    }

    setIsLoading(false);

    const dados = response.data;

    if (dados && dados.tags) {
      setaTags(dados.tags);
    }
  };

  useEffect(() => {
    if (inseriu) {
      toast("Tag adicionada!");
      getTags();
    }
  }, [inseriu]);

  useEffect(() => {
    if (atualizou) {
      toast("Tag atualizada!");
      getTags();
    }
  }, [atualizou]);

  useEffect(() => {
    if (removeu) {
      toast("Tag removida!");
      getTags();
    }
  }, [removeu]);

  // primeiro acesso
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
    getTags();
  }, []);

  const btnAdd = <BtnModalFormTag onAdd={onAdd} />;

  const btns = tag => (
    <Fragment>
      <BtnModalFormTag onEdit={onEdit} {...tag} />
      <BtnModalRemoverTag id={tag.id} nome={tag.nome} onDelete={onDelete} />
    </Fragment>
  );

  return (
    <div className="animated fadeIn">
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1049 }}
        />
        <Table size="sm" hover>
          <thead className="table-primary">
            <tr>
              <th>Nome da Tag</th>
              <th className="text-right">{btnAdd}</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={2}>
                  <div className="text-muted text-center mt-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                  </div>
                </td>
              </tr>
            )}

            {!isLoading && tags && tags.length === 0 && (
              <tr>
                <td colSpan={2}>
                  <p className="text-muted text-center mt-3">
                    Nenhuma tag criada.
                  </p>
                </td>
              </tr>
            )}

            {!isLoading &&
              tags &&
              tags.length > 0 &&
              tags.map(tag => (
                <tr key={`tag_${tag.id}`}>
                  <td>
                    <Badge pill className="ml-1">
                      {tag.nome}
                    </Badge>
                  </td>
                  <td className="text-right">{btns(tag)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default withRouter(Tags);
