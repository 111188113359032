import React, { Fragment, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge
} from "reactstrap";
import PropTypes from "prop-types";
import { getAxiosAuth, getTokenClientId, logApp, logClient, logFront } from "../../../utils";
import { withRouter } from "react-router-dom";

function BtnModalRemoverTag(props) {
  const clienteId = getTokenClientId();
  const [aberto, setaAberto] = useState(false);

  // --- eventos para o modal ---
  const toggle = e => {
    e && e.stopPropagation();
    setaAberto(!aberto);
  };

  // --- eventos para o formulário ---
  const onSubmitForm = async e => {
    e.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/tags/${props.id}`;
    let response;

    try {
      response = await axiosInstance.delete(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
        logFront(err, "onSubmitForm()");
      } else {
        console.error(err, response);
        
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
        
        // alert(err.message);
        return err.message;
      }
    }

    setaAberto(false);
    props.onDelete && props.onDelete(props.id);
  };

  return (
    <Fragment>
      <Button size="sm" color="light" onClick={toggle}>
        <i className="fas fa-times" />
      </Button>
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>Remover Tag</ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <p>
              Deseja mesmo remover a tag <Badge pill>{props.nome}</Badge>?
            </p>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={onSubmitForm}>
            Remover
          </Button>
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalRemoverTag.propTypes = {
  id: PropTypes.number.isRequired,
  nome: PropTypes.string.isRequired,
  onDelete: PropTypes.func
}

export default withRouter(BtnModalRemoverTag);
