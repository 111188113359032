import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getAxiosAuth, getTokenClientId, toBRL, toPercent, logApp, logClient } from "../../utils";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Line } from "react-chartjs-2";
import moment from "moment";
import "moment/locale/pt-br";

function GraficoFluxoCaixa(props) {
  const clienteId = getTokenClientId();
  const [dados, setaDados] = useState();
  const [legendas, setaLegendas] = useState([]);
  const [receitas, setaReceitas] = useState([]);
  const [contribuicoes, setaContribuicoes] = useState([]);
  const [despesasEssenciais, setaDespesasEssenciais] = useState([]);
  const [despesasSuperfluas, setaDespesasSuperfluas] = useState([]);
  const [despesasLivres, setaDespesasLivres] = useState([]);

  /**
   * Primeiro acesso: obtem dados para o fluxo de caixa
   */
  useEffect(() => {
    const getFluxoDeCaixa = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/dashboard/fluxo-de-caixa`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err);

          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        setaDados(response.data);
      }
    };

    getFluxoDeCaixa();
  }, []);

  /**
   * Após obter dados, processa legendas e séries
   */
  useEffect(() => {
    if (!dados) {
      return;
    }

    let novasLegendas = [];
    let novasReceitas = [];
    let novasContribuicoes = [];
    let novasDespesasEssenciais = [];
    let novasDespesasSuperfluas = [];
    let novasDespesasLivres = [];

    for (let i = 11; i >= 0; i--) {
      let m = moment()
        .endOf("month")
        .subtract(i, "month");
      let legenda = m.format("MMMM");

      novasLegendas.push(legenda);

      const mes = m.get("month") + 1;
      const ano = m.get("year");

      /**
       * Processando receitas
       */
      const receita = dados.receitasLiquidas.find(
        receita => receita.ano === ano && receita.mes === mes
      );

      const receitaValor = receita ? receita.totalLiquido : 0;

      novasReceitas.push(receitaValor);

      /**
       * Processando contribuições
       */
      const contribuicao = dados.contribuicoes.find(
        contribuicao => contribuicao.ano === ano && contribuicao.mes === mes
      );

      const contribuicaoValor = contribuicao
        ? contribuicao.totalContribuicoes
        : 0;

      novasContribuicoes.push(contribuicaoValor);

      /**
       * Processando despesas Essenciais
       */
      const despesaEssencial = dados.despesasEssenciais.find(
        despesa => despesa.ano === ano && despesa.mes === mes
      );

      const despesaEssencialValor = despesaEssencial
        ? despesaEssencial.totalDespesas
        : 0;

      novasDespesasEssenciais.push(despesaEssencialValor);

      /**
       * Processando despesas Livres
       */

      const despesaLivre = dados.despesasLivres.find(
        despesa => despesa.ano === ano && despesa.mes === mes
      );

      const despesaLivreValor = despesaLivre
        ? despesaLivre.totalDespesas
        : 0;

      novasDespesasLivres.push(despesaLivreValor);

      /**
       * Processando despesas Supérfluas
       */
      const despesaSuperflua = dados.despesasSuperfluas.find(
        despesa => despesa.ano === ano && despesa.mes === mes
      );

      const despesaSuperfluaValor = despesaSuperflua
        ? despesaSuperflua.totalDespesas
        : 0;

      novasDespesasSuperfluas.push(despesaSuperfluaValor);
    }

    setaLegendas(novasLegendas);
    setaReceitas(novasReceitas);
    setaContribuicoes(novasContribuicoes);
    setaDespesasEssenciais(novasDespesasEssenciais);
    setaDespesasSuperfluas(novasDespesasSuperfluas);
    setaDespesasLivres(novasDespesasLivres);
  }, [dados]);

  const chart = {
    labels: legendas,
    datasets: [
      {
        label: "Receitas",
        backgroundColor: "transparent",
        borderColor: "#84C027",
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: receitas
      },
      {
        label: "Contribuições",
        backgroundColor: "rgba(5, 146, 220, 0.1)",
        borderColor: "#0592DC",
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: contribuicoes
      },
      {
        label: "Despesas Essenciais",
        backgroundColor: "transparent",
        borderColor: "#F8B910",
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: despesasEssenciais
      },
      {
        label: "Despesas Livres",
        backgroundColor: "transparent",
        borderColor: "#F23A71",
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: despesasLivres
      },
      {
        label: "Despesas Supérfluas",
        backgroundColor: "transparent",
        borderColor: "#f86c6b",
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: despesasSuperfluas
      }
    ]
  };

  const chartOpts = {
    maintainAspectRatio: false,
    legend: {
      // display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const { datasetIndex } = tooltipItem;
          let label = data.datasets[datasetIndex].label;
          let percentageText = "";

          if (datasetIndex === 2 || datasetIndex === 3) {
            let total =
              data.datasets[2].data[tooltipItem.index] +
              data.datasets[3].data[tooltipItem.index];
            if (total > 0) {
              percentageText = `(${toPercent(tooltipItem.yLabel / total)})`;
            }
          }

          let textLabel = `${label}: ${toBRL(
            tooltipItem.yLabel
          )} ${percentageText}`;
          return textLabel;
        }
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            maxTicksLimit: 5,
            callback: function(value) {
              return toBRL(value);
            }
          }
        }
      ]
    },
    elements: {
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="text-center">Fluxo de Caixa Anual</CardTitle>
        <div className="chart-wrapper" style={{ height: 300, marginTop: 40 }}>
          <Line data={chart} options={chartOpts} height={300} />
        </div>
      </CardBody>
    </Card>
  );
}

export default withRouter(GraficoFluxoCaixa);
