import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Alert,
} from "reactstrap";
import { FormErrMsg, FieldDate } from "../../../components";
import {
  getAxiosAuth,
  toISO8601,
  fromISO8601,
  isDateValid,
  getTokenClientId,
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";

function Filhos(props) {
  const clienteId = getTokenClientId();
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [filhos, setaFilhos] = useState([]);
  const [salvarFilhos, setaSalvarFilhos] = useState();
  const [obterFilhos, setaObterFilhos] = useState();

  const NOVO_FILHO = {
    id: null,
    nomeCompleto: "",
    nomeCompletoErro: null,
    dataNascimento: "",
    dataNascimentoErro: null,
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    let valido = true;

    if (estaLimpo()) {
      setaFilhos([{ ...NOVO_FILHO }]);
    } else {
      filhos.forEach((filho, idx) => {
        const nomeCompletoValido = validaNomeCompleto(idx);
        const dataNascimentoValida = validaDataNascimento(idx);

        if (!nomeCompletoValido || !dataNascimentoValida) {
          valido = false;
        }
      });
    }

    if (valido) {
      setaFormularioValido(true);
      setaSalvarFilhos(new Date());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  const estaLimpo = () => {
    if (filhos.length > 1) {
      return false;
    } else if (filhos.length === 1) {
      const filho = filhos[0];

      return (
        validator.isEmpty(filho.nomeCompleto, { ignore_whitespace: true }) &&
        validator.isEmpty(filho.dataNascimento, { ignore_whitespace: true })
      );
    }
  };

  // primeiro acesso: obtem dados dos filhos
  useEffect(() => {
    const getFilhos = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/filhos`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
          logFront(err, "getFilhos()");
        } else {
          console.error(err);
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
    
          // alert(err.message);
          return err.message;
        }
      }

      const dados = response.data;

      if (
        dados.filhos &&
        Array.isArray(dados.filhos) &&
        dados.filhos.length > 0
      ) {
        setaFilhos(
          dados.filhos.map((filho) => ({
            ...NOVO_FILHO,
            id: filho.id,
            nomeCompleto: filho.nomeCompleto,
            dataNascimento: fromISO8601(filho.dataNascimento),
          }))
        );
      } else {
        setaFilhos([{ ...NOVO_FILHO }]);
      }
    };

    getFilhos();
  }, [obterFilhos]);

  // salvar
  useEffect(() => {
    const putFilhos = async () => {
      const dados = estaLimpo()
        ? { filhos: [] }
        : {
            filhos: filhos.map((filho) => {
              let dataNascimento = toISO8601(filho.dataNascimento);

              return {
                id: filho.id,
                nomeCompleto: filho.nomeCompleto,
                dataNascimento,
              };
            }),
          };

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/filhos`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err.response);
        } else {
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
    
          console.error(err);
          return err.message;
        }
      }

      if (response.status === 200) {
        let mensagem;

        if (estaLimpo()) {
          mensagem = "Filhos removidos com sucesso!";
        } else if (filhos.length > 1) {
          mensagem = "Filhos salvos com sucesso!";
        } else {
          mensagem = "Filho salvo com sucesso!";
        }

        toast(mensagem);

        setaObterFilhos(new Date());
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        const responseApp = await logApp(axiosInstance, response);
        await logClient(axiosInstance, null, response, responseApp.data.trace_id, uri, response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
    
        // alert(response.message);
        return response.message;
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      putFilhos();
    }
  }, [salvarFilhos]);

  const onClickBtnAdicionarFilho = (e) => {
    e.preventDefault();

    const novosFilhos = [...filhos, { ...NOVO_FILHO }];

    setaFilhos(novosFilhos);
  };

  const onClickBtnRemoverFilho = (idx, e) => {
    e.preventDefault();

    let novosFilhos = [...filhos];

    if (filhos.length === 1) {
      novosFilhos = [{ ...NOVO_FILHO }];
    } else {
      novosFilhos.splice(idx, 1);
    }

    setaFilhos(novosFilhos);
  };

  const validaNomeCompleto = (idx) => {
    if (!filhos[idx]) return;

    let novosFilhos = [...filhos];

    const filho = novosFilhos[idx];

    let valido = true;

    if (validator.isEmpty(filho.nomeCompleto, { ignore_whitespace: true })) {
      filho.nomeCompletoErro = "Nome completo obrigatório.";
      valido = false;
    }

    if (
      valido &&
      novosFilhos.find(
        (outroFilho, outroIdx) =>
          outroIdx !== idx && outroFilho.nomeCompleto === filho.nomeCompleto
      )
    ) {
      filho.nomeCompletoErro = "Nome repetido.";
      valido = false;
    }

    if (valido) {
      filho.nomeCompletoErro = false;
    }

    setaFilhos(novosFilhos);

    return valido;
  };

  const onChangeNomeCompleto = (idx, e) => {
    if (!filhos[idx]) return;

    let novosFilhos = [...filhos];

    novosFilhos[idx].nomeCompleto = e.target.value;

    setaFilhos(novosFilhos);
  };

  const onBlurNomeCompleto = (idx) => {
    validaNomeCompleto(idx);
  };

  const validaDataNascimento = (idx) => {
    if (!filhos[idx]) return;

    let novosFilhos = [...filhos];

    const filho = novosFilhos[idx];

    let valido = true;

    if (validator.isEmpty(filho.dataNascimento, { ignore_whitespace: true })) {
      filho.dataNascimentoErro = "Data de nascimento obrigatória.";
      valido = false;
    }

    if (valido && !isDateValid(filho.dataNascimento)) {
      filho.dataNascimentoErro = "Data inválida.";
      valido = false;
    }

    if (valido) {
      filho.dataNascimentoErro = false;
    }

    setaFilhos(novosFilhos);

    return valido;
  };

  const onChangeDataNascimento = (idx, e) => {
    if (!filhos[idx]) return;

    let novosFilhos = [...filhos];

    novosFilhos[idx].dataNascimento = e.target.value;

    setaFilhos(novosFilhos);
  };

  const onBlurDataNascimento = (idx) => {
    validaDataNascimento(idx);
  };

  const requiredClassName = estaLimpo() ? "" : "required";

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <Card>
          <CardHeader>
            <i className="far fa-edit" />
            <strong>Filhos</strong>
          </CardHeader>
          <CardBody>
            {filhos.map((filho, elIdx) => (
              <Row form key={`filho_${elIdx}`} style={{ marginBottom: 15 }}>
                <Col xs={12} sm={6} xl={4}>
                  <FormGroup>
                    <Label className={requiredClassName}>Nome completo</Label>
                    <Input
                      type="text"
                      value={filho.nomeCompleto}
                      onChange={onChangeNomeCompleto.bind(this, elIdx)}
                      onBlur={onBlurNomeCompleto.bind(this, elIdx)}
                    />
                    {filho.nomeCompletoErro && (
                      <FormErrMsg>{filho.nomeCompletoErro}</FormErrMsg>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={6} sm={3} xl={2}>
                  <FormGroup>
                    <Label className={requiredClassName}>Nascimento</Label>
                    <FieldDate
                      value={filho.dataNascimento}
                      onChange={onChangeDataNascimento.bind(this, elIdx)}
                      onBlur={onBlurDataNascimento.bind(this, elIdx)}
                    />
                    {filho.dataNascimentoErro && (
                      <FormErrMsg>{filho.dataNascimentoErro}</FormErrMsg>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={4} sm={3} xl={2} className="d-flex align-items-center">
                  <ButtonGroup>
                    <Button color="primary" onClick={onClickBtnAdicionarFilho}>
                      <i className="fas fa-plus" />
                    </Button>
                    <Button
                      color="secondary"
                      onClick={onClickBtnRemoverFilho.bind(this, elIdx)}
                    >
                      <i className="fas fa-minus" />
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            ))}
            {formularioValido === false && (
              <Alert color="danger" style={{ display: "inline-block" }}>
                Não foi possível salvar. {formularioErro}
              </Alert>
            )}
          </CardBody>
          <CardFooter>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </div>
  );
}

export default withRouter(Filhos);
