import React, { Fragment, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { FormErrMsg } from "../../components";
import { getTokenClientId, getAxiosAuth, logFront } from "../../utils";
import { withRouter } from "react-router-dom";

function FileUpload(props) {
  const clienteId = getTokenClientId();
  const fileRef = useRef(0);
  const [isSending, setIsSending] = useState(false);
  const isFirstRun = useRef(true);
  const [error, setError] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const predicado = props.predicado ? props.predicado : "arquivo";
  const [dadosResponse, setaDadosResponse] = useState(false);

  const onClick = e => {
    e.preventDefault();
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const onChange = e => {
    if (e.target.value) {
      setIsSending(true);
      setError(false);
      setPercentCompleted(0);
      props.onUploadStart && props.onUploadStart(e.currentTarget.files[0]);
    }
  };

  useEffect(() => {
    const postFile = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/uploads`;
      let response;

      const data = new FormData();
      data.append("file", fileRef.current.files[0]);

      const config = {
        onUploadProgress: progressEvent => {
          setPercentCompleted(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
        timeout: 0
      };

      try {
        response = await axiosInstance.post(uri, data, config);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        logFront(err, "postFile()");
        setError(true);
        setIsSending(false);
        return;
      }
      setIsSending(false);
      setaDadosResponse(response.data);
    };

    if (isSending) {
      postFile();
    }
  }, [isSending]);

  useEffect(() => {
    if (!isFirstRun.current) {
      props.onUploadEnd && props.onUploadEnd(dadosResponse);
    }
  }, [dadosResponse]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, []);

  const iconStyle = { display: "inline-border", marginRight: 10 };

  return (
    <Fragment>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileRef}
        onChange={onChange}
        accept={props.accept}
      />

      <Button
        color={error ? "danger" : "primary"}
        onClick={onClick}
        disabled={isSending}
        size="sm"
      >
        {isSending && (
          <Fragment>
            <div
              className="spinner-border spinner-border-sm"
              role="status"
              style={iconStyle}
            />
            Enviando {percentCompleted}%...
          </Fragment>
        )}
        {error && <i className="fas fa-times-circle" style={iconStyle} />}
        {!isSending && (
          <Fragment>
            <i className="fas fa-cloud-upload-alt" style={iconStyle} /> Enviar{" "}
            {predicado}
          </Fragment>
        )}
      </Button>
      {error && (
        <FormErrMsg>Erro ao enviar {predicado}. Tente novamente</FormErrMsg>
      )}
    </Fragment>
  );
}

FileUpload.propTypes = {
  predicado: PropTypes.string,
  onUploadStart: PropTypes.func,
  onUploadEnd: PropTypes.func
};

export default withRouter(FileUpload);
