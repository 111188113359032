import React, { useState, useEffect, Fragment } from "react";
import { Row, Button, Container } from "reactstrap";
import { getAxiosAuth, getTokenClientId, logApp, logClient, logFront } from "../../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import ProjetoItem from "./ProjetoItem";

function ProjetosVida(props) {
  const clienteId = getTokenClientId();
  const [projetosCurtoPrazo, setaProjetosCurtoPrazo] = useState([]);
  const [projetosMedioPrazo, setaProjetosMedioPrazo] = useState([]);
  const [projetosLongoPrazo, setaProjetosLongoPrazo] = useState([]);

  const temProjetos = () => {
    return (
      projetosCurtoPrazo.length > 0 ||
      projetosMedioPrazo.length > 0 ||
      projetosLongoPrazo.length > 0
    );
  };

  let criouSucesso = false;
  let editouSucesso = false;

  if (props.history && props.history.location && props.history.location.state) {
    if (props.history.location.state.criouSucesso) {
      criouSucesso = true;
    }

    if (props.history.location.state.editouSucesso) {
      editouSucesso = true;
    }
  }

  const handleIniciarClick = (e) => {
    e.stopPropagation();
    const { history } = props;
    history.push("/planeje/projetos-de-vida/especifique");
  };

  // quando obter criouSucesso=true/editouSucesso=true do router
  useEffect(() => {
    if (criouSucesso) {
      toast("Projeto criado com sucesso!");
    }

    if (editouSucesso) {
      toast("Projeto alterado com sucesso!");
    }
  }, [criouSucesso, editouSucesso]);

  const getProjetos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/projetos`;
    let response;

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
        logFront(err, "getProjetos()");
      } else {
        console.error(err);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }

    if (response.status === 200) {
      const dados = response.data;

      if (dados.curtoPrazo) {
        setaProjetosCurtoPrazo(dados.curtoPrazo);
      }

      if (dados.medioPrazo) {
        setaProjetosMedioPrazo(dados.medioPrazo);
      }

      if (dados.longoPrazo) {
        setaProjetosLongoPrazo(dados.longoPrazo);
      }
    } else if (response.status === 404) {
      console.error(response);
      props.history.push("/404");
      return;
    }
  };

  // primeiro acesso: lista projetos
  useEffect(() => {
    getProjetos();
  }, []);

  const onDeleteProjeto = () => {
    toast("Projeto removido com sucesso!");
    getProjetos();
  };

  return (
    <div className="animated fadeIn" style={{ marginBottom: 40 }}>
      <Container>
        <div className="projetos-de-vida-header">
          <h1>Projetos de Vida</h1>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 2999 }}
        />

        <p
          className="text-center lead"
          style={{ marginTop: 40, marginBottom: 20, "white-space": "pre" }}
        >
          O que você deseja fazer nos próximos anos?{'\n'}Seus sonhos devem ser o
          antídoto para o consumo não consciente!{'\n'}Busque por objetivos que
          motivará você a mudar suas atitudes e o comportamento em relação ao
          dinheiro.
        </p>

        {!temProjetos() && (
          <p className="text-center lead">
            <Button size="lg" color="primary" onClick={handleIniciarClick}>
              Iniciar
            </Button>
          </p>
        )}

        {temProjetos() && (
          <Fragment>
            <p
              className="text-center"
              style={{ marginTop: 30, marginBottom: 60 }}
            >
              <Button size="lg" color="primary" onClick={handleIniciarClick}>
                Adicionar Novo Projeto
              </Button>
            </p>
            
            <hr style={{ marginTop: 30, marginBottom: 30 }} />
            <h3 className="text-center">Meus Projetos de Vida</h3>

            {projetosCurtoPrazo.length > 0 && (
              <Fragment>
                <h4 style={{ marginTop: 30 }}>De curto prazo (até 3 anos)</h4>
                <Row form style={{ marginTop: 30 }}>
                  {projetosCurtoPrazo.map((projeto) => (
                    <ProjetoItem
                      {...projeto}
                      key={`projeto_item_${projeto.id}`}
                      onDelete={onDeleteProjeto}
                    />
                  ))}
                </Row>
              </Fragment>
            )}

            {projetosMedioPrazo.length > 0 && (
              <Fragment>
                <h4 style={{ marginTop: 30 }}>De médio prazo (até 10 anos)</h4>
                <Row form style={{ marginTop: 30 }}>
                  {projetosMedioPrazo.map((projeto) => (
                    <ProjetoItem
                      {...projeto}
                      key={`projeto_item_${projeto.id}`}
                      onDelete={onDeleteProjeto}
                    />
                  ))}
                </Row>
              </Fragment>
            )}

            {projetosLongoPrazo.length > 0 && (
              <Fragment>
                <h4 style={{ marginTop: 30 }}>
                  De longo prazo (mais de 10 anos)
                </h4>
                <Row form style={{ marginTop: 30 }}>
                  {projetosLongoPrazo.map((projeto) => (
                    <ProjetoItem
                      {...projeto}
                      key={`projeto_item_${projeto.id}`}
                      onDelete={onDeleteProjeto}
                    />
                  ))}
                </Row>
              </Fragment>
            )}
          </Fragment>
        )}
      </Container>
    </div>
  );
}

export default withRouter(ProjetosVida);
