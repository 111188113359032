import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { getTokenClientId, getAxiosAuth, logApp, logClient, logFront } from "../../../utils";
import { FormErrMsg } from "../../../components";
import validator from "validator";
import owasp from "owasp-password-strength-test";
import { withRouter } from "react-router-dom";

function DadosAcesso(props) {
  const clienteId = getTokenClientId();
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [email, setaEmail] = useState("");
  const [emailErro, setaEmailErro] = useState(null);
  const [senha, setaSenha] = useState("");
  const [senhaErro, setaSenhaErro] = useState(null);
  const [senhaConfirmacao, setaSenhaConfirmacao] = useState("");
  const [senhaConfirmacaoErro, setaSenhaConfirmacaoErro] = useState(null);
  const [salvarDados, setaSalvarDados] = useState();

  const onSubmitForm = (e) => {
    e.preventDefault();

    const emailValido = validaEmail();
    const senhaValida = validaSenha();
    const senhaConfirmacaoValida = validaSenhaConfirmacao();

    if (emailValido && senhaValida && senhaConfirmacaoValida) {
      setaFormularioValido(true);
      setaSalvarDados(new Date());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  const validaEmail = () => {
    if (
      !validator.isEmpty(email, { ignore_whitespace: true }) &&
      !validator.isEmail(email)
    ) {
      setaEmailErro("Email inválido.");
      return false;
    }

    setaEmailErro(false);
    return true;
  };

  const onChangeEmail = (e) => {
    setaEmail(e.target.value);
  };

  const onBlurEmail = () => {
    validaEmail();
  };

  const validaSenha = () => {
    if (validator.isEmpty(senha)) {
      // redefinir senha é opcional
      setaSenhaErro(null);
      return true;
    }

    const MIN_PASSWORD_LENGTH = 8;

    owasp.config({
      minOptionalTestsToPass: 2,
      minLength: MIN_PASSWORD_LENGTH,
    });

    const resultado = owasp.test(senha);

    let mensagem = "";

    if (!resultado.strong) {
      mensagem = "Senha fraca. ";
    }

    if (!resultado.strong && resultado.failedTests.length > 0) {
      const teste = resultado.failedTests[0];

      switch (teste) {
        case 0:
          mensagem += `Deve ter no mínimo ${MIN_PASSWORD_LENGTH} caracteres.`;
          break;
        case 2:
          mensagem += `Não deve conter sequências de três ou mais caracteres repetidos.`;
          break;
        case 3:
          mensagem += `Deve conter pelo menos um caractere em caixa baixa.`;
          break;
        case 4:
          mensagem += `Deve conter pelo menos um caractere em caixa alta.`;
          break;
        case 5:
          mensagem += `Deve conter pelo menos um número.`;
          break;
        case 6:
          mensagem += `Deve conter pelo menos um caractere especial (#,$,%, etc.).`;
          break;
        default:
          break;
      }
    }

    setaSenhaErro(mensagem || false);

    return resultado.strong;
  };

  const onChangeSenha = (e) => {
    setaSenha(e.target.value);
  };

  const onBlurSenha = () => {
    validaSenha();
  };

  useEffect(() => {
    validaSenha();
    validaSenhaConfirmacao();
  }, [senha]);

  const validaSenhaConfirmacao = () => {
    if (validator.isEmpty(senha)) {
      setaSenhaConfirmacaoErro(null);
      return true;
    } else if (senhaConfirmacao !== senha) {
      setaSenhaConfirmacaoErro("Senhas não conferem.");
      return false;
    }

    setaSenhaConfirmacaoErro(false);
    return true;
  };

  const onChangeSenhaConfirmacao = (e) => {
    setaSenhaConfirmacao(e.target.value);
  };

  useEffect(() => {
    if (senhaConfirmacaoErro !== null) {
      validaSenhaConfirmacao();
    }
  }, [senhaConfirmacao]);

  const onBlurSenhaConfirmacao = () => {
    validaSenhaConfirmacao();
  };

  // primeiro acesso: obtem dados (email)
  useEffect(() => {
    const getDados = async () => {
      const axiosInstance = getAxiosAuth(() => props.history.push("/login"));
      const uri = `/sessao`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err);
          
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);

          // alert(err.message);
          return err.message;
        }

        if (response.status !== 200 && response.status !== 404) {
          console.error(err, response);
          
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);

          // alert(err.message);
          return err.message;
        }
      }

      const dados = response.data;
      // console.log(dados);
      setaEmail(dados.email || "");
    };

    getDados();
  }, []);

  // salvar
  useEffect(() => {
    const patchDados = async () => {
      const dados = {};

      if (email) {
        dados["email"] = email;
      }

      if (senha) {
        dados["senha"] = senha;
      }

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}`;
      let response;

      try {
        response = await axiosInstance.patch(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          logFront(err, "patchDados()");
          // console.error(err, response);
        } else {
          
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);

          console.error(err);
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        toast("Dados salvos com sucesso!");
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        const dados = response.data;

        if (
          dados.errors &&
          Array.isArray(dados.errors) &&
          dados.errors.length > 0
        ) {
          const entrada = dados.errors[0];

          if (entrada.field === "email" && entrada.error === "not_unique") {
            mensagem = "Email já foi usado por outro cliente.";
            setaEmailErro(mensagem);
          }
        }

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        
        const responseApp = await logApp(axiosInstance, response);
        await logClient(axiosInstance, null, response, responseApp.data.trace_id, uri, response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);

        // alert(response.message);
        return response.message;
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      patchDados();
    }
  }, [salvarDados]);

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <Row form>
          <Col md={12} lg={12} className="d-flex align-items-stretch">
            <Card style={{ flexGrow: 1 }}>
              <CardHeader>
                <i className="fa fa-user" />
                <strong>Dados de Acesso</strong>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col sm={6} md={6} lg={6} xl={3}>
                    <FormGroup>
                      <Label htmlFor="email">Redefinir email</Label>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>@</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="email"
                          type="text"
                          placeholder="Email para acesso"
                          autoComplete="off"
                          value={email}
                          onChange={onChangeEmail}
                          onBlur={onBlurEmail}
                          valid={emailErro === false}
                          invalid={!!emailErro}
                        />
                        {emailErro && <FormErrMsg>{emailErro}</FormErrMsg>}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm={6} md={6} lg={6} xl={3}>
                    <FormGroup>
                      <Label htmlFor="senha">Redefinir senha</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="senha"
                          type="password"
                          placeholder="Preencha se quiser redefinir"
                          autoComplete="off"
                          onChange={onChangeSenha}
                          onBlur={onBlurSenha}
                          value={senha}
                          valid={senhaErro === false}
                          invalid={!!senhaErro}
                        />
                        {senhaErro && <FormErrMsg>{senhaErro}</FormErrMsg>}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} lg={6} xl={3}>
                    <FormGroup>
                      <Label
                        htmlFor="confirmarSenha"
                        className={senha ? "required" : ""}
                      >
                        Confirmar senha
                      </Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="confirmarSenha"
                          type="password"
                          placeholder="Repita sua nova senha"
                          autoComplete="off"
                          onChange={onChangeSenhaConfirmacao}
                          onBlur={onBlurSenhaConfirmacao}
                          value={senhaConfirmacao}
                          valid={senhaConfirmacaoErro === false}
                          invalid={!!senhaConfirmacaoErro}
                        />
                        {senhaConfirmacaoErro && (
                          <FormErrMsg>{senhaConfirmacaoErro}</FormErrMsg>
                        )}
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                {formularioValido === false && (
                  <Alert color="danger" style={{ display: "inline-block" }}>
                    Não foi possível salvar. {formularioErro}
                  </Alert>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row form style={{ paddingBottom: 20 }}>
          <Col>
            <Button color="primary" style={{ display: "block" }}>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default withRouter(DadosAcesso);
