import React, { useEffect, useState } from "react";
import { Container, Table } from "reactstrap";
import { DateCarousel } from "../../../components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  getAxiosAuth,
  getTokenClientId,
  wasRequestCancelled,
  toBRL, 
  logApp, 
  logClient,
} from "../../../utils";
import LinhaFinanciamento from "./LinhaFinanciamento";
import { ToastContainer, toast } from "react-toastify";

function Financiamentos(props) {
  const clienteId = getTokenClientId();
  const m = moment();
  const [financiamentos, setaFinanciamentos] = useState([]);
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [isLoading, setIsLoading] = useState(true);
  const [totalDevido, setaTotalDevido] = useState(0);
  const [atualizou, setaAtualizou] = useState(null);
  const [parcelasValorTotal, setaParcelasValorTotal] = useState(0);

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  const getFinanciamentos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/financiamentos`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    setIsLoading(false);

    if (response.data && "financiamentos" in response.data) {
      setaFinanciamentos(response.data.financiamentos);
      // console.log(response.data.financiamentos);
    }

    if (response.data && "totalDevido" in response.data) {
      setaTotalDevido(response.data.totalDevido);
    }
  };

  const onFinanciamentoInterrompido = nome => {
    toast(`Financiamento ${nome} interrompido.`);
    setaAtualizou(new Date());
  };

  const onFinanciamentoRetomado = nome => {
    toast(`Financiamento ${nome} retomado.`);
    setaAtualizou(new Date());
  };

  // primeiro acesso, e quando altera data
  useEffect(() => {
    getFinanciamentos();
  }, [mes, ano, atualizou]);
  
  useEffect(()=>{
    let total=0
    financiamentos.forEach((f)=>{
      total+=f.valorParcela
    })
    setaParcelasValorTotal(total)

  },[financiamentos])

  return (
    <div className="animated fadeIn">
      <Container className="">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1049 }}
        />
        <div className="text-center">
          <DateCarousel mes={mes} ano={ano} onChange={onChangeDate} />
        </div>

        <Table size="sm" hover>
          <thead className="thead-dark">
            <tr>
              <th className="text-left"></th>
              <th className="text-left">Total devido</th>
              <th className="text-right">{toBRL(parcelasValorTotal)}</th>
              <th className="d-none d-sm-table-cell"/>
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="text-right">{toBRL(totalDevido)}</th>
              <th className="d-none d-sm-table-cell" />
            </tr>
          </thead>
          <tbody className="table-danger">
            <tr>
              <th className="text-left" style={{ paddingLeft: 10, width: 60 }}>
                1&ordm; pagto
              </th>
              <th className="text-left">Nome</th>
              <th className="text-right">Valor</th>
              <th className="text-right d-none d-sm-table-cell">
                N<sup>&ordm;</sup> da Parcela
              </th>
              <th className="text-right d-none d-sm-table-cell">
                Total Parcelas
              </th>
              <th className="d-none d-sm-table-cell">Último pagamento</th>
              <th className="text-left d-none d-sm-table-cell">Credor</th>
              <th className="text-right d-none d-sm-table-cell">Juros</th>
              <th className="text-right d-none d-sm-table-cell">
                Valor Restante
              </th>
              <th className="text-right d-none d-md-table-cell">Ações</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td
                colSpan={3}
                className="text-right d-table-cell d-sm-none"
              ></td>
            </tr>
            {isLoading && (
              <tr>
                <td colSpan={9}>
                  <div className="text-muted text-center mt-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                  </div>
                </td>
              </tr>
            )}

            {!isLoading && financiamentos.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <p className="text-muted text-center mt-3">
                    Sem financiamentos no período.
                  </p>
                </td>
              </tr>
            )}

            {!isLoading &&
              financiamentos.length > 0 &&
              financiamentos.map(financiamento => (
                <LinhaFinanciamento
                  key={`financiamento_${financiamento.id}`}
                  {...financiamento}
                  mes={mes}
                  ano={ano}
                  onFinanciamentoInterrompido={onFinanciamentoInterrompido}
                  onFinanciamentoRetomado={onFinanciamentoRetomado}
                />
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default withRouter(Financiamentos);
