import React, { useState, useEffect, Fragment } from "react";
import { Table, Button, Alert } from "reactstrap";
import { withRouter } from "react-router-dom";
import { DateSpanSelector } from "../../../components";
import moment from "moment";
import "moment/locale/pt-br";
import {
  getAxiosAuth,
  getTokenClientId,
  toBRL,
  toBRLColor,
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import { CATEGORIAS_DESPESAS } from "../../../options";
import { TIPOS_RECEITAS } from "../../../options";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

function DRE(props) {
  const clienteId = getTokenClientId();

  const dataInicialPadrao = moment().subtract(2, "month");
  const dataFinalPadrao = moment();

  const [mesInicial, setaMesInicial] = useState(
    Number(dataInicialPadrao.format("M"))
  );
  const [anoInicial, setaAnoInicial] = useState(
    Number(dataInicialPadrao.format("YYYY"))
  );
  const [mesFinal, setaMesFinal] = useState(
    Number(dataFinalPadrao.format("M"))
  );
  const [anoFinal, setaAnoFinal] = useState(
    Number(dataFinalPadrao.format("YYYY"))
  );
  const [dadosRelatorio, setaDadosRelatorio] = useState([]);
  const [colunasMeses, setaColunasMeses] = useState([]);
  const [receitas, setaReceitas] = useState([]);
  const [categoriasDespesas, setaCategoriasDespesas] = useState([]);
  const [receitasExpandidas, setaReceitasExpandidas] = useState(false);
  const [despesasExpandidas, setaDespesasExpandidas] = useState(false);
  const [contribuicoesExpandidas, setaContribuicoesExpandidas] = useState(
    false
  );
  const [categoriasExpandidas, setaCategoriasExpandidas] = useState([]);
  const [tiposExpandidos, setaTiposExpandidos] = useState([]);
  const [contribuicoes, setaContribuicoes] = useState([]);
  const [totaisReceitas, setaTotaisReceitas] = useState([]);
  const [totaisDespesas, setaTotaisDespesas] = useState([]);
  const [totaisContribuicoes, setaTotaisContribuicoes] = useState([]);
  const [resultados, setaResultados] = useState([]);
  const [patrimonios, setaPatrimonios] = useState([]);
  const [financiamentos, setaFinanciamentos] = useState([]);
  const [patrimoniosLiquidos, setaPatrimoniosLiquidos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [semDados, setaSemDados] = useState(true);
  const [selectedDropdown] = useState("Geral");
    
  const onChangeDateSpan = (mesInicial, anoInicial, mesFinal, anoFinal) => {
    setaMesInicial(mesInicial);
    setaAnoInicial(anoInicial);
    setaMesFinal(mesFinal);
    setaAnoFinal(anoFinal);
  };

  const getDRE = async () => {
    let filtroDataInicial;
    let filtroDataFinal;

    if (mesInicial && anoInicial && mesFinal && anoFinal) {
      filtroDataInicial = moment()
        .date(1)
        .month(mesInicial - 1)
        .year(anoInicial)
        .format("YYYY-MM-DD");
      filtroDataFinal = moment()
        .date(1)
        .month(mesFinal - 1)
        .year(anoFinal)
        .endOf("month")
        .format("YYYY-MM-DD");
    }

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/relatorios/dre`;
    const dados = {
      filtroDataInicial,
      filtroDataFinal,
    };
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (err.response) {
        response = err.response;
        logFront(err, "getDRE()");
      } else {
        console.error(err);
        const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }

    setIsLoading(false);

    if (
      response.status === 200 &&
      "receitasLiquidas" in response.data &&
      "despesasPorCategorias" in response.data &&
      "contribuicoes" in response.data &&
      "patrimonios" in response.data &&
      "investimentos" in response.data &&
      "totalDividas" in response.data &&
      "financiamentos" in response.data
    ) {
      setaDadosRelatorio(response.data);
    } else {
      console.error(response);
      const responseApp = await logApp(axiosInstance, response);
      await logClient(axiosInstance, null, response, responseApp.data.trace_id, uri, response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(response.message);
      return response.message;
    }
  };
  
  useEffect(() => {
    getDRE();
  }, [mesInicial, anoInicial, mesFinal, anoFinal]);

  const processaMediaETotal = (valores) => {
    let total = valores.reduce(
      (accumulator, valor) => accumulator + valor,
      0.0
    );

    let media = valores.length > 0 ? total / valores.length : 0;

    valores.push(media, total);
  };

  const processaIntervalos = () => {
    let todosDados = [
      ...dadosRelatorio.receitasLiquidas,
      ...dadosRelatorio.despesasPorCategorias,
      ...dadosRelatorio.contribuicoes,
      ...dadosRelatorio.patrimonios,
      ...dadosRelatorio.investimentos,
      ...dadosRelatorio.financiamentos,
    ];

    let todosDadosOrdenados = todosDados
      .map((obj) => ({
        ano: obj.ano,
        mes: obj.mes,
      }))
      .reduce((accumulator, current) => {
        if (
          !accumulator.find(
            (predicate) =>
              predicate.ano === current.ano && predicate.mes === current.mes
          )
        ) {
          accumulator.push(current);
        }
        return accumulator;
      }, [])
      .sort((a, b) => {
        const momentA = moment()
          .year(a.ano)
          .month(a.mes - 1)
          .format("YYYYMMDD");
        const momentB = moment()
          .year(b.ano)
          .month(b.mes - 1)
          .format("YYYYMMDD");
        return momentA - momentB;
      });
      
    return todosDadosOrdenados;
  };

  const processaColunasMeses = (intervalos, exibirMediaETotal) => {
    let colunas = intervalos.map((intervalo) => {
      let mes = moment()
        .year(intervalo.ano)
        .month(intervalo.mes - 1)
        .format("MMM");
      return `${mes} ${intervalo.ano}`;
    });

    if (exibirMediaETotal) {
      colunas.push("Média", "Total");
    }

    setaColunasMeses(colunas);
    return colunas;
  };

  const processaReceitas = (
    intervalos,
    receitasLiquidas,
    computarMediaETotal
  ) => {
    let legendas = receitasLiquidas.reduce((accumulator, current) => {
      let busca = accumulator.find(
        (atual) =>
          atual.descricao === current.descricao && 
          atual.dia === current.dia && 
          (current.renda_fixa === 1 || atual.id === current.id)
      );
      if (!busca){
        accumulator.push({"descricao": current.descricao, "id": current.id, "renda_fixa": current.renda_fixa, "tipo": current.tipo, "dia": current.dia});
      }

      //if (accumulator.indexOf(current.id) < 0) {
      //  accumulator.push(current.id);
      //}
      return accumulator;
    }, []);

    let tiposReceitas = TIPOS_RECEITAS.map((tipo) => {
      let valores = intervalos.map((intervalo) => {
        let receitas = receitasLiquidas.filter(
          (receita) =>
            receita.ano === intervalo.ano &&
            receita.mes === intervalo.mes &&
            receita.tipo === tipo
        );

        let totalReceitasPorTipo = receitas.reduce(
          (accumulator, current) => accumulator + current.valor_liquido,
          0.0
        );

        return totalReceitasPorTipo;
      });

      let novasReceitas = legendas
        .filter((receita) => receita.tipo === tipo)
        .map((legenda) => {
          let valores = intervalos.map((intervalo) => {
            let receitaLiquida = receitasLiquidas.find(
              (receitaLiquida) =>
                receitaLiquida.descricao === legenda.descricao &&
                receitaLiquida.dia === legenda.dia &&
                (receitaLiquida.renda_fixa === 1 || receitaLiquida.id === legenda.id) &&
                receitaLiquida.ano === intervalo.ano &&
                receitaLiquida.mes === intervalo.mes
            );
              
            if (receitaLiquida !== undefined) {
              return { "valor": receitaLiquida.valor_liquido, "descricao": receitaLiquida.descricao, "dia": receitaLiquida.dia};
            } else {
              return { "valor": 0.0, "descricao": null, "dia": null};
            }
          });
          
          let val = valores.map((valor) => {
            return valor.valor;
          });
          
          let desc = valores.reduce((accumulator,valor) => {
            if (accumulator.indexOf(valor.descricao) < 0){
              accumulator.push(valor.descricao);
            }
            return accumulator;
          }, []);
          
          let dias = valores.reduce((acc, val) => {
            if(acc.indexOf(val.dia) < 0) {
              acc.push(val.dia);
            } else {
              acc.push(null);
            }
            return acc;
          }, []);
          
          if (computarMediaETotal) {
            processaMediaETotal(val);
          }
          
          for(let i = dias.length; i < val.length; i++) {
            dias.push(null);
          }
          
          return {
            "legenda": desc,
            "valores": val,
            "dias": dias,
          };
        });

        if (computarMediaETotal) {
          processaMediaETotal(valores);
        }

      return {
        "tipo": tipo,
        "valores": valores,
        "receitas": novasReceitas
      }
    }).filter((tipo) => tipo.length !== 0);

    setaReceitas(tiposReceitas);
    setaTiposExpandidos(tiposReceitas.map(() => false));
    return tiposReceitas;
  };

  const processaDespesas = (
    intervalos,
    despesasPorCategorias,
    computarMediaETotal
  ) => {
    let novasCategorias = despesasPorCategorias
      .reduce((accumulator, despesaAtual) => {
        if (accumulator.indexOf(despesaAtual.categoria) < 0) {
          accumulator.push(despesaAtual.categoria);
        }
        return accumulator;
      }, [])
      .map((categoria) => {
        let valores = intervalos.map((intervalo) => {
          let despesas = despesasPorCategorias.filter(
            (despesa) =>
              despesa.ano === intervalo.ano &&
              despesa.mes === intervalo.mes &&
              despesa.categoria === categoria
          );
          let totalDespesasPorCategoria = despesas.reduce(
            (accumulator, despesaAtual) => accumulator + despesaAtual.valor,
            0.0
          );
            
          return totalDespesasPorCategoria;
        });

        let despesas = despesasPorCategorias
          .filter((despesa) => despesa.categoria === categoria)
          .map((despesa) => despesa.descricao)
          .reduce((accumulator, descricao) => {
            if (accumulator.indexOf(descricao) < 0) {
              accumulator.push(descricao);
            }
            return accumulator;
          }, [])
          .map((descricao) => {
            let valores = intervalos.map((intervalo) =>
              despesasPorCategorias
                .filter(
                  (despesa) =>
                    despesa.ano === intervalo.ano &&
                    despesa.mes === intervalo.mes &&
                    despesa.categoria === categoria &&
                    despesa.descricao === descricao
                )
                .map((despesa) => despesa.valor)
                .reduce((accumulator, valor) => accumulator + valor, 0.0)
            );
            
            let dias = intervalos.map((intervalo) => 
              despesasPorCategorias
                  .filter(
                    (despesa) => 
                      despesa.ano === intervalo.ano &&
                      despesa.mes === intervalo.mes &&
                      despesa.categoria === categoria &&
                      despesa.descricao === descricao
                  )
                  .map((despesa) => despesa.dia)
                  .reduce((accumulator, valor) => accumulator + valor, 0)
            )
            
            if (computarMediaETotal) {
              processaMediaETotal(valores);
            }

            for(let i = dias.length; i < valores.length; i++) {
              dias.push(0);
            }

            return {
              legenda: descricao,
              valores,
              dias,
            };
          });

        if (computarMediaETotal) {
          processaMediaETotal(valores);
        }

        const icone = CATEGORIAS_DESPESAS.find((obj) => obj.nome === categoria)
          .icone;

        return {
          categoria,
          icone,
          valores,
          despesas,
        };
      });

    setaCategoriasDespesas(novasCategorias);
    setaCategoriasExpandidas(novasCategorias.map(() => false));
    return novasCategorias;
  };

  const processaContribuicoes = (
    intervalos,
    contribuicoes,
    computarMediaETotal
  ) => {

    let novasContribuicoes = contribuicoes
      .reduce((accumulator, contribuicao) => {
        if (accumulator.indexOf(contribuicao.descricao) < 0) {
          accumulator.push(contribuicao.descricao);
        }
        
        return accumulator;
      }, [])
      .map((legenda, i) => {
        let valores = intervalos.map((intervalo) => {
          let contribuicao = contribuicoes.find(
            (contribuicao) =>
              contribuicao.descricao === legenda &&
              contribuicao.ano === intervalo.ano &&
              contribuicao.mes === intervalo.mes
          );
            
          if (contribuicao) {
            return contribuicao.valor;
          } else {
            return 0.0;
          }
        });

        let dias = contribuicoes.map((contribuicao) => {
          return contribuicao.dia;
        })

        if (computarMediaETotal) {
          let total = valores.reduce(
            (accumulator, valor) => accumulator + valor,
            0.0
          );

          let media = valores.length > 0 ? total / valores.length : 0;

          valores.push(media, total);
        }

        let dia = dias[i];

        return {
          legenda,
          valores,
          dia
        };
      });

    setaContribuicoes(novasContribuicoes);
    return novasContribuicoes;
  };

  const processaTotaisReceitas = (numeroColunas, receitas) => {
    let totais = [];

    for (let i = 0; i < numeroColunas; ++i) {
      totais.push(
        receitas.reduce(
          (accumulator, receita) => accumulator + receita.valores[i],
          0.0
        )
      );
    }

    setaTotaisReceitas(totais);
    return totais;
  };

  const processaTotaisDespesas = (numeroColunas, categoriasDespesas) => {
    let totais = [];

    for (let i = 0; i < numeroColunas; ++i) {
      totais.push(
        categoriasDespesas.reduce(
          (accumulator, categoria) => accumulator + categoria.valores[i],
          0.0
        )
      );
    }
    
    setaTotaisDespesas(totais);
    return totais;
  };

  const processaTotaisContribuicoes = (numeroColunas, contribuicoes) => {
    let totais = [];

    for (let i = 0; i < numeroColunas; ++i) {
      totais.push(
        contribuicoes.reduce(
          (accumulator, contribuicao) => accumulator + contribuicao.valores[i],
          0.0
        )
      );
    }
    
    setaTotaisContribuicoes(totais);
    return totais;
  };

  const processaResultados = (
    numeroColunas,
    totaisReceitas,
    totaisDespesas,
    totaisContribuicoes
  ) => {
    let totais = [];

    for (let i = 0; i < numeroColunas; ++i) {
      totais.push(
        totaisReceitas[i] -
          [totaisDespesas[i], totaisContribuicoes[i]].reduce(
            (accumulator, valor) => {
              if (valor) {
                accumulator += valor;
              }
              return accumulator;
            },
            0.0
          )
      );
    }

    setaResultados(totais);
    return totais;
  };

  const processaPatrimonios = (
    intervalos,
    patrimonios,
    investimentos,
    computarMedia
  ) => {
    let novosPatrimonios = intervalos.map((intervalo) => {
      let valorPatrimonios = patrimonios
        .filter(
          (patrimonio) =>
            patrimonio.ano === intervalo.ano && patrimonio.mes === intervalo.mes
        )
        .map((patrimonio) => patrimonio.valor)
        .reduce((accumulator, valor) => accumulator + valor, 0.0);

      let valorInvestido = investimentos
        .filter(
          (investimento) =>
            investimento.ano === intervalo.ano &&
            investimento.mes === intervalo.mes
        )
        .map((investimento) => investimento.valor)
        .reduce((accumulator, valor) => accumulator + valor, 0.0);

      return valorPatrimonios + valorInvestido;
    });

    if (computarMedia) {
      processaMediaETotal(novosPatrimonios);

      novosPatrimonios[novosPatrimonios.length - 1] =
        novosPatrimonios[novosPatrimonios.length - 3];
    }

    setaPatrimonios(novosPatrimonios);
    return novosPatrimonios;
  };

  /*
  const processaFinanciamentosDividas = (
    numeroColunas,
    financiamentos,
    totalDividas,
    computarMediaETotal
  ) => {
    let totais = [];

    for (let i = 0; i < numeroColunas; ++i) {
      if (financiamentos[i]) {
        totais.push(financiamentos[i].valor_restante + totalDividas);
      }
    }

    if (computarMediaETotal) {
      processaMediaETotal(totais);

      let ultimoRegistro = totais[totais.length - 3];
      totais[totais.length - 1] = ultimoRegistro;
    }

    setaFinanciamentos(totais);
    return totais;
  };
  */
  const processaFinanciamentosDividas = (
    intervalos,
    financiamentos,
    totalDividas,
    computarMediaETotal
  ) => {
    let novosFinanciamentos = intervalos.map((intervalo) => {
      let valorFinanciamentos = financiamentos
        .filter(
          (financiamento) =>
            financiamento.ano === intervalo.ano &&
            financiamento.mes === intervalo.mes
        )
        .map((financiamento) => financiamento.valor_restante)
        .reduce((acc, valor) => acc + valor, 0);

      return valorFinanciamentos + totalDividas;
    });

    if (computarMediaETotal) {
      processaMediaETotal(novosFinanciamentos);

      novosFinanciamentos[novosFinanciamentos.length - 1] =
        novosFinanciamentos[novosFinanciamentos.length - 3];
    }

    setaFinanciamentos(novosFinanciamentos);
    return novosFinanciamentos;
  };

  const processaPatrimoniosLiquidos = (numeroColunas, patrimonios, dividas) => {
    let totais = [];

    for (let i = 0; i < numeroColunas; ++i) {
      totais.push(patrimonios[i] - dividas[i]);
    }

    setaPatrimoniosLiquidos(totais);
    return totais;
  };

  const processaDados = () => {
    if (
      !(
        "receitasLiquidas" in dadosRelatorio &&
        "despesasPorCategorias" in dadosRelatorio &&
        "contribuicoes" in dadosRelatorio &&
        "patrimonios" in dadosRelatorio &&
        "investimentos" in dadosRelatorio
      )
    ) {
      return;
    }

    let intervalos = processaIntervalos();
    setaSemDados(intervalos.length === 0);

    let computarMediaETotal = intervalos.length > 1;

    let colunas = processaColunasMeses(intervalos, computarMediaETotal);

    let novasReceitas = processaReceitas(
      intervalos,
      dadosRelatorio.receitasLiquidas,
      computarMediaETotal
    );

    let novasDespesas = processaDespesas(
      intervalos,
      dadosRelatorio.despesasPorCategorias,
      computarMediaETotal
    );

    let novasContribuicoes = processaContribuicoes(
      intervalos,
      dadosRelatorio.contribuicoes,
      computarMediaETotal
    );

    let totaisReceitas = processaTotaisReceitas(colunas.length, novasReceitas);
    let totaisDespesas = processaTotaisDespesas(colunas.length, novasDespesas);
    let totaisContribuicoes = processaTotaisContribuicoes(
      colunas.length,
      novasContribuicoes
    );

    processaResultados(
      colunas.length,
      totaisReceitas,
      totaisDespesas,
      totaisContribuicoes
    );

    let patrimonios = processaPatrimonios(
      intervalos,
      dadosRelatorio.patrimonios,
      dadosRelatorio.investimentos,
      computarMediaETotal
    );

    let dividas = processaFinanciamentosDividas(
      intervalos,
      dadosRelatorio.financiamentos,
      dadosRelatorio.totalDividas,
      computarMediaETotal
    );

    processaPatrimoniosLiquidos(colunas.length, patrimonios, dividas);
  };

  useEffect(processaDados, [dadosRelatorio]);

  const renderizaValor = (valor) =>
    valor !== 0.0 ? (
      toBRLColor(valor)
    ) : (
      <span className="text-muted">{toBRL(0)}</span>
    );

  const renderizaColunaValoresInvestimentos = (valores, dia) => {
    if(dia) {
      return  ( 
        valores.map((valor, idxValor) => {
          // Os dois ultimos valores do array 'valores', são médias e totais
          // logo não precisam de data
          if(idxValor === valores.length - 1 || idxValor === valores.length - 2){
            return (
              <td key={`valor_${idxValor}`} className="text-nowrap text-right">
                {renderizaValor(valor)}
              </td>
            )
          }
          return (
            <td key={`valor_${idxValor}`} className="text-nowrap text-right">
              {renderizaValor(valor)} {valor !== 0 && <>({dia})</>}
            </td>
          )
        })
    )}
    return  ( valores.map((valor, idxValor) => {
      return (
        <td key={`valor_${idxValor}`} className="text-nowrap text-right">
          {renderizaValor(valor)} 
        </td>
      )
      })
    )
  }

  const renderizaColunasValoresDespesas = (valores, dias) => {
    if(dias) {
      return (dias.map((dia, idx) => {
        if(idx === valores.length - 1 || idx === valores.length - 2){
          return (
            <td key={`valor_${idx}`} className="text-nowrap text-right">
              {renderizaValor(valores[idx])}
            </td>
          )
        }
        else {
          return (
            <td key={`valor_${idx}`} className="text-nowrap text-right">
              {renderizaValor(valores[idx])} {valores[idx] !== 0 && <>({dia})</>}
            </td>
          )
        }
      }))
    }
    
    return  ( valores.map((valor, idxValor) => {
      return (
        <td key={`valor_${idxValor}`} className="text-nowrap text-right">
          {renderizaValor(valor)} 
        </td>
      )
      })
    )
  }

  const renderizaColunasValores = (valores) => {
    return  ( valores.map((valor, idxValor) => {
      return (
        <td key={`valor_${idxValor}`} className="text-nowrap text-right">
          {renderizaValor(valor)} 
        </td>
      )
      })
    )} 

  const renderizaLinhaValoresDespesas = ({ legenda, valores, dias }) => {
    return (
      <tr key={`${legenda}_valor`}>
        <td className="bg-white">{legenda}</td>
        {renderizaColunasValoresDespesas(valores, dias)} 
      </tr>
    )
  }

  const renderizaColunasReceitas = (valores, dias) => {
    if(dias) {
      return (dias.map((dia, idx) => {
        if(dia) {
          return (
            <td key={`valor_${idx}`} className="text-nowrap text-right">
              {renderizaValor(valores[idx])} {valores[idx] !== 0 && <>({dia})</>}
            </td>
          )
        } else {
          return (
            <td key={`valor_${idx}`} className="text-nowrap text-right">
              {renderizaValor(valores[idx])}
            </td>
          )
        }
      }))
    }

    return  ( valores.map((valor, idxValor) => {
      return (
        <td key={`valor_${idxValor}`} className="text-nowrap text-right">
          {renderizaValor(valor)} 
        </td>
      )
      })
    )
  }

  const renderizaLinhaReceitas = ({ legenda, valores, dias }) => {
    return (<tr key={`${legenda}_valor`}>
      <td className="bg-white">{legenda}</td>
      {renderizaColunasReceitas(valores, dias)} 
      </tr>)
    }

  const renderizaLinhaValores = ({ legenda, valores }) => {
    return (<tr key={`${legenda}_valor`}>
      <td className="bg-white">{legenda}</td>
      {renderizaColunasValores(valores)} 
    </tr>)
  };

  const renderizaLinhaValoresInvestimentos = ({ legenda, valores, dia }) => (
    <tr key={`${legenda}_valor`}>
      <td className="bg-white">{legenda}</td>
      {renderizaColunaValoresInvestimentos(valores, dia)}
    </tr>
  )

  function isFloat(n){
    return Number(n) === n && n % 1 !== 0;
  }

  const renderizaLinhaTotais = ({ legenda, valores, dia, className, bgColumn }) => {
    if (!bgColumn) {
      bgColumn = "bg-white";
    }

    let thClassName = [bgColumn].join(" ");

    return (
      <tr key={`${legenda}_totais`} className={className}>
        <th scope="row" className={thClassName}>
          {legenda}
        </th>
        {valores.map((valor, idxValor) => (
          <th
            scope="row"
            key={`total_${idxValor}`}
            className="text-nowrap text-right"
          >
            {renderizaValor(valor)}
          </th>
        ))}
      </tr>
    );
  };

  const renderizaLinhaTotaisReceitas = () => {
    return renderizaLinhaTotais({
      legenda: "Total Receitas",
      valores: totaisReceitas,
      className: "table-success",
      bgColumn: "table-success",
    });
  };

  const renderizaLinhaTotaisDespesas = () => {
    return renderizaLinhaTotais({
      legenda: "Total Despesas",
      valores: totaisDespesas,
      className: "table-warning",
      bgColumn: "table-warning",
    });
  };

  const renderizaLinhaTotaisContribuicoes = () => {
    return renderizaLinhaTotais({
      legenda: "Investimentos mensais",
      valores: totaisContribuicoes,
      className: "table-primary",
      bgColumn: "table-primary",
    });
  };

  const renderizaLinhaResultados = () => {
    return renderizaLinhaTotais({
      legenda: (
        <>
          Resultado (<em>Capacidade de Poupança</em>)
        </>
      ),
      valores: resultados,
      className: "bg-white",
      bgColumn: "bg-white",
    });
  };

  const renderizaLinhaPatrimoniosLiquidos = () => {
    return renderizaLinhaTotais({
      legenda: "Patrimônio líquido",
      valores: patrimoniosLiquidos,
      className: "bg-white",
      bgColumn: "bg-white",
    });
  };

  const toggleExpandirCategoria = (idxCategoria) => {
    let novoObjeto = [...categoriasExpandidas];
    novoObjeto[idxCategoria] = !novoObjeto[idxCategoria];
    setaCategoriasExpandidas(novoObjeto);
  };
  
  const toggleExpandirTipos = (idxtipo) => {
    let novoObjeto = [...tiposExpandidos];
    novoObjeto[idxtipo] = !novoObjeto[idxtipo];
    setaTiposExpandidos(novoObjeto);
  };

  const toggleExpandirReceitas = () => {
    setaReceitasExpandidas(!receitasExpandidas);
  };

  const toggleExpandirDespesas = () => {
    setaDespesasExpandidas(!despesasExpandidas);
  };

  const toggleExpandirContribuicoes = () => {
    setaContribuicoesExpandidas(!contribuicoesExpandidas);
  };

  const handleExportToExcel = async(e) => {
    e.preventDefault();

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const primeiraLinha = []
    const intervalos = processaIntervalos();
    intervalos.forEach((intervalo) => {
      primeiraLinha.push(moment([intervalo.ano, intervalo.mes - 1]).format("MMM") + " " + intervalo.ano)
    })
    primeiraLinha.push("Média", "Total");

    const despesasLabels = [];
  
    categoriasDespesas.forEach((categoria) => {
      let despesa = [];
      despesa.push(categoria.categoria);
      categoria.valores.map((valor) => despesa.push(valor));
      despesasLabels.push(despesa);
    })

    const contribuicoesLabel = [];
    contribuicoes.forEach((contribuicao) => {
      let contribui = [];
      contribui.push(contribuicao.legenda);
      contribuicao.valores.map((valor) => contribui.push(valor));
      contribuicoesLabel.push(contribui);
    })
    
    const capacidadeResultado = ['Resultado (Capacidade de Poupança)']
    resultados.forEach((resultado) => {
      capacidadeResultado.push(resultado)
    })

    const receitasLabel = [];
    receitas.forEach((receita) => {
      let receitaIndividual = [];
      receitaIndividual.push(receita.tipo);
      receita.valores.map((valor) => receitaIndividual.push(valor));
      receitasLabel.push(receitaIndividual)
    })

    const segundaLinha = ["Receitas"];
    totaisReceitas.forEach((receita) => {
      segundaLinha.push(receita);
    })

    const terceiraLinha = ["Despesas"];
    totaisDespesas.forEach((despesa) => {
      terceiraLinha.push(despesa)
    })

    const quartaLinha = ["Investimentos Mensais"];
    totaisContribuicoes.forEach((contribuicao) => {
      quartaLinha.push(contribuicao)
    })

    const quintaLinha = ["Balanço Patrimonial"];
    totaisContribuicoes.forEach((contribuicao) => {
      quintaLinha.push("#");
    })

    const resultadosPoupanca = ["Resultado capacidade de poupança"]
    resultados.forEach((resultado) => {
      resultadosPoupanca.push(resultado)
    })

    const sextaLinha = ["Patrimonios"];
    patrimonios.forEach((patrimonio) => {
      sextaLinha.push(patrimonio)
    })  

    const setimaLinha = ["Dividas"];
    financiamentos.forEach((financiamento) => {
      setimaLinha.push(financiamento)
    })

    const oitavaLinha = ["Patrimonio Líquido"];
    patrimoniosLiquidos.forEach((patrimonio) => {
      oitavaLinha.push(patrimonio)
    })

    const final = [
      primeiraLinha,
      segundaLinha,
    ];

    const division = []

    primeiraLinha.map((val) => division.push('#'))
    division.push('#')

    receitasLabel.map(receita => final.push(receita));

    final.push(division);

    final.push(terceiraLinha);

    despesasLabels.map(despesa => final.push(despesa))

    final.push(division);
    final.push(quartaLinha);

    contribuicoesLabel.map(contribuicao => final.push(contribuicao))

    final.push(division)
    final.push(capacidadeResultado)
    final.push(division)

    final.push(quintaLinha);
    final.push(sextaLinha);
    final.push(setimaLinha);
    final.push(oitavaLinha);
    
    const ws = XLSX.utils.aoa_to_sheet(final, { raw: true })
    
    const range = XLSX.utils.decode_range(ws["!ref"]);
    const rowCount = range.e.r;
    const columnCount = range.e.c;
    
    ws['!cols'] = [{ wch: 20 }]

    for(let row = 0; row <= rowCount; row++) {
      for(let col = 0; col <= columnCount; col++) {
        const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

        ws['!cols'].push({wch: 20})

        if(!ws[cellRef]) {
          continue
        }
        
        if(isFloat(ws[cellRef].v)) {
          ws[cellRef].z = '2';
        }

        if(typeof ws[cellRef].v === typeof 12) {
          ws[cellRef].t = 'n';
          ws[cellRef].f = '=' + ws[cellRef].v;
        }
        
      }
    }

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "DRE " + selectedDropdown + fileExtension);
  }

  return (
    <div className="d-flex flex-column h-100">
      <h4>DRE</h4>
      <div className="mb-2">
        <DateSpanSelector
            mesInicial={mesInicial}
            anoInicial={anoInicial}
            mesFinal={mesFinal}
            anoFinal={anoFinal}
            onChange={onChangeDateSpan}
          />  
      </div>
      <div className="flex-fill h-100 position-relative mb-4">
        <div className="position-absolute w-100 h-100 overflow-auto">
          <div className="dre">
            {semDados && (
              <div className="mt-3">
                <Alert color="secondary">
                  <i className="far fa-meh-blank"></i>
                  &nbsp; Sem dados para exibir.
                </Alert>
              </div>
            )}
            {isLoading && (
              <div className="text-muted text-center mt-3">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Carregando...</span>
                </div>
              </div>
            )}
            {!isLoading && !semDados && (
              <Table className="tbl_dre mt-2 bg-white" borderless>
                <thead className="bg-white">
                  <tr>
                    <th className="bg-white sticky-top"></th>
                    {colunasMeses.map((coluna) => (
                      <th
                        className="text-right bg-white sticky-top"
                        key={coluna}
                      >
                        {coluna}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-dark">
                    <th scope="row" className="table-dark">
                      <Button
                        color="link"
                        title={
                          receitasExpandidas
                            ? `Recolher Receitas`
                            : `Expandir Receitas`
                        }
                        onClick={toggleExpandirReceitas}
                        className="text-nowrap text-body text-left font-weight-bold p-0"
                      >
                        <span className="text-white">
                          Receitas
                          {receitasExpandidas ? (
                            <i className="fas fa-caret-down ml-2" />
                          ) : (
                            <i className="fas fa-caret-right ml-2" />
                          )}
                        </span>
                      </Button>
                    </th>
                    {receitasExpandidas ? (
                      <td
                        colSpan={colunasMeses.length}
                        className="table-dark"
                      ></td>
                    ) : (
                      renderizaColunasValores(totaisReceitas)
                    )}
                  </tr>
                  {receitasExpandidas &&
                    receitas.map((tipo, idxTipo) => (
                      <Fragment key={`receita_${tipo.tipo}`}>
                        <tr className="table-warning">
                          <th scope="row">
                            <Button
                              color="link"
                              title={
                                tiposExpandidos[idxTipo]
                                  ? `Recolher ${tipo.tipo}`
                                  : `Expandir ${tipo.tipo}`
                              }
                              onClick={toggleExpandirTipos.bind(
                                this,
                                idxTipo
                              )}
                              className="text-nowrap text-body text-left font-weight-bold p-0"
                            >
                              {tipo.icone && (
                                <i
                                  className={tipo.icone + ` mr-2`}
                                  style={{ width: "1rem" }}
                                />
                              )}
                              {tipo.tipo}
                              {tiposExpandidos[idxTipo] ? (
                                <i className="fas fa-caret-down ml-2" />
                              ) : (
                                <i className="fas fa-caret-right ml-2" />
                              )}
                            </Button>
                          </th>

                          {tiposExpandidos[idxTipo] ? (
                            <td colSpan={colunasMeses.length}></td>
                          ) : (
                            renderizaColunasValores(tipo.valores)
                          )}
                        </tr>
                        {tiposExpandidos[idxTipo] &&
                          tipo.receitas.map((receita) =>
                            renderizaLinhaReceitas(receita)
                          )}
                        {tiposExpandidos[idxTipo] &&
                          renderizaLinhaTotais({
                            legenda: tipo.tipo,
                            valores: tipo.valores,
                          })}
                      </Fragment>
                    ))}
                  {receitasExpandidas && renderizaLinhaTotaisReceitas()}
                  <tr className="bg-dark">
                    <th scope="row" className="bg-dark">
                      {/* Despesas */}
                      <Button
                        color="link"
                        title={
                          despesasExpandidas
                            ? `Recolher Despesas`
                            : `Expandir Despesas`
                        }
                        onClick={toggleExpandirDespesas}
                        className="text-nowrap text-body text-left font-weight-bold p-0"
                      >
                        <span className="text-white">
                          Despesas
                          {despesasExpandidas ? (
                            <i className="fas fa-caret-down ml-2" />
                          ) : (
                            <i className="fas fa-caret-right ml-2" />
                          )}
                        </span>
                      </Button>
                    </th>
                    {despesasExpandidas ? (
                      <td
                        colSpan={colunasMeses.length}
                        className="table-dark"
                      ></td>
                    ) : (
                      renderizaColunasValores(totaisDespesas)
                    )}
                  </tr>
                  {despesasExpandidas &&
                    categoriasDespesas.map((categoria, idxCategoria) => (
                      <Fragment key={`despesa_${categoria.categoria}`}>
                        <tr className="table-warning">
                          <th scope="row">
                            <Button
                              color="link"
                              title={
                                categoriasExpandidas[idxCategoria]
                                  ? `Recolher ${categoria.categoria}`
                                  : `Expandir ${categoria.categoria}`
                              }
                              onClick={toggleExpandirCategoria.bind(
                                this,
                                idxCategoria
                              )}
                              className="text-nowrap text-body text-left font-weight-bold p-0"
                            >
                              {categoria.icone && (
                                <i
                                  className={categoria.icone + ` mr-2`}
                                  style={{ width: "1rem" }}
                                />
                              )}
                              {categoria.categoria}
                              {categoriasExpandidas[idxCategoria] ? (
                                <i className="fas fa-caret-down ml-2" />
                              ) : (
                                <i className="fas fa-caret-right ml-2" />
                              )}
                            </Button>
                          </th>

                          {categoriasExpandidas[idxCategoria] ? (
                            <td colSpan={colunasMeses.length}></td>
                          ) : (
                            renderizaColunasValores(categoria.valores)
                          )}
                        </tr>
                        
                        {categoriasExpandidas[idxCategoria] &&
                          categoria.despesas.map((despesa) => {
                            return renderizaLinhaValoresDespesas(despesa)
                        })}
                          
                        {categoriasExpandidas[idxCategoria] &&
                          renderizaLinhaTotais({
                            legenda: categoria.categoria,
                            valores: categoria.valores,
                            dia: categoria.dia
                          })}
                      </Fragment>
                    ))}
                  {despesasExpandidas && renderizaLinhaTotaisDespesas()}
                  <tr className="bg-dark">
                    <th scope="row" className="table-dark">
                      {/* Contribuições */}
                      <Button
                        color="link"
                        title={
                          contribuicoesExpandidas
                            ? `Recolher Investimentos mensais`
                            : `Expandir Investimentos mensais`
                        }
                        onClick={toggleExpandirContribuicoes}
                        className="text-nowrap text-body text-left font-weight-bold p-0"
                      >
                        <span className="text-white">
                          Investimentos mensais
                          {contribuicoesExpandidas ? (
                            <i className="fas fa-caret-down ml-2" />
                          ) : (
                            <i className="fas fa-caret-right ml-2" />
                          )}
                        </span>
                      </Button>
                    </th>
                    {contribuicoesExpandidas ? (
                      <td
                        colSpan={colunasMeses.length}
                        className="table-dark"
                      ></td>
                    ) : (
                      renderizaColunasValores(totaisContribuicoes)
                    )}
                  </tr>
                  {contribuicoesExpandidas &&
                    contribuicoes.map((contribuicao, i) => {
                      return  renderizaLinhaValoresInvestimentos(contribuicao)
                    })}
                  {contribuicoesExpandidas &&
                    renderizaLinhaTotaisContribuicoes()}
                  {renderizaLinhaResultados()}
                  <tr>
                    <th scope="row" className="bg-dark">
                      Balanço Patrimonial
                    </th>
                    <td
                      colSpan={colunasMeses.length}
                      className="table-dark"
                    ></td>
                  </tr>
                  {renderizaLinhaValores({
                    legenda: "Patrimônios",
                    valores: patrimonios,
                  })}
                  {renderizaLinhaValores({
                    legenda: "Dívidas",
                    valores: financiamentos,
                  })}
                  {renderizaLinhaPatrimoniosLiquidos()}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
      
      <div className="row ml-0">
          <div className="col-sm-3 ml-0">
          {!isLoading && <div className=""><Button
            color="success"
            size="mt-2 mb-2 ml-0"
            onClick={handleExportToExcel}
          >Exportar para excel</Button>
          </div>
          }
          </div>
      </div>
    </div>
  );
}

export default withRouter(DRE);
