import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  getTokenClientId,
  getAxiosAuth,
  isPositiveBRL,
  fromBRL,
  toBRLValueOnly, 
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import moment from "moment";
import {
  Form,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Alert
} from "reactstrap";
import {
  DateCarousel,
  Monetary,
  FormErrMsg,
  ModalConfirmarAlteracoesNaoSalvas
} from "../../../components";
import ImagemPadrao from "../../../assets/img/sonho-independencia-financeira.jpg";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";

function Progresso(props) {
  const m = moment();
  const clienteId = getTokenClientId();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [tmpMes, setaTmpMes] = useState();
  const [tmpAno, setaTmpAno] = useState();
  const [modalAvisoAberto, setaModalAvisoAberto] = useState(false);
  const [alteracoesPendentes, setaAlteracoesPendentes] = useState();
  const [refreshDateCaroulsel, setaRefreshDateCaroulsel] = useState();
  const [obter, setaObter] = useState();
  const [contribuicoes, setaContribuicoes] = useState([]);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [salvar, setaSalvar] = useState();
  const [projetos,setProjetos] = useState({
    curtoPrazo:[],
    medioPrazo:[],
    longoPrazo:[],
  });


  const NOVA_CONTRIBUICAO = {
    id: null,
    valor: "",
    valorErro: "",
    saldoAcumulado: "",
    totalDisponivel: "",
    projetoNome: "",
    projetoId: null
  };
  // primeiro acesso: obtem progresso dos projetos atuais
  useEffect(() => {
    const getContribuicoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/projetos/contribuicoes`;
      const params = {
        filtroMes: mes,
        filtroAno: ano
      };
      let response;

      try {
        response = await axiosInstance.get(uri, { params });
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
          logFront(err, "getContribuicoes()");
        } else {
          console.error(err);
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          // alert(err.message);
          return err.message;
        }
      }

      const dados = response.data;

      if ("contribuicoes" in dados && Array.isArray(dados.contribuicoes)) {
        let novasContribuicoes = dados.contribuicoes.map(dados => {
          let contribuicao = {
            ...NOVA_CONTRIBUICAO,
            id: dados.id,
            valor: toBRLValueOnly(dados.valor),
            saldoAcumulado: toBRLValueOnly(dados.saldoAcumulado),
            totalDisponivel: toBRLValueOnly(dados.valor + dados.saldoAcumulado),
            projetoNome: dados.projetoNome,
            projetoId: dados.projetoId,
            prazo: dados.prazo
          };

          return contribuicao;
        });

        setaContribuicoes(novasContribuicoes);
      }
    };

    if (obter) {
      getContribuicoes();
    }
  }, [obter]);

  //OBTEM OS PROJETOS
  const getProjetos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/projetos`;
    let response;

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
        logFront(err, "getProjetos()");
      } else {
        console.error(err);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }
    if (response.status === 200) {
      const dados = response.data;
      setProjetos(dados);

    } else if (response.status === 404) {
      console.error(response);
      props.history.push("/404");
      return;
    }
  };

  // primeiro acesso: lista projetos
  useEffect(() => {
    getProjetos();
  }, []);

  const onChangeDate = (novoMes, novoAno) => {
    if (alteracoesPendentes) {
      setaTmpMes(novoMes);
      setaTmpAno(novoAno);
      setaModalAvisoAberto(true);
      setaRefreshDateCaroulsel(new Date());
    } else {
      setaMes(novoMes);
      setaAno(novoAno);
    }
  };

  const onConfirmModal = () => {
    setaModalAvisoAberto(false);
    setaAlteracoesPendentes(false);
    setaMes(tmpMes);
    setaAno(tmpAno);
  };

  const onCancelModal = () => {
    setaModalAvisoAberto(false);
  };

  // quando mudar a data
  useEffect(() => {
    setaObter(new Date());
  }, [mes, ano]);

  const validaValor = idx => {
    if (!contribuicoes[idx]) return true;

    let novasContribuicoes = [...contribuicoes];

    const contribuicao = novasContribuicoes[idx];

    let valido = true;

    if (validator.isEmpty(contribuicao.valor, { ignore_whitespace: true })) {
      contribuicao.valorErro = "Campo obrigatório.";
      valido = false;
    }

    if (valido && !isPositiveBRL(contribuicao.valor)) {
      contribuicao.valorErro = "Valor monetário inválido.";
      valido = false;
    }

    if (valido) {
      contribuicao.valorErro = false;
    }

    setaContribuicoes(novasContribuicoes);

    return valido;
  };

  const onChangeValor = (idx, e) => {
    if (!contribuicoes[idx]) return;

    let novasContribuicoes = [...contribuicoes];

    novasContribuicoes[idx]["valor"] = e.target.value;

    if (isPositiveBRL(e.target.value)) {
      novasContribuicoes[idx]["totalDisponivel"] = toBRLValueOnly(
        fromBRL(e.target.value) +
          fromBRL(novasContribuicoes[idx]["saldoAcumulado"])
      );
    }

    setaContribuicoes(novasContribuicoes);

    setaAlteracoesPendentes(new Date());
  };

  const onBlurValor = idx => {
    validaValor(idx);
  };

  const onSubmitForm = e => {
    e.preventDefault();

    const valido = contribuicoes.reduce((valido, contribuicao, idx) => {
      const valorValido = validaValor(idx);

      if (valido && !valorValido) {
        valido = false;
      }

      return valido;
    }, true);

    if (valido) {
      setaFormularioValido(true);
      setaSalvar(new Date());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  // salvar
  useEffect(() => {
    const dataContribuicao = moment()
      .set("year", Number(ano))
      .set("month", Number(mes) - 1)
      .startOf("month")
      .format("YYYY-MM-DD");

    const putContribuicoes = async () => {
      const dadosContribuicoes = contribuicoes.map(contribuicao => ({
        id: contribuicao.id,
        dataContribuicao,
        valor: fromBRL(contribuicao.valor),
        projetoId: contribuicao.projetoId
      }));

      const dados = { contribuicoes: dadosContribuicoes };

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/projetos/contribuicoes`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err.response);
        } else {
          const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          console.error(err);
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        setaObter(new Date());
        toast("Progresso salvo com sucesso!");
        setaAlteracoesPendentes(false);
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        const responseApp = await logApp(axiosInstance, response);
      await logClient(axiosInstance, null, response, responseApp.data.trace_id, uri, response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(response.message);
        return response.message;
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      putContribuicoes();
    }
  }, [salvar]);

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <ModalConfirmarAlteracoesNaoSalvas
        aberto={modalAvisoAberto}
        onContinue={onConfirmModal}
        onCancel={onCancelModal}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <div className="text-center">
          <DateCarousel
            ano={ano}
            mes={mes}
            onChange={onChangeDate}
            refresh={refreshDateCaroulsel}
          />
        </div>
        <Container>
          <Card>
            <CardHeader>
              <i className="fa fa-circle-notch" />
              <strong>Progresso</strong>
            </CardHeader>
            <CardBody>
              {(!contribuicoes || contribuicoes.length === 0) && (
                <p className="text-muted text-center mt-3">
                  Sem projetos no período.
                </p>
              )}
              {contribuicoes.map((contribuicao, idx) => {
                let imageSrc = ImagemPadrao;
                let projetoCorrespondente;

               if (projetos.curtoPrazo.some(projeto => projeto.id === contribuicao.projetoId)) {
                projetoCorrespondente = projetos.curtoPrazo.find(projeto => projeto.id === contribuicao.projetoId);
              } else if (projetos.medioPrazo.some(projeto => projeto.id === contribuicao.projetoId)) {
                projetoCorrespondente = projetos.medioPrazo.find(projeto => projeto.id === contribuicao.projetoId);
              } else if (projetos.longoPrazo.some(projeto => projeto.id === contribuicao.projetoId)) {
                projetoCorrespondente = projetos.longoPrazo.find(projeto => projeto.id === contribuicao.projetoId);
              }
              
              if (projetoCorrespondente && projetoCorrespondente.upload) {
                imageSrc = `data:image/png;base64,${projetoCorrespondente.upload.content}`;
              }

                return(
                <Row
                  form
                  className="mb-2"
                  key={`projeto_${contribuicao.projetoId}`}
                >
                  <Col sm={12} md={3} xl={4}>
                    <FormGroup>
                      <Label style={{ 
                        display: 'inline-block', 
                        maxWidth: '160px',
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap' 
                      }}>
                        <strong>{contribuicao.projetoNome}</strong> <br />
                        ({contribuicao.prazo > 1 ? "faltam" : "falta"} {contribuicao.prazo} {contribuicao.prazo > 1 ? "meses" : "mês"})
                      </Label>
                      <br />
                      <img
                        src={imageSrc}
                        alt={contribuicao.projetoNome}
                        height={40}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} xl={2}>
                    <FormGroup>
                      <Label>Saldo anterior:</Label>
                      <Monetary readOnly value={contribuicao.saldoAcumulado} />
                    </FormGroup>
                  </Col>
                  <Col md={3} xl={2}>
                    <FormGroup>
                      <Label className="required">Contribuição:</Label>
                      <Monetary
                        value={contribuicao.valor}
                        onChange={onChangeValor.bind(this, idx)}
                        onBlur={onBlurValor.bind(this, idx)}
                        valid={contribuicao.valorErro === false}
                        invalid={!!contribuicao.valorErro}
                      />
                      <FormErrMsg>{contribuicao.valorErro}</FormErrMsg>
                    </FormGroup>
                  </Col>
                  <Col md={3} xl={2}>
                    <FormGroup>
                      <Label>Total disponível:</Label>
                      <Monetary readOnly value={contribuicao.totalDisponivel} />
                    </FormGroup>
                  </Col>
                </Row>
              )})}
              {formularioValido === false && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível salvar. {formularioErro}
                </Alert>
              )}
            </CardBody>
            <CardFooter>
              <Button
                type="submit"
                color="primary"
                style={{ display: "block" }}
              >
                Salvar
              </Button>
            </CardFooter>
          </Card>
        </Container>
      </Form>
    </div>
  );
}

export default withRouter(Progresso);
