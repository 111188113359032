import React, { Fragment, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import PropTypes from "prop-types";
import { toBRL, getAxiosAuth, getTokenClientId, logApp, logClient } from "../../../utils";
import { withRouter } from "react-router-dom";
import moment from "moment";

function BtnModalRemoverContribuicao(props) {
  const clienteId = getTokenClientId();
  const [aberto, setaAberto] = useState(false);

  // --- eventos para o modal ---
  const toggle = e => {
    e && e.stopPropagation();
    setaAberto(!aberto);
  };

  const handleRemoverFuturas = async(e) => {
    e.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    let uri = `/clientes/${clienteId}/contribuicoes/${props.id}`;

    const dataContribuicao = moment([props.ano, props.mes - 1, props.dia]).format('YYYY-MM-DD')
    const dataSelect = moment([props.anoSelect, props.mesSelect - 1]).format('YYYY-MM-DD')

    if(props.recorrente) {
      try {
        await axiosInstance.delete(uri, { params: { 
          cascade: 1,
          data_contribuicao: dataContribuicao,
          descricao: props.descricao,
          recorrente: 1,
          valor: props.valor,
          dataAtual: dataSelect,
        } });
      } catch(err) {
        return
      }
  
      setaAberto(false);
      props.onDelete && props.onDelete(props.id);
    }
  }

  // --- eventos para o formulário ---
  const onSubmitForm = async e => {
    e.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/contribuicoes/${props.id}`;
    let response;

    try {
      response = await axiosInstance.delete(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
      } else {
        console.error(err, response);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }

    setaAberto(false);
    props.onDelete && props.onDelete(props.id);
  };

  return (
    <Fragment>
      <Button size="sm" onClick={toggle}>
        <i className="fas fa-times" />
      </Button>
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>Remover Investimento Mensal</ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <p>
              Deseja confirmar a remoção de <strong>{props.descricao}</strong>{" "}
              de <strong>{toBRL(props.valor)}</strong>?
            </p>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={onSubmitForm}>
            Remover
          </Button>
          { (props.recorrente===true) &&
          <Button color="danger" onClick={handleRemoverFuturas}>
            Remover todas as contribuicoes futuras
          </Button>}
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalRemoverContribuicao.propTypes = {
  id: PropTypes.number.isRequired,
  descricao: PropTypes.string.isRequired,
  valor: PropTypes.number.isRequired,
  onDelete: PropTypes.func
};

export default withRouter(BtnModalRemoverContribuicao);
