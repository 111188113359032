import React, { useEffect, useState, useRef } from "react";
import { Container, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getAxiosAuth, getTokenClientId, logApp, logClient, logFront } from "../../../utils";
import TabelaProtecoesConteudo from "./TabelaProtecoesConteudo";

function SeguroProtecoes(props) {
  const clienteId = getTokenClientId();
  const [protecoes, setaProtecoes] = useState([]);
  const [protecoesConjuge, setaProtecoesConjuge] = useState([]);
  const [inseriu, setaInseriu] = useState(null);
  const [atualizou, setaAtualizou] = useState(null);
  const [removeu, setaRemoveu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [temConjuge, setaTemConjuge] = useState();
  const isFirstRun = useRef(true);

  const onAdd = () => {
    setaInseriu(new Date());
  };

  const onEdit = () => {
    setaAtualizou(new Date());
  };

  const onDelete = () => {
    setaRemoveu(new Date());
  };

  const getProtecoes = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/protecoes`;
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
        console.error(err, response);
        logFront(err, "()");
      } else {
        console.error(err);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }

    setIsLoading(false);

    const dados = response.data;
    // console.log(dados);

    if (dados && dados.protecoes) {
      setaProtecoes(dados.protecoes);
    }

    if (dados && dados.protecoesConjuge) {
      setaProtecoesConjuge(dados.protecoesConjuge);
    }
  };

  const getConjuge = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/conjuge`;
    let response;

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
        logFront(err, "()");
      } else {
        console.error(err);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(response.message);
        return err.message;
      }

      if (response.status !== 200 && response.status !== 404) {
        console.error(err, response);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }

    if (response.status === 200) {
      setaTemConjuge(true);
    } else {
      setaTemConjuge(false);
    }
  };

  useEffect(() => {
    if (inseriu) {
      toast("Proteção adicionada!");
      getProtecoes();
    }
  }, [inseriu]);

  useEffect(() => {
    if (atualizou) {
      toast("Proteção atualizada!");
      getProtecoes();
    }
  }, [atualizou]);

  useEffect(() => {
    if (removeu) {
      toast("Proteção removida!");
      getProtecoes();
    }
  }, [removeu]);

  // primeiro acesso
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }

    getConjuge();
    getProtecoes();
  }, []);

  return (
    <div className="animated fadeIn">
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1049 }}
        />

        <Table size="sm" hover>
          <TabelaProtecoesConteudo
            clienteId={clienteId}
            isLoading={isLoading}
            protecoes={protecoes}
            onAdd={onAdd}
            onEdit={onEdit}
            onDelete={onDelete}
          />

          {temConjuge && (
            <TabelaProtecoesConteudo
              clienteId={clienteId}
              isLoading={isLoading}
              protecoes={protecoesConjuge}
              onAdd={onAdd}
              onEdit={onEdit}
              onDelete={onDelete}
              conjuge={true}
            />
          )}
        </Table>
      </Container>
    </div>
  );
}

export default withRouter(SeguroProtecoes);
