import React, { useEffect, useState } from "react";
import { getAxios, toDecimal, logFront } from "../../utils";
import { Line } from "react-chartjs-2";
import moment from "moment";

function IndiceCotacaoSELIC(props) {
  const [valorAtual, setaValorAtual] = useState(11.25);
  const [legendas, setaLegendas] = useState([]);
  const [valores, setaValores] = useState([]);

  /**
   * Primeiro acesso: obtem cotação da SELIC
   */
  useEffect(() => {
    const getSELIC = async () => {
      const axiosInstance = getAxios();
      const uri = "/selic";
      let response;

      try {
        response = await axiosInstance.get(uri);

      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err);
          logFront(err, "getSELIC()");
          return;
        }
      }

      if (response.status === 200) {
        // console.log(response.data);

        if (Array.isArray(response.data) && response.data.length > 0) {
          let ultimo = response.data[response.data.length - 1];

          if ("valor" in ultimo) {
            setaValorAtual(ultimo.valor);
          }

          let novasLegendas = [];
          let novosValores = [];

          response.data.forEach(entrada => {
            novasLegendas.push(moment(entrada.data, "DD/MM/YYYY").format("YYYY-MM-DD"));
            novosValores.push(parseFloat(entrada.valor));
          });

          setaLegendas(novasLegendas);
          setaValores(novosValores);
        }
      }
    };

    getSELIC();
  }, []);

  const chart = {
    labels: legendas,
    datasets: [
      {
        backgroundColor: "transparent",
        borderColor: "#63c2de",
        data: valores
      }
    ]
  };

  const chartOptions = {
    tooltips: {
      enabled: false
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      xAxes: [
        {
          display: false
        }
      ],
      yAxes: [
        {
          display: false
        }
      ]
    },
    elements: {
      line: {
        borderWidth: 2
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    },
    legend: {
      display: false
    }
  };

  const openLink = () => window.open('https://www.bcb.gov.br/controleinflacao/historicotaxasjuros', '_blank','noreferrer noopener');

  return (
    <div
      className="callout callout-info" 
      onClick={openLink}
      style={{cursor: "pointer"}}
    >
      <small className="text-muted">SELIC (%) a.a.</small>
      <br />
      <strong className="h4">{toDecimal(valorAtual)}</strong>
      <div className="chart-wrapper">
        <Line data={chart} options={chartOptions} width={100} height={30} />
      </div>
    </div>
  );
}

export default IndiceCotacaoSELIC;
