import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Container, Button, Table, Badge } from "reactstrap";
import { getAxiosAuth, getTokenClientId, logApp, logClient, logFront } from "../../../utils";
import FileMenu from "./FileMenu";
import SuccessModal from "./SuccessModal";
import LinhaReceita from "./LinhaReceita";
import LinhaDespesa from "./LinhaDespesa";
import { LoadingSpinner } from "../../../components";
import { toast, ToastContainer } from "react-toastify";

function UploadDeExtratos(props) {
  const clienteId = getTokenClientId();
  const [positiveEntries, setPositiveEntries] = useState([]);
  const [negativeEntries, setNegativeEntries] = useState([]);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [patrimonios, setaPatrimonios] = useState({});
  const [tagsOpcoes, setaTagsOpcoes] = useState("");
  const [loading, setLoading] = useState(false);
  const [globalTags, setGlobalTags] = useState([]);
  const [totalDespesaImportada, setTotalDespesaImportada] = useState(0);
  const [totalReceitaImportada, setTotalReceitaImportada] = useState(0);
  const [valorTotalReceita, setValorTotalReceita] = useState(0);
  const [valorTotalDespesa, setValorTotalDespesa] = useState(0);
  const [isCreditoSelected, setIsCreditoSelected] = useState(false);
  const [datesPatrimonios, setDatesPatrimonios] = useState({});

  const [incorrectDate, setIncorrectDate] = useState(
    { positiveEntries: {}, negativeEntries: {} }
  );

  const [itensGlobal, setItensGlobal] = useState([]);

  React.useEffect(() => {
    setItensGlobal(globalTags);
  }, [globalTags])

  React.useEffect(() => {
    const fetchData = async () => {
      const uploadData = localStorage.getItem(`uploadData-${clienteId}`);

      if (!uploadData) return;

      const {
        positiveEntries,
        negativeEntries,
        isCreditoSelected,
        datesPatrimonios
      } = JSON.parse(uploadData);

      if (positiveEntries) {
        setPositiveEntries(positiveEntries);
      }

      if (negativeEntries) {
        setNegativeEntries(negativeEntries);
      }

      if (isCreditoSelected !== null) {
        setIsCreditoSelected(isCreditoSelected);
      }

      if (datesPatrimonios) {
        setDatesPatrimonios(datesPatrimonios);

        for (const [ano, mesArray] of Object.entries(datesPatrimonios)) {
          const parsedAno = parseInt(ano, 10);
          for (const mes of mesArray) {
            const parsedMes = parseInt(mes, 10);

            await getPatrimonios(parsedMes, parsedAno);
          }
        }
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    const data = {
      isCreditoSelected,
      positiveEntries,
      negativeEntries,
      datesPatrimonios,
    };

    localStorage.setItem(
      `uploadData-${clienteId}`,
      JSON.stringify(data)
    );
  }, [
    isCreditoSelected,
    positiveEntries,
    negativeEntries,
    datesPatrimonios
  ]);
  
  const handleClean = async () => {
    localStorage.removeItem(`uploadData-${clienteId}`);

    setPositiveEntries([]);
    setNegativeEntries([]);
    setIsCreditoSelected(false);
    setDatesPatrimonios({});
  }

  const handleUpload = async (type, file, selectedBank = null, password = '', transactionType, initialBalanceType) => {
    setLoading(true);

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/uploadExtrato/parser`;
    let response;

    const data = new FormData();
    data.append("type", type);
    data.append("file", file);
    data.append("bankType", selectedBank)
    data.append("password", password)
    data.append("transactionType", transactionType)
    data.append("initialBalanceType", initialBalanceType)

    try {
      response = await axiosInstance.post(uri, data, { timeout: 40000 });
    } catch (err) {
      logFront(err, "handleUpload()");
      if (err.response && err.response.status === 400) {
        const errMsg = err.response.data.msg;
        toast.error(errMsg);
        handleClean();
      }
      setLoading(false);
      if (err.response) {
        response = err.response;
      }
      console.error(err, response);
      return;
    }


    if (response && response.data) {
      if ("receitas" in response.data) {
        setPositiveEntries(response.data.receitas);
      }

      if ("despesas" in response.data) {
        setNegativeEntries(response.data.despesas);
      }

      if ("dates" in response.data) {
        setDatesPatrimonios(response.data.dates);
        for (const [ano, meses] of Object.entries(response.data.dates)) {
          for (const mes of meses) {
            await getPatrimonios(mes, ano);
          }
        }
      }
    }

    setLoading(false);
  };

  const formatTags = (localTags) => {
    localTags = localTags ? localTags : [];
    const localTagsAux = localTags.map((tag) => ({
      id: tag.__isNew__ ? null : tag.value,
      nome: tag.label,
    }));

    return localTagsAux;
  }

  /* Funções para receitas */

  useEffect(() => {
    let total = 0;
    let valorTotal = 0;
    positiveEntries.forEach((receita) => {
      valorTotal += receita.valor;
      if (receita.duplication === true) {
        total += 1;
      }
    })
    const formattedValorTotal = valorTotal.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
    setValorTotalReceita(formattedValorTotal);
    setTotalReceitaImportada(total);
  }, [positiveEntries])

  const formatReceita = (receita) => ({
    ...receita,
    tags: formatTags(receita.tags) || null,
    patrimonioId: receita.patrimonio || null,
  })

  function fixDate(v) {
    const partesData = v.split('/').map(part => part.startsWith('0') ? part.substring(1) : part);
    return partesData.join('/');
  }

  const handleEditReceita = (index, field, value) => {
    if (field === 'data') {
      value = fixDate(value);
    }
    const updatedReceitas = [...positiveEntries];
    updatedReceitas[index][field] = value;
    setPositiveEntries(updatedReceitas);
  };

  const handleDeleteReceita = (index) => {
    const updatedReceitas = [...positiveEntries];
    updatedReceitas.splice(index, 1);
    setPositiveEntries(updatedReceitas);
  };

  /* Funções para despesas */

  const formatDespesa = (despesa) => ({
    ...despesa,
    tags: formatTags(despesa.tags) || null,
    patrimonioId: despesa.patrimonio || null,
  })

  useEffect(() => {
    let total = 0;
    let valorTotal = 0;
    negativeEntries.forEach((despesa) => {
      valorTotal += despesa.valor;
      if (despesa.duplication === true) {
        total += 1;
      }
    })
    const formattedValorTotal = valorTotal.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
    setValorTotalDespesa(formattedValorTotal);
    setTotalDespesaImportada(total);
  }, [negativeEntries])

  const handleEditDespesa = (index, field, value) => {
    if (field === 'data') {
      value = fixDate(value);
    }
    const updatedDespesas = [...negativeEntries];
    updatedDespesas[index][field] = value;
    setNegativeEntries(updatedDespesas);
  };

  const handleDeleteDespesa = (index) => {
    const updatedDespesas = [...negativeEntries];
    updatedDespesas.splice(index, 1);
    setNegativeEntries(updatedDespesas);
  };

  const handleSend = async (event) => {
    event.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const dados = {
      despesas: negativeEntries.map(formatDespesa),
      receitas: positiveEntries.map(formatReceita),
      regimeSelecionado,
      fluxoCaixaDate:
        regimeSelecionado === "fluxoCaixa"
          ? fluxoCaixaDate.value.replaceAll("-", "/")
          : null,
    }

    const uri = `/clientes/${clienteId}/uploadExtrato`;
    let response;

    try {
      response = await axiosInstance.post(uri, dados);
      setConfirmationOpen(true);
      handleClean();
    } catch (err) {
      if (err.response) {
        response = err.response;
        logFront(err, "handleSend()");
      } else {
        console.error(err, response);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }
  };

  const getPatrimonios = async (mes, ano, update = false) => {
    if ((!patrimonios[ano] || !patrimonios[ano][mes]) || update) {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/patrimonios`;
      let response;
      let dados = {
        filtroMes: mes,
        filtroAno: ano,
      };

      try {
        response = await axiosInstance.get(uri, { params: dados });
      } catch (err) {
        logFront(err, "getPatrimonios()");
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        return;
      }

      if (response && response.data && "patrimonios" in response.data) {
        const novosPatrimonios = response.data.patrimonios.map((p) => ({
          id: p.id.toString(),
          descricao: p.descricao,
        }));

        const updatePatrimonios = update ? { ...patrimonios } : patrimonios;
        if (!updatePatrimonios[ano]) updatePatrimonios[ano] = {};
        updatePatrimonios[ano][mes] = novosPatrimonios;
        setaPatrimonios(updatePatrimonios);
      }
    }
  };

  const handleUpdatePatrimonios = (mes, ano) => getPatrimonios(mes, ano, true);

  useEffect(() => {
    const getTagsOpcoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/tags`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        logFront(err, "handleUpdatePatrimonios()");
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        return;
      }

      if (response && response.data && "tags" in response.data) {
        const novasTagsOpcoes = response.data.tags.map((tag) => ({
          value: tag.id,
          label: tag.nome,
        }));

        setaTagsOpcoes(novasTagsOpcoes);
      }
    };

    getTagsOpcoes()
  }, [])

  const handleChangeGlobalTags = (tags) => {
    setGlobalTags(tags)
  };

  const handleCloseSuccessModal = () => {
    setConfirmationOpen(false);
    setPositiveEntries([]);
    setNegativeEntries([]);
    setGlobalTags([]);
  }
  const [regimeSelecionado, setRegimeSelecionado] = useState(null);
  const handleRegimeChange = (event) => {
    setRegimeSelecionado(event.target.value);
  };

  const handleCreditoVerify = (selectedBank) => {
    const name = selectedBank.name;

    const creditoNames = [
      "Banco do Brasil Cartao de Credito",
      "Itau Cartao de Credito",
      "Sicoob Cartao de Credito",
      "Nubank Cartao de Credito",
      "Santander Cartao de Credito",
      "BTG Cartao de Credito",
      "Bradesco Cartao de Credito"
    ];
    
    setIsCreditoSelected(creditoNames.includes(name));
  };
  const [fluxoCaixaDate, setFluxoCaixaDate] = useState({ value: "" });

  React.useEffect(() => {
    const newIncorrectDate = { ...incorrectDate };

    positiveEntries.forEach((_, index) => {
      if (newIncorrectDate.positiveEntries[index] === undefined) {
        newIncorrectDate.positiveEntries[index] = false;
      }
    });

    negativeEntries.forEach((_, index) => {
      if (newIncorrectDate.negativeEntries[index] === undefined) {
        newIncorrectDate.negativeEntries[index] = false;
      }
    });

    setIncorrectDate(newIncorrectDate);
  }, [positiveEntries, negativeEntries]);


  const hasIncorrectDate = () => {
    const positiveIncorrect = Object.values(incorrectDate.positiveEntries).some(value => value === true);
    const negativeIncorrect = Object.values(incorrectDate.negativeEntries).some(value => value === true);
    return positiveIncorrect || negativeIncorrect;
  };


  let receitaTable = <>
    <h3>RECEITAS</h3>
    <div className="mb-2 d-flex align-items-center">
      <h5 className="mb-0 mr-4">Total: {positiveEntries.length} </h5>
      <h5 className="mb-0 mr-4">Já importadas: {totalReceitaImportada} </h5>
      <h5 className="mb-0">Total: {valorTotalReceita} </h5>

    </div>

    <Table size="sm" cel>
      <thead>
        <tr style={{ backgroundColor: '#D2E7AF' }}>
          <th style={{ width: 60 }}>DATA</th>
          <th >DESCRIÇÃO</th>
          <th style={{ width: 100 }}>VALOR</th>
          <th style={{ width: 120 }}>TIPO</th>
          <th style={{ width: 196 }}>TAGS</th>
          <th style={{ width: 210 }}>PATRIMÔNIOS</th>
          <th style={{ width: 10 }} />
        </tr>
      </thead>
      <tbody>
        {positiveEntries.map((receita, index) => (
          <LinhaReceita
            key={`receita_${index}`}
            index={index}
            receita={receita} // Passa a receita ajustada
            handleDelete={handleDeleteReceita}
            handleEdit={handleEditReceita}
            tagsOpcoes={tagsOpcoes}
            itensGlobal={itensGlobal}
            patrimonios={patrimonios}
            onUpdatePatrimonios={handleUpdatePatrimonios}
            incorrectDate={incorrectDate.positiveEntries[index]}
            setIncorrectDate={(value) => {
              const newIncorrectDates = { ...incorrectDate, positiveEntries: { ...incorrectDate.positiveEntries, [index]: value } };
              setIncorrectDate(newIncorrectDates);
            }}
          />
        ))}
      </tbody>
    </Table>
  </>

  let despesaTable = <>
    <h3>DESPESAS</h3>
    <div className="mb-2 d-flex align-items-center">
      <h5 className="mb-0 mr-4">Total: {negativeEntries.length} </h5>
      <h5 className="mb-0 mr-4">Já importadas: {totalDespesaImportada} </h5>
      <h5 className="mb-0">Total: {valorTotalDespesa} </h5>
    </div>

    <Table size='sm' cel>
      <thead>
        <tr style={{ backgroundColor: '#FCE4A3' }}>
          <th style={{ width: 60 }}>DATA</th>
          <th>DESCRIÇÃO</th>
          <th style={{ width: 100 }}>VALOR</th>
          <th style={{ width: 130 }}>CATEGORIA</th>
          <th style={{ width: 120 }}>TIPO</th>
          <th style={{ width: 196 }}>TAGS</th>
          <th style={{ width: 210 }}>PATRIMÔNIOS</th>
          <th style={{ width: 10 }} />
        </tr>
      </thead>
      <tbody>
        {negativeEntries.map((despesa, index) => (
          <LinhaDespesa
            key={`despesa_${index}`}
            index={index}
            despesa={despesa}
            handleDelete={handleDeleteDespesa}
            handleEdit={handleEditDespesa}
            itensGlobal={itensGlobal}
            tagsOpcoes={tagsOpcoes}
            patrimonios={patrimonios}
            onUpdatePatrimonios={handleUpdatePatrimonios}
            incorrectDate={incorrectDate.negativeEntries[index]}
            setIncorrectDate={(value) => {
              const newIncorrectDates = { ...incorrectDate, negativeEntries: { ...incorrectDate.negativeEntries, [index]: value } };
              setIncorrectDate(newIncorrectDates);
            }}
          />
        ))}
      </tbody>
    </Table>
  </>

  let saveButton = (
    <Button
      color="primary"
      onClick={handleSend}
      style={{ display: "block" }}
      disabled={
        (isCreditoSelected && !regimeSelecionado) ||
        (regimeSelecionado === "fluxoCaixa" && (!fluxoCaixaDate.value || isNaN(new Date(fluxoCaixaDate.value)))) ||
        hasIncorrectDate() // Adiciona a verificação de datas incorretas aqui
      }
    >
      Salvar
    </Button>
  );

  return (
    <Container style={{ display: 'flex', flexDirection: 'column' }}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1049 }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <h2>Upload de Extratos</h2>
        <Badge style={{ color: "white", marginBottom: "20px" }} color="danger" pill>
          Beta
        </Badge>
      </div>
      <br />
      <FileMenu
        handleUpload={handleUpload}
        tagsOpcoes={tagsOpcoes}
        tags={globalTags}
        handleChangeTags={handleChangeGlobalTags}
        handleCreditoVerify={handleCreditoVerify}
      />

      {(positiveEntries.length > 0 || negativeEntries.length > 0)
        && !loading
        && (<Button
          color="danger"
          onClick={handleClean}
          style={{ marginLeft: 'auto' }}
        >LIMPAR</Button>)
      }

      {loading
        ? <LoadingSpinner />
        : <>
          {positiveEntries.length > 0 &&
            receitaTable
          }

          {negativeEntries.length > 0 &&
            despesaTable
          }

          {(positiveEntries.length > 0 || negativeEntries.length > 0) && (
            <>
              {isCreditoSelected && (
                <>
                  <h5><b>Como prefere importar essa fatura?</b></h5>
                  <h6><b>Selecione o tipo de regime:</b></h6>
                  <div style={{ display: "flex", gap: "20px", marginTop: "10px", marginBottom: "20px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="radio"
                          name="regime"
                          value="competencia"
                          onChange={handleRegimeChange}
                        />
                        Regime de Competência
                      </label>
                      <p style={{ fontSize: "12px", marginTop: "-12px" }}>(Data da realização da compra)</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label>
                        <input
                          style={{ marginRight: "5px" }}
                          type="radio"
                          name="regime"
                          value="fluxoCaixa"
                          onChange={handleRegimeChange}
                        />
                        Regime de Caixa
                      </label>
                      <p style={{ fontSize: "12px", marginTop: "-12px" }}>(Data de vencimento da fatura)</p>
                      {
                        regimeSelecionado === "fluxoCaixa" && (
                          <div style={{ marginTop: "-10px" }}>
                            <input
                              type="date"
                              value={fluxoCaixaDate.value}
                              onChange={(e) =>
                                setFluxoCaixaDate({
                                  value: e.target.value,
                                })
                              }
                              style={{ marginBottom: "15px" }}
                            />
                          </div>
                        )
                      }
                    </div>
                  </div>
                </>
              )}
              <div style={{ paddingBottom: "20px" }}>{saveButton}</div>
            </>
          )}
        </>
      }

      <SuccessModal isOpen={isConfirmationOpen} onClose={handleCloseSuccessModal} />
    </Container>
  );
}
export default withRouter(UploadDeExtratos);
