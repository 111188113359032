import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { DateCarousel, TagsFilterSelector } from "../../../components";
import moment from "moment";
import { getAxiosAuth, wasRequestCancelled, getClienteId, logApp, logClient } from "../../../utils";
import GraficoTotalReceitasTotalDeducoes from "./GraficoTotalReceitasTotalDeducoes";
import GraficoReceitasLiquidas from "./GraficoReceitasLiquidas";
import GraficoDeducoes from "./GraficoDeducoes";
import GraficoReceitasPorTipo from "./GraficoReceitasPorTipo";
import { Badge } from "reactstrap";

import { withRouter } from "react-router-dom";

const OPCAO_EXIBIR_GRAFICO = {
  totais: 0,
  receitasLiquidas: 1,
  deducoes: 2,
};

function Receitas(props) {
  const clienteId = getClienteId();
  const m = moment();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [isLoading, setIsLoading] = useState(true);
  const [totalReceitas, setaTotalReceitas] = useState(0.0);
  const [totalReceitaPrincipal, setaTotalReceitaPrincipal] = useState(0.0);
  const [totalReceitaExtra, setaTotalReceitaExtra] = useState(0.0);
  const [totalReceitaPassiva, setaTotalReceitaPassiva] = useState(0.0);
  const [totalDeducoes, setaTotalDeducoes] = useState(0.0);
  const [totalLiquido, setaTotalLiquido] = useState(0.0);
  const [receitasLiquidas, setaReceitasLiquidas] = useState([]);
  const [deducoes, setaDeducoes] = useState([]);
  const [tags, setaTags] = useState([]);
  const [graficoExibido, setaGraficoExibido] = useState(
    OPCAO_EXIBIR_GRAFICO.totais
  );

  const onChangeTags = (newValues) => {
    setaTags(newValues);
  };

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  const getReceitas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas`;
    const dados = {
      filtroMes: mes,
      filtroAno: ano,
      filtroTags: tags.map((tag) => tag.value),
    };
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    setIsLoading(false);

    if (response.data) {
      if ("totalReceitas" in response.data) {
        setaTotalReceitas(response.data.totalReceitas);
      }

      if ("totalDeducoes" in response.data) {
        setaTotalDeducoes(response.data.totalDeducoes);
      }

      if ("totalLiquido" in response.data) {
        setaTotalLiquido(response.data.totalLiquido);
      }

      if ("items" in response.data) {
        const totaisPorTipo = response.data.items.reduce(
          (acc, item) => {
            if (item.tipo === "Principal") {
              acc.principal += item.valor;
            } else if (item.tipo === "Extra") {
              acc.extra += item.valor;
            } else if (item.tipo === "Passiva") {
              acc.passiva += item.valor;
            }

            return acc;
          },
          {
            principal: 0.0,
            extra: 0.0,
            passiva: 0.0,
          }
        );

        setaTotalReceitaPrincipal(totaisPorTipo.principal);
        setaTotalReceitaExtra(totaisPorTipo.extra);
        setaTotalReceitaPassiva(totaisPorTipo.passiva);

        setaReceitasLiquidas(
          response.data.items.map((receita) => ({
            descricao: receita.descricao,
            valor:
              receita.valor -
              receita.deducoes.reduce((acc, cur) => acc + cur.valor, 0.0),
          }))
        );

        setaDeducoes(
          response.data.items
            .reduce((acc, receita) => {
              for (let deducao of receita.deducoes) {
                acc.push({
                  descricao: deducao.descricao,
                  valor: deducao.valor,
                });
              }

              return acc;
            }, [])
            .sort((a, b) => a.valor < b.valor)
        );
      }
    }
  };

  useEffect(() => {
    getReceitas(); // primeiro acesso, e quando alterar data ou tags
  }, [mes, ano, tags]);

  const onClickReceitasLiq = () => {
    setaGraficoExibido(OPCAO_EXIBIR_GRAFICO.receitasLiquidas);
  };

  const onClickDeducoes = () => {
    setaGraficoExibido(OPCAO_EXIBIR_GRAFICO.deducoes);
  };

  const onClickBtnVoltar = () => {
    setaGraficoExibido(OPCAO_EXIBIR_GRAFICO.totais);
  };

  return (
    <div className="animated fadeIn">
      <Container>
        <Row>
          <Col md={4} className="text-center">
            <TagsFilterSelector tagShow={true} onChange={onChangeTags} tags={tags} />
            <div>
            {tags.map((tag)=>{
              return <Badge style={{fontSize:"14px"}} color="secondary" pill key={tag.id}>{tag.label}</Badge>
            })}
            </div>
          </Col>
          <Col md={4} className="text-center">
            <DateCarousel
              ano={ano}
              mes={mes}
              onChange={onChangeDate}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={8} className="text-center mt-5 mb-3">
            <h4>Receitas por Tipo</h4>
          </Col>
        </Row>
        <GraficoReceitasPorTipo
          isLoading={isLoading}
          totalReceitaPrincipal={totalReceitaPrincipal}
          totalReceitaPassiva={totalReceitaPassiva}
          totalReceitaExtra={totalReceitaExtra}
        />
        <Row>
          <Col lg={6} xl={8} className="text-center mt-5 mb-3">
            <h4>Receitas totais</h4>
          </Col>
        </Row>
        {graficoExibido === OPCAO_EXIBIR_GRAFICO.totais && (
          <GraficoTotalReceitasTotalDeducoes
            isLoading={isLoading}
            totalBruto={totalReceitas}
            totalLiquido={totalLiquido}
            totalDeducoes={totalDeducoes}
            onClickReceitasLiq={onClickReceitasLiq}
            onClickDeducoes={onClickDeducoes}
          />
        )}
        {graficoExibido === OPCAO_EXIBIR_GRAFICO.receitasLiquidas && (
          <GraficoReceitasLiquidas
            isLoading={isLoading}
            receitas={receitasLiquidas}
            onClickBtnVoltar={onClickBtnVoltar}
          />
        )}
        {graficoExibido === OPCAO_EXIBIR_GRAFICO.deducoes && (
          <GraficoDeducoes
            isLoading={isLoading}
            deducoes={deducoes}
            onClickBtnVoltar={onClickBtnVoltar}
          />
        )}
      </Container>
    </div>
  );
}

export default withRouter(Receitas);
