import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ButtonGroup,
  Alert,
} from "reactstrap";
import {
  FormErrMsg,
  DateCarousel,
  Monetary,
  Percent,
  ModalConfirmarAlteracoesNaoSalvas,
} from "../../../components";
import {
  getAxiosAuth,
  getTokenClientId,
  isPositiveBRL,
  fromBRL,
  fromPercent,
  toDecimal,
  pad,
  toBRLValueOnly,
  fromDecimal,
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  maxWidthText,
  TIPOS_PATRIMONIOS,
  TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA,
} from "../../../options";
import moment from "moment";
import { withRouter } from "react-router-dom";

const ICONES = [
  "fa fa-home",
  "fas fa-hotel",
  "fas fa-car-side",
  "fas fa-file-signature",
  "fas fa-chart-line",
];

function Patrimonios(props) {
  const m = moment();
  const clienteId = getTokenClientId();
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [tmpMes, setaTmpMes] = useState();
  const [tmpAno, setaTmpAno] = useState();
  const [patrimonios, setaPatrimonios] = useState([]);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [salvarPatrimonios, setaSalvarPatrimonios] = useState();
  const [atualizaPatrimonios, setaAtualizaPatrimonios] = useState();
  const [modalAvisoAberto, setaModalAvisoAberto] = useState(false);
  const [alteracoesPendentes, setaAlteracoesPendentes] = useState();
  const [refreshDateCaroulsel, setaRefreshDateCaroulsel] = useState();

  const limpaTodos = () => {
    setaPatrimonios(
      TIPOS_PATRIMONIOS.map((tipo) => ({
        ...NOVO_PATRIMONIO,
        tipo,
      }))
    );
  };

  const getPatrimonios = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/patrimonios`;
    const params = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params });
    } catch (err) {
      if (err.response) {
        response = err.response;
        console.error(err, response);
        logFront(err, "getPatrimonios()");
      } else {
        console.error(err);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }

    const dados = response.data;

    if (dados.patrimonios && Array.isArray(dados.patrimonios)) {
      let novosPatrimonios = TIPOS_PATRIMONIOS.map((tipo) => ({
        ...NOVO_PATRIMONIO,
        tipo,
      }));

      dados.patrimonios.forEach((patrimonio) => {
        const novoPatrimonio = {
          ...NOVO_PATRIMONIO,
          id: patrimonio.id,
          tipo: patrimonio.tipo,
          descricao: patrimonio.descricao,
          valor: toBRLValueOnly(patrimonio.valor),
          porcentagemAcionaria: toDecimal(patrimonio.porcentagemAcionaria),
        };

        if (novoPatrimonio.tipo === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA) {
          novoPatrimonio.valor = toBRLValueOnly(patrimonio.valorTotalEmpresa);
        }

        novosPatrimonios.push(novoPatrimonio);
      });

      novosPatrimonios = novosPatrimonios.reduce((acc, patrimonio) => {
        if (
          patrimonio.id ||
          !novosPatrimonios.find((p) => p.tipo === patrimonio.tipo && p.id)
        ) {
          acc.push(patrimonio);
        }

        return acc;
      }, []);

      setaPatrimonios(novosPatrimonios);
    } else {
      limpaTodos();
    }
  };

  // primeiro acesso: obtem dados dos patrimonios
  useEffect(() => {
    getPatrimonios();
  }, [atualizaPatrimonios]);

  const onChangeDate = (novoMes, novoAno) => {
    if (alteracoesPendentes) {
      setaTmpMes(novoMes);
      setaTmpAno(novoAno);
      setaModalAvisoAberto(true);
      setaRefreshDateCaroulsel(new Date());
    } else {
      setaMes(novoMes);
      setaAno(novoAno);
    }
  };

  const onConfirmModal = () => {
    setaModalAvisoAberto(false);
    setaAlteracoesPendentes(false);
    setaMes(tmpMes);
    setaAno(tmpAno);
  };

  const onCancelModal = () => {
    setaModalAvisoAberto(false);
  };

  // quando mudar a data
  useEffect(() => {
    setaAtualizaPatrimonios(new Date());
  }, [mes, ano]);

  // salvar
  useEffect(() => {
    const putPatrimonios = async () => {
      let anoPad = pad(ano, 4);
      let mesPad = pad(mes, 2);
      let diaPad = pad(1, 2);
      const dataPatrimonio = `${anoPad}-${mesPad}-${diaPad}`;

      const dadosPatrimonios = patrimonios
        .reduce((acc, cur, idx) => {
          if (!patrimonioLimpo(idx)) {
            acc.push(cur);
          }

          return acc;
        }, [])
        .map((patrimonio) => {
          let porcentagemAcionaria = fromPercent(
            patrimonio.porcentagemAcionaria
          );
          let valor, valorTotalEmpresa;

          if (patrimonio.tipo === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA) {
            valorTotalEmpresa = fromBRL(patrimonio.valor);
            valor = (porcentagemAcionaria * valorTotalEmpresa) / 100;
          } else {
            valorTotalEmpresa = null;
            valor = fromBRL(patrimonio.valor);
          }

          return {
            id: patrimonio.id,
            dataPatrimonio,
            tipo: patrimonio.tipo,
            descricao: patrimonio.descricao,
            valor,
            valorTotalEmpresa,
            porcentagemAcionaria,
          };
        });

      const dados = {
        patrimonios: dadosPatrimonios,
        dataPatrimonio,
      };

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/patrimonios`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err.response);
          logFront(err, "putPatrimonios()");
        } else {
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          console.error(err);
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        toast("Patrimônios salvos com sucesso!");

        setaAtualizaPatrimonios(new Date());
        setaAlteracoesPendentes(false);
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        const responseApp = await logApp(axiosInstance, response);
      await logClient(axiosInstance, null, response, responseApp.data.trace_id, uri, response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(response.message);
        return response.message;
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      putPatrimonios();
    }
  }, [salvarPatrimonios]);

  const NOVO_PATRIMONIO = {
    id: null,
    dataPatrimonio: "",
    tipo: "",
    descricao: "",
    descricaoErro: null,
    valor: "",
    valorErro: null,
    porcentagemAcionaria: "",
    porcentagemAcionariaErro: null,
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    let valido = true;

    if (estaLimpo()) {
      limpaTodos();
    } else {
      valido = patrimonios.reduce((valido, patrimonio, idx) => {
        const descricaoValida = validaDescricao(idx);
        const valorValido = validaValor(idx);
        const porcentagemAcionaria = validaPorcentagemAcionaria(idx);

        if (
          valido &&
          (!descricaoValida || !valorValido || !porcentagemAcionaria)
        ) {
          valido = false;
        }

        return valido;
      }, true);
    }

    if (valido) {
      setaFormularioValido(true);
      setaSalvarPatrimonios(new Date());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  const patrimonioLimpo = (idx) => {
    if (!patrimonios[idx]) return true;

    const patrimonio = patrimonios[idx];

    return (
      validator.isEmpty(patrimonio.descricao, { ignore_whitespace: true }) &&
      validator.isEmpty(patrimonio.valor, { ignore_whitespace: true }) &&
      validator.isEmpty(patrimonio.porcentagemAcionaria, {
        ignore_whitespace: true,
      })
    );
  };

  const estaLimpo = () => {
    return patrimonios.reduce((acc, cur, idx) => {
      if (acc) {
        acc = patrimonioLimpo(idx);
      }

      return acc;
    }, true);
  };

  const onClickBtnAdicionarPatrimonio = (tipo, e) => {
    e.preventDefault();

    const novosPatrimonios = [...patrimonios, { ...NOVO_PATRIMONIO, tipo }];

    setaPatrimonios(novosPatrimonios);
  };

  const onClickBtnRemoverPatrimonio = (idx, e) => {
    e.preventDefault();

    const novosPatrimonios = [...patrimonios];

    const tipo = novosPatrimonios[idx].tipo;

    if (novosPatrimonios.filter((p) => p.tipo === tipo).length === 1) {
      novosPatrimonios[idx] = { ...NOVO_PATRIMONIO, tipo };
    } else {
      novosPatrimonios.splice(idx, 1);
    }

    setaPatrimonios(novosPatrimonios);
  };

  const onChangeCampo = (idx, campo, valor) => {
    if (!patrimonios[idx]) return;

    let novosPatrimonios = [...patrimonios];

    novosPatrimonios[idx][campo] = valor;

    setaPatrimonios(novosPatrimonios);

    setaAlteracoesPendentes(new Date());
  };

  const validaDescricao = (idx) => {
    if (!patrimonios[idx]) return true;

    let novosPatrimonios = [...patrimonios];

    const patrimonio = novosPatrimonios[idx];

    let valido = true;

    if (
      !patrimonioLimpo(idx) &&
      validator.isEmpty(patrimonio.descricao, { ignore_whitespace: true })
    ) {
      patrimonio.descricaoErro = "Descrição obrigatória.";
      valido = false;
    }

    if (valido) {
      patrimonio.descricaoErro = !patrimonioLimpo(idx) ? false : null;
    }

    setaPatrimonios(novosPatrimonios);

    return valido;
  };

  const onChangeDescricao = (idx, e) => {
    const valor = e.target.value.slice(0,maxWidthText)
    onChangeCampo(idx, "descricao", valor);
  };

  const onBlurDescricao = (idx) => {
    validaDescricao(idx);
  };

  const validaValorMonetario = (idx, campo) => {
    if (!patrimonios[idx]) return true;

    let novosPatrimonios = [...patrimonios];

    const patrimonio = novosPatrimonios[idx];

    let valido = true;

    if (
      !patrimonioLimpo(idx) &&
      validator.isEmpty(patrimonio[campo], { ignore_whitespace: true })
    ) {
      patrimonio[campo + "Erro"] = "Campo obrigatório";
      valido = false;
    }

    if (!patrimonioLimpo(idx) && valido && !isPositiveBRL(patrimonio[campo])) {
      patrimonio[campo + "Erro"] = "Valor monetário inválido";
      valido = false;
    }

    if (valido) {
      patrimonio[campo + "Erro"] = !patrimonioLimpo(idx) ? false : null;
    }

    setaPatrimonios(novosPatrimonios);

    return valido;
  };

  const validaValor = (idx) => {
    return validaValorMonetario(idx, "valor");
  };

  const onChangeValor = (idx, e) => {
    onChangeCampo(idx, "valor", e.target.value);
  };

  const onBlurValor = (idx) => {
    validaValor(idx);
  };

  const validaPorcentagemAcionaria = (idx) => {
    if (!patrimonios[idx]) return true;

    if (patrimonios[idx].tipo !== TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA) {
      return true;
    }

    let novosPatrimonios = [...patrimonios];

    const patrimonio = novosPatrimonios[idx];

    let valido = true;

    if (
      !patrimonioLimpo(idx) &&
      validator.isEmpty(patrimonio.porcentagemAcionaria, {
        ignore_whitespace: true,
      })
    ) {
      patrimonio.porcentagemAcionariaErro = "Campo obrigatório";
      valido = false;
    }

    if (
      !patrimonioLimpo(idx) &&
      valido &&
      !validator.isDecimal(patrimonio.porcentagemAcionaria, { locale: "pt-BR" })
    ) {
      patrimonio.porcentagemAcionariaErro = "Porcentagem inválida.";
      valido = false;
    }

    if (valido) {
      patrimonio.porcentagemAcionariaErro = !patrimonioLimpo(idx)
        ? false
        : null;
    }

    setaPatrimonios(novosPatrimonios);

    return valido;
  };

  const onChangePorcentagemAcionaria = (idx, e) => {
    onChangeCampo(idx, "porcentagemAcionaria", e.target.value);
  };

  const onBlurPorcentagemAcionaria = (idx) => {
    validaPorcentagemAcionaria(idx);
  };

  const totalTodosPatrimonios = patrimonios.reduce((acc, cur) => {
    if (cur.tipo === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA) {
      return acc;
    }
    let valor = fromBRL(cur.valor);
    if (isNaN(valor)) {
      return acc;
    } else {
      return acc + valor;
    }
  }, 0.0);

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <ModalConfirmarAlteracoesNaoSalvas
        aberto={modalAvisoAberto}
        onContinue={onConfirmModal}
        onCancel={onCancelModal}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <div className="text-center">
          <DateCarousel
            // travaMesAtual
            ano={ano}
            mes={mes}
            onChange={onChangeDate}
            refresh={refreshDateCaroulsel}
            lockMomentMonth={true}
          />
        </div>
        {TIPOS_PATRIMONIOS.map((tipo, tipoIdx) => {
          let patrimoniosTipo = patrimonios
            .map((p, i) => {
              p.posIdx = i;
              return p;
            })
            .filter((p) => p.tipo === tipo);
          let totalPatrimonios = toBRLValueOnly(
            patrimoniosTipo.reduce((acc, cur) => {
              let valor = fromBRL(cur.valor);
              if (!isNaN(valor)) {
                return acc + valor;
              } else {
                return acc;
              }
            }, 0.0)
          );
          let totalParticipacaoAcionaria = toBRLValueOnly(
            patrimoniosTipo.reduce((acc, cur) => {
              if (cur.tipo !== TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA) {
                return acc;
              }

              let valorEmpresa = fromBRL(cur.valor);
              if (isNaN(valorEmpresa)) {
                return acc;
              }

              let porcentagemAcionaria = fromDecimal(cur.porcentagemAcionaria);
              if (isNaN(porcentagemAcionaria)) {
                return acc;
              }

              return acc + (valorEmpresa * porcentagemAcionaria) / 100.0;
            }, 0.0)
          );
          let totalGeral = toBRLValueOnly(
            fromBRL(totalParticipacaoAcionaria) + totalTodosPatrimonios
          );
          return (
            <Card key={`patrimonio` + tipo}>
              <CardHeader>
                {ICONES[tipoIdx] && <i className={ICONES[tipoIdx]} />}
                <strong>{tipo}</strong>
              </CardHeader>
              <CardBody>
                {patrimoniosTipo.map((patrimonio) => {
                  return (
                    <Row form key={`patrimonio_${tipo}_${patrimonio.posIdx}`}>
                      <Col md={12} lg={4}>
                        <FormGroup>
                          <Label
                            className={
                              !patrimonioLimpo(patrimonio.posIdx)
                                ? "required"
                                : ""
                            }
                          >
                            Descrição
                          </Label>
                          <Input
                            type="text"
                            value={patrimonio.descricao}
                            onChange={onChangeDescricao.bind(
                              this,
                              patrimonio.posIdx
                            )}
                            onBlur={onBlurDescricao.bind(
                              this,
                              patrimonio.posIdx
                            )}
                            valid={patrimonio.descricaoErro === false}
                            invalid={!!patrimonio.descricaoErro}
                          />
                          <FormErrMsg>{patrimonio.descricaoErro}</FormErrMsg>
                        </FormGroup>
                      </Col>
                      <Col md={"auto"}>
                        <FormGroup>
                          <Label
                            className={
                              !patrimonioLimpo(patrimonio.posIdx)
                                ? "required"
                                : ""
                            }
                          >
                            {tipo === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA
                              ? "Valor total da empresa"
                              : "Valor"}
                          </Label>
                          <Monetary
                            value={patrimonio.valor}
                            onChange={onChangeValor.bind(
                              this,
                              patrimonio.posIdx
                            )}
                            onBlur={onBlurValor.bind(this, patrimonio.posIdx)}
                            valid={patrimonio.valorErro === false}
                            invalid={!!patrimonio.valorErro}
                          />
                          <FormErrMsg>{patrimonio.valorErro}</FormErrMsg>
                        </FormGroup>
                      </Col>
                      {tipo === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA && (
                        <Col md={"auto"}>
                          <FormGroup>
                            <Label
                              className={
                                !patrimonioLimpo(patrimonio.posIdx)
                                  ? "required"
                                  : ""
                              }
                            >
                              Porcentagem acionária
                            </Label>
                            <Percent
                              value={patrimonio.porcentagemAcionaria}
                              onChange={onChangePorcentagemAcionaria.bind(
                                this,
                                patrimonio.posIdx
                              )}
                              onBlur={onBlurPorcentagemAcionaria.bind(
                                this,
                                patrimonio.posIdx
                              )}
                              valid={
                                patrimonio.porcentagemAcionariaErro === false
                              }
                              invalid={!!patrimonio.porcentagemAcionariaErro}
                            />
                            <FormErrMsg>
                              {patrimonio.porcentagemAcionariaErro}
                            </FormErrMsg>
                          </FormGroup>
                        </Col>
                      )}
                      <Col md={"auto"} className="d-flex align-items-center">
                        <ButtonGroup>
                          <Button
                            color="primary"
                            onClick={onClickBtnAdicionarPatrimonio.bind(
                              this,
                              tipo
                            )}
                          >
                            <i className="fas fa-plus" />
                          </Button>
                          <Button
                            color="secondary"
                            onClick={onClickBtnRemoverPatrimonio.bind(
                              this,
                              patrimonio.posIdx
                            )}
                          >
                            <i className="fas fa-minus" />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  );
                })}
                {(patrimoniosTipo.length > 1 ||
                  tipo === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA) && (
                  <Row form>
                    <Col md={12} lg={4} />
                    <Col md={"auto"}>
                      <FormGroup>
                        <Label>Total</Label>
                        <Monetary readOnly value={totalPatrimonios} />
                      </FormGroup>
                    </Col>
                    <Col md={"auto"} />
                    {tipo === TIPO_PATRIMONIO_PARTICIPACAO_ACIONARIA && (
                      <Col md={"auto"}>
                        <FormGroup>
                          <Label>Total participação</Label>
                          <Monetary
                            readOnly
                            value={totalParticipacaoAcionaria}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                )}
                {tipoIdx + 1 === TIPOS_PATRIMONIOS.length &&
                  formularioValido === false && (
                    <Alert color="danger" style={{ display: "inline-block" }}>
                      Não foi possível salvar. {formularioErro}
                    </Alert>
                  )}
              </CardBody>
              {tipoIdx + 1 === TIPOS_PATRIMONIOS.length && (
                <CardFooter>
                  <Row form>
                    <Col md={12} lg={4} />
                    <Col md={"auto"}>
                      <FormGroup>
                        <Label>Total geral</Label>
                        <Monetary readOnly value={totalGeral} />
                      </FormGroup>
                    </Col>
                    <Col md={"auto"} />
                  </Row>
                  <Button
                    type="submit"
                    color="primary"
                    style={{ display: "block" }}
                  >
                    Salvar
                  </Button>
                </CardFooter>
              )}
            </Card>
          );
        })}
      </Form>
    </div>
  );
}

export default withRouter(Patrimonios);
