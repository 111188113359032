import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAxios,
  setAccessToken,
  setRefreshToken,
  setClienteId,
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import { Link } from "react-router-dom";

function Login(props) {
  let emailRegistro;

  if (
    props.history &&
    props.history.location &&
    props.history.location.state &&
    props.history.location.state.email
  ) {
    emailRegistro = props.history.location.state.email;
  }

  const [email, setaEmail] = useState(emailRegistro || "");
  const [senha, setaSenha] = useState("");
  const [erroLoginOuSenha, setarErroLoginOuSenha] = useState();

  // --- eventos para o formulário ---
  const onSubmitForm = async (e) => {
    e.preventDefault();

    // preparando dados para enviar
    const dados = {
      email,
      senha,
    };

    const axiosInstance = getAxios();
    const uri = "/sessao";
    let response;

    try {
      response = await axiosInstance.post(uri, dados);
    } catch (err) {
      if (err.response) {
        response = err.response;

        if (response.status !== 403) {
          console.error(err, response);
          logFront(err, "onSubmitForm()");
        }
      } else {
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        console.error(err);
        // alert(err.message);
        return err.message;
      }
    }

    if (response.status === 200 && response.data.clienteId) {
      setClienteId(response.data.clienteId);
      // salvando tokens
      setAccessToken(response.data.accessToken);
      setRefreshToken(response.data.refreshToken);

      setarErroLoginOuSenha(false);
      props.history.push("/");
    } else {
      setarErroLoginOuSenha(true);
    }
  };

  // --- eventos para campo Email ---
  const onChangeEmail = (e) => {
    setaEmail(e.target.value);
    setarErroLoginOuSenha(false);
  };

  // --- eventos para campo Senha ---
  const onChangeSenha = (e) => {
    setaSenha(e.target.value);
    setarErroLoginOuSenha(false);
  };

  // --- outros eventos ---
  const onClickBtnInscreverse = (e) => {
    e.preventDefault();

    props.history.push("/registrar");
  };

  useEffect(() => {
    if (emailRegistro) {
      toast("Sua conta foi criada! Aguarde a liberação para poder ingressar.");
    }
  }, []);

  return (
    <div className="app flex-row align-items-center login-bg">
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={8000}
          style={{ zIndex: 1999 }}
        />
        <Row className="justify-content-center animated fadeIn">
          <Col md={5}>
            <Card className="p-4">
              <CardBody>
                <Form onSubmit={onSubmitForm}>
                  <h1>Entrar</h1>
                  <p className="text-muted">
                    Entre com a sua conta ou inscreva-se.
                  </p>

                  {erroLoginOuSenha && (
                    <Alert color="danger">
                      Nome de usuário/senha incorretos, ou usuário não
                      habilitado. Verifique sua credencial ou contate o seu
                      consultor.
                    </Alert>
                  )}

                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>@</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      text="text"
                      placeholder="Email"
                      autoComplete="off"
                      value={email}
                      onChange={onChangeEmail}
                      invalid={erroLoginOuSenha}
                    />
                  </InputGroup>

                  <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-lock" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      placeholder="Senha"
                      autoComplete="off"
                      value={senha}
                      onChange={onChangeSenha}
                      invalid={erroLoginOuSenha}
                    />
                  </InputGroup>

                  <Row>
                    <Col xs={7}>
                      <Button color="primary" className="mr-2">
                        Entrar
                      </Button>
                      <Button
                        outline
                        color="primary"
                        onClick={onClickBtnInscreverse}
                      >
                        Inscrever-se
                      </Button>
                    </Col>
                    <Col xs={5} className="text-right">
                      <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
