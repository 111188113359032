import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";
import {
  getTokenClientId,
  getAxiosAuth,
  wasRequestCancelled,
  logApp, 
  logClient,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { GraficoIndiceLinha } from "../../../components";
import { DateSpanSelector } from "../../../components";
import { ResumoIndice } from "../../../components";
import { TextosIndice } from "../../../components";

function HistoricoIndices(props) {
  const clienteId = getTokenClientId();

  const dataInicialPadrao = moment().subtract(2, "month");
  const dataFinalPadrao = moment();

  const [mesInicial, setaMesInicial] = useState(
    Number(dataInicialPadrao.format("M"))
  );
  const [anoInicial, setaAnoInicial] = useState(
    Number(dataInicialPadrao.format("YYYY"))
  );
  const [mesFinal, setaMesFinal] = useState(
    Number(dataFinalPadrao.format("M"))
  );
  const [anoFinal, setaAnoFinal] = useState(
    Number(dataFinalPadrao.format("YYYY"))
  );
  const [indiceEndividamento, setaIndiceEndividamento] = useState(0.0);
  const [indicePadraoDeVida, setaIndicePadraoDeVida] = useState(0.0);
  const [
    indiceCoberturaDespesasMensais,
    setaIndiceCoberturaDespesasMensais,
  ] = useState(0);
  const [indiceImobilizacao, setaIndiceImobilizacao] = useState(0.0);
  const [indiceCasaPropria, setaIndiceCasaPropria] = useState(0.0);
  const [indiceVeicular, setaIndiceVeicular] = useState(0.0);
  const [indiceAtivosTrabalhando, setaIndiceAtivosTrabalhando] = useState(0.0);
  const [indiceLiquidez, setaIndiceLiquidez] = useState(0.0);
  const [indiceLiberdadeFinanceira, setaIndiceLiberdadeFinanceira] = useState(
    0.0
  );
  const [erroFuturo, setErroFuturo] = useState(undefined);

  //const [receitas, setaReceitas] = useState([]);
  //const [totalReceitas, setaTotalReceitas] = useState(0.0);
  //const [totalDespesas, setaTotalDespesas] = useState(0.0);
  const [calcular, setaCalcular] = useState(true);

  const [dates, setDates] = useState()
  const [indicesArray, setIndicesArray] = useState([]);
  const [monthsLabelHook, setMonthsLabelHook] = useState([]);

  const onChangeDateSpan = (mesInicial, anoInicial, mesFinal, anoFinal) => {
    const dataInicial = moment([anoInicial, mesInicial - 1]);
    const dataFinal = moment([anoFinal, mesFinal - 1]);

    // 13/03/2023 - 10/03/2023 > 0
    if(moment().diff(dataInicial) < 0 || moment().diff(dataFinal) < 0) {
      setErroFuturo('Não é possivel visualizar historico de índices futuros!');
      return;
    }

    setErroFuturo(undefined);
    setaMesInicial(mesInicial);
    setaAnoInicial(anoInicial);
    setaMesFinal(mesFinal);
    setaAnoFinal(anoFinal);
    setaCalcular(true);
  };

  const getPatrimonios = async (numAno, numMes) => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/patrimonios`;
    const dados = { filtroMes: numMes, filtroAno: numAno };
    let response;
    
    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }
    
    if (response.data && "patrimonios" in response.data) {
      return response.data.patrimonios
    }
  };

  const getDividas = async (numAno, numMes) => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/dividas`;
    const data = { filtroAno: numAno, filtroMes: numMes }
    let response;

    try {
      response = await axiosInstance.get(uri, { params: data });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "dividas" in response.data) {
      return response.data.dividas;
    }
  };

  const getFinanciamentos = async (numAno, numMes) => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/financiamentos`;
    const data = { filtroAno: numAno, filtroMes: numMes }
    let response;

    try {
      response = await axiosInstance.get(uri, { params: data });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "financiamentos" in response.data) {
      return response.data.financiamentos;
    }
  };

  const getInvestimentos = async (numAno, numMes) => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/investimentos`;
    const dados = { filtroMes: numAno, filtroAno: numMes };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "investimentos" in response.data) {
      return response.data.investimentos;
    }
  };

  const getDespesasMA = async (numMesAnterior, numAnoAnterior) => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/despesas`;
    const dados = { filtroMes: numMesAnterior, filtroAno: numAnoAnterior };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "totalDespesas" in response.data) {
      return response.data.totalDespesas
    }
  };

  const getReceitasMA = async (numMesAnterior, numAnoAnterior) => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas`;
    const dados = { filtroMes: numMesAnterior, filtroAno: numAnoAnterior };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "items" in response.data) {
      return response.data.items;
    }
  };

  const getTotalReceitasMA = async(numMesAnterior, numAnoAnterior) => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas`;
    const dados = { filtroMes: numMesAnterior, filtroAno: numAnoAnterior };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "totalLiquido" in response.data) {
      return response.data.totalLiquido;
    }
  }

  const handleIndicesArray = () => {
    const indices = [
      indiceEndividamento,
      indicePadraoDeVida,
      indiceCoberturaDespesasMensais,
      indiceImobilizacao,
      indiceCasaPropria,
      indiceVeicular,
      indiceAtivosTrabalhando,
      indiceLiquidez,
      indiceLiberdadeFinanceira,
    ]

    setIndicesArray(indices);
  }

  const getDados = async (monthsLabel) => {
    const dates = monthsLabel.reduce((acc, cur) => {
      if(!acc[cur]) {
        acc[cur] = {};
      }
    
      return acc;
    }, {})
    
    Object.keys(dates).map(async(date) => {
      const numMes = moment().month(date).format('M');
      const numAno = moment(date).year();
      
      const numMesAnterior = Number(moment().month(date).subtract(1, "month").format("M"));
      const numAnoAnterior = Number(moment(date).subtract(1, "year").year());

      const patrimoniosLocal = await getPatrimonios(numAno, numMes);
      const dividasLocal = await getDividas(numAno, numMes);
      const financiamentosLocal = await getFinanciamentos(numAno, numMes);
      const investimentosLocal = await getInvestimentos(numAno, numMes);
      
      const receitasMALocal = await getReceitasMA(numMesAnterior, numAnoAnterior);
      const totalDespesasLocal = await getDespesasMA(numMesAnterior, numAnoAnterior);
      const totalReceitasMALocal = await getTotalReceitasMA(numMesAnterior, numAnoAnterior);
      
      const totalPatrimonios = patrimoniosLocal
        .map((p) => p.valor)
        .reduce((acc, cur) => acc + cur, 0);
      const totalInvestimentos = investimentosLocal
        .map((i) => i.valor)
        .reduce((acc, cur) => acc + cur, 0);
      const totalInvestimentosLiquidezImediata = investimentosLocal
        .filter((investimento) => investimento.liquidez === "Imediata")
        .map((i) => i.valor)
        .reduce((acc, cur) => acc + cur, 0);
      const totalInvestimentosLiquidez3Meses = investimentosLocal
        .filter((investimento) => investimento.liquidez === "Até 3 meses")
        .map((i) => i.valor)
        .reduce((acc, cur) => acc + cur, 0);
      const totalAtivos =
        patrimoniosLocal
          .filter((p) => p.receitaMensal >= p.custoMensal)
          .map((p) => p.valor)
          .reduce((acc, cur) => acc + cur, 0) + totalInvestimentos;
      const totalDividas = dividasLocal
        .map((d) => d.valorTotalHoje)
        .reduce((acc, cur) => acc + cur, 0);
      const totalFinanciamentos = financiamentosLocal
        .map((f) => {
          if (f.financiamentoCanceladoEm) {
            return 0.0;
          }
          let valorRestante = 0.0;
          const parcelasRestantes = f.totalParcelas - f.parcela;
          if (parcelasRestantes > 0) {
            valorRestante = parcelasRestantes * f.valorParcela;
          }
          return valorRestante;
        })
        .reduce((acc, cur) => acc + cur, 0);

      let valor;
      
      // Índice de Endividamento
      valor = (totalDividas + totalFinanciamentos) / (totalPatrimonios + totalInvestimentos);
      setaIndiceEndividamento(isNaN(valor) ? 0 : valor);
      dates[date]['indiceEndividamento'] = isNaN(valor) ? 0 : valor
      
      // Índice Padrão de Vida
      valor = totalDespesasLocal / totalReceitasMALocal;
      setaIndicePadraoDeVida(isNaN(valor) ? 0 : valor);
      dates[date]['indicePadraoDeVida'] = isNaN(valor) ? 0 : valor
        
      // Índice de Coberturas Despesas Mensais
      valor = Math.round( (totalInvestimentosLiquidezImediata + totalInvestimentosLiquidez3Meses) / totalDespesasLocal);
      setaIndiceCoberturaDespesasMensais(isNaN(valor) ? 0 : valor);
      dates[date]['indiceCoberturaDespesasMensais'] = isNaN(valor) ? 0 : valor;

      // Índice de Imobilização
      valor =
        patrimoniosLocal
          .filter(
            (p) =>
              p.tipo === "Minha casa própria" ||
              p.tipo === "Outros Imóveis" ||
              p.tipo === "Veículos/Autos" ||
              p.tipo === "Participação acionária"
          )
          .reduce((acc, cur) => acc + cur.valor, 0) /
        (totalPatrimonios + totalInvestimentos);
      setaIndiceImobilizacao(isNaN(valor) ? 0 : valor);
      dates[date]['indiceImobilizacao'] = isNaN(valor) ? 0 : valor

      // Índice Casa Própria
      valor =
        patrimoniosLocal
          .filter((p) => p.tipo === "Minha casa própria")
          .reduce((acc, cur) => acc + cur.valor, 0) /
        (totalPatrimonios + totalInvestimentos);
      setaIndiceCasaPropria(isNaN(valor) ? 0 : valor);
      dates[date]['indiceCasaPropria'] = isNaN(valor) ? 0 : valor

      // Índice Veicular
      valor =
        patrimoniosLocal
          .filter((p) => p.tipo === "Veículos/Autos")
          .reduce((acc, cur) => acc + cur.valor, 0) /
        (totalPatrimonios + totalInvestimentos);
      setaIndiceVeicular(isNaN(valor) ? 0 : valor);
      dates[date]['indiceVeicular'] = isNaN(valor) ? 0 : valor

      // Índice Ativos Trabalhando
      valor = (totalAtivos + totalInvestimentos) / (totalPatrimonios + totalInvestimentos);
      setaIndiceAtivosTrabalhando(isNaN(valor) ? 0 : valor);
      dates[date]['indiceAtivosTrabalhando'] = isNaN(valor) ? 0 : valor

      // Índice de Liquidez
      valor =
      totalPatrimonios + totalInvestimentos === 0
          ? 0
          : (totalInvestimentosLiquidezImediata + totalInvestimentosLiquidez3Meses) /
            (totalPatrimonios + totalInvestimentos);
      setaIndiceLiquidez(isNaN(valor) ? 0 : valor);
      dates[date]['indiceLiquidez'] = isNaN(valor) ? 0 : valor
    
      // Índice Liberdade Financeira
      valor =
        receitasMALocal
          .filter((r) => r.tipo === "Passiva")
          .reduce((acc, cur) => acc + cur.valor, 0) / totalDespesasLocal;
      setaIndiceLiberdadeFinanceira(isNaN(valor) ? 0 : valor);
      dates[date]['indiceLiberdadeFinanceira'] = isNaN(valor) ? 0 : valor
    })
    
    setDates(dates);
    handleIndicesArray();
  };

  useEffect(() => {
    if(!calcular){
      return;
    }

    const months = moment.monthsShort();

    const dataInicial = moment([anoInicial, mesInicial - 1]);
    const dataFinal = moment([anoFinal, mesFinal - 1]);

    const monthsLabel = []
    let indice = moment(dataInicial).month();
    let dif = dataFinal.diff(dataInicial, "months")

    for(let i = 0; i <= dif; i++) {
      let ano = dataInicial
      if(indice > months.length - 1) {
        indice = 0;
      }
      if(i !== 0 && (i % 12) === 0) {
        ano.add(1, "year");
      } 
      monthsLabel.push(months[indice] + '-' + ano.format('YYYY'))
      indice++;
    }
    
    setMonthsLabelHook(monthsLabel);
    getDados(monthsLabel);
    setaCalcular(false);
  }, [calcular]);

  return (
    <Container style={{ maxWidth: 798 }}>
      <Row className="mb-3">
        <Col lg={12}>
          <h4 className="text-center">Histórico de Índices do Checkup Financeiro</h4>
          <div className="text-center">
            {!!erroFuturo && <div className="alert alert-danger">{erroFuturo}</div>}
            <DateSpanSelector 
              mesInicial={mesInicial}
              anoInicial={anoInicial}
              mesFinal={mesFinal}
              anoFinal={anoFinal}
              onChange={onChangeDateSpan}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={12}>
          {
            indicesArray && indicesArray.map((indice, i) => {
              const labels = [
                "Endividamento",
                "Padrão de vida",
                "Reserva Estratégica de Segurança",
                "Imobilização",
                "Casa Própria",
                "Veicular",
                "Ativos Trabalhando",
                "Liquidez",
                "Liberdade Financeira"
              ]

              const subtitulos = [
                "Dívidas / Bens Totais",
                "Despesas Mensais / Receitas Totais",
                "Ativos CP / Reserva Estratégica de Segurança",
                "Imóveis totais / Bens totais",
                "Casa Própria / Bens totais",
                "Automóveis / Bens totais",
                "Bens Ativos / Bens totais",
                "Ativos CP / Total de Patrimônio",
                "Renda Passiva / Despesas Mensais",
              ]

              let indiceEndividamento = [];
              let indicePadraoDeVida = [];
              let indiceCoberturaDespesasMensais = [];
              let indiceImobilizacao = [];
              let indiceCasaPropria = [];
              let indiceVeicular = [];
              let indiceAtivosTrabalhando = [];
              let indiceLiquidez = [];
              let indiceLiberdadeFinanceira = [];

              let indices = []
              
              Object.keys(dates).map((mes) => (
                Object.keys(dates[mes]).forEach((indice, i) => {
                  switch(i) {
                    case 0:
                      indiceEndividamento.push(dates[mes][indice]);
                      break;
                    case 1:
                      indiceCoberturaDespesasMensais.push(dates[mes][indice]);
                      break;
                    case 2:
                      indicePadraoDeVida.push(dates[mes][indice]);
                      break;
                    case 3:
                      indiceImobilizacao.push(dates[mes][indice]);
                      break;
                    case 4:
                      indiceCasaPropria.push(dates[mes][indice]);
                      break;
                    case 5:
                      indiceVeicular.push(dates[mes][indice]);
                      break;
                    case 6:
                      indiceAtivosTrabalhando.push(dates[mes][indice]);
                      break;
                    case 7:
                      indiceLiquidez.push(dates[mes][indice]);
                      break;
                    case 8:
                      indiceLiberdadeFinanceira.push(dates[mes][indice]);
                      break;
                    default:
                      break;
                  }
                })
                
              ))
                
              indices.push(
                indiceEndividamento,
                indicePadraoDeVida,
                indiceCoberturaDespesasMensais,
                indiceImobilizacao,
                indiceCasaPropria,
                indiceVeicular,
                indiceAtivosTrabalhando,
                indiceLiquidez,
                indiceLiberdadeFinanceira,
              )
              
              return (
              <>
                <ResumoIndice 
                  titulo={labels[i]}
                  subtitulo={subtitulos[i]}
                  texto={TextosIndice[i]}
                />
                <GraficoIndiceLinha
                  key={labels[i]} 
                  titulo={labels[i]}
                  months={monthsLabelHook}
                  indice={indices[i]}
                />
              </>
              )
            })
          }
        </Col>
      </Row>
      <br />
    </Container>
  );
}

export default withRouter(HistoricoIndices);
