import React, { useEffect, useState, useRef, Fragment } from "react";
import { Container, Table } from "reactstrap";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  getAxiosAuth,
  getTokenClientId,
  toBRLColor,
  toBRL, 
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import BtnModalFormDivida from "./BtnModalFormDivida";
import BtnModalRemoverDivida from "./BtnModalRemoverDivida";

function Dividas(props) {
  const clienteId = getTokenClientId();
  const [dividas, setaDividas] = useState([]);
  const [totalDevido, setaTotalDevido] = useState(0);
  const [inseriu, setaInseriu] = useState(null);
  const [atualizou, setaAtualizou] = useState(null);
  const [removeu, setaRemoveu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isFirstRun = useRef(true);

  const onAdd = () => {
    setaInseriu(new Date());
  };

  const onEdit = () => {
    setaAtualizou(new Date());
  };

  const onDelete = () => {
    setaRemoveu(new Date());
  };

  const getDividas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/dividas`;
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
        console.error(err, response);
        logFront(err, "getDividas()");
      } else {
        console.error(err);
        const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }

    setIsLoading(false);

    const dados = response.data;
    // console.log(dados);

    if (dados && dados.dividas) {
      setaDividas(dados.dividas);
    }

    if (dados && dados.totalDevido) {
      setaTotalDevido(dados.totalDevido);
    }
  };

  useEffect(() => {
    if (inseriu) {
      toast("Dívida adicionada!");
      getDividas();
    }
  }, [inseriu]);

  useEffect(() => {
    if (atualizou) {
      toast("Dívida atualizada!");
      getDividas();
    }
  }, [atualizou]);

  useEffect(() => {
    if (removeu) {
      toast("Dívida removida!");
      getDividas();
    }
  }, [removeu]);

  // primeiro acesso
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
    getDividas();
  }, []);

  const btnAdd = <BtnModalFormDivida onAdd={onAdd} />;

  const btns = divida => (
    <Fragment>
      <BtnModalFormDivida onEdit={onEdit} {...divida} />
      <BtnModalRemoverDivida
        id={divida.id}
        nome={divida.nome}
        onDelete={onDelete}
      />
    </Fragment>
  );

  return (
    <div className="animated fadeIn">
      <Container>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1049 }}
        />

        <Table size="sm" hover>
          <thead className="thead-dark">
            <tr>
              <th className="text-left">Total Devido</th>
              <th className="d-none d-sm-table-cell" />
              <th className="d-none d-sm-table-cell" />
              <th className="text-right">{toBRL(totalDevido)}</th>
              <th className="d-none d-sm-table-cell" />
            </tr>
          </thead>
          <tbody>
            <tr className="table-danger">
              <th className="text-left">Nome</th>
              <th className="text-left d-none d-sm-table-cell">Credor</th>
              <th className="text-left d-none d-sm-table-cell">Observações</th>
              <th className="text-right">Valor total hoje</th>
              <th className="text-left d-none d-sm-table-cell">{btnAdd}</th>
            </tr>

            <tr>
              <td colSpan={2} className="text-right d-table-cell d-sm-none">
                {btnAdd}
              </td>
            </tr>

            {isLoading && (
              <tr>
                <td colSpan={5}>
                  <div className="text-muted text-center mt-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                  </div>
                </td>
              </tr>
            )}

            {!isLoading && dividas && dividas.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <p className="text-muted text-center mt-3">
                    Nenhuma dívida inserida.
                  </p>
                </td>
              </tr>
            )}

            {!isLoading &&
              dividas &&
              dividas.length > 0 &&
              dividas.map(divida => (
                <tr key={`divida_${divida.id}`}>
                  <td className="text-left" style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span>{divida.nome}</span>

                    <span className="d-inline d-sm-none">
                      {" "}
                      / {divida.credor}
                    </span>

                    {divida.obs && (
                      <span className="d-block d-sm-none mt-2">
                        {divida.obs}
                      </span>
                    )}

                    <span className="d-block d-sm-none mb-3 mt-2">
                      {btns(divida)}
                    </span>
                  </td>
                  <td className="text-left d-none d-sm-table-cell" style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {divida.credor}
                  </td>
                  <td className="text-left d-none d-sm-table-cell" style={{ maxWidth: '120px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {divida.obs}
                  </td>
                  <td className="text-right">
                    {toBRLColor(divida.valorTotalHoje)}
                  </td>
                  <td className="text-right d-none d-sm-table-cell">
                    {btns(divida)}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default withRouter(Dividas);
