import React, { useState, Fragment, useEffect } from "react";
import {
  Container,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Alert,
} from "reactstrap";
import { JUROS_PADRAO, maxDescriptionWidthText, MESES } from "../../../options";
import { Monetary, Percent, FormErrMsg, FileUpload } from "../../../components";
import { withRouter } from "react-router-dom";
import {
  isPositiveBRL,
  getTokenClientId,
  fromBRL,
  fromPercent,
  getAxiosAuth,
  toBRLValueOnly,
  toDecimal,
  pad,
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import validator from "validator";
import moment from "moment";
import BtnModalCalculadoraSonhos from "./BtnModalCalculadoraSonhos";


function Especifique(props) {
  const clienteId = getTokenClientId();
  const [projetoId, setaProjetoId] = useState();
  const [nome, setaNome] = useState("");
  const [nomeErro, setaNomeErro] = useState(null);
  const [dia, setaDia] = useState("1");
  const [mes, setaMes] = useState(moment().format("M"));
  const [ano, setaAno] = useState(moment().format("YYYY"));
  const [dataErro, setaDataErro] = useState(null);
  const [descricao, setaDescricao] = useState("");
  const [custoProjeto, setaCustoProjeto] = useState("");
  const [custoProjetoErro, setaCustoProjetoErro] = useState(null);
  const [valorPoupadoMes, setaValorPoupadoMes] = useState("");
  const [valorPoupadoMesErro, setaValorPoupadoMesErro] = useState(null);
  const [juros, setaJuros] = useState(JUROS_PADRAO);
  const [jurosErro, setaJurosErro] = useState(null);
  const [prazoMeses, setaPrazoMeses] = useState("");
  const [prazoMesesErro, setaPrazoMesesErro] = useState(null);
  const [prazoAnos, setaPrazoAnos] = useState("");
  const [prazoAnosErro, setaPrazoAnosErro] = useState(null);
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [salvarProjeto, setaSalvarProjeto] = useState();
  const [, setIsUploading] = useState(false);
  const [imagem, setaImagem] = useState();
  const [imagemUrl, setaImagemUrl] = useState(null);
 
  const onClickCancelBtn = (e) => {
    e.preventDefault();
    const { history } = props;
    history.push("/planeje/projetos-de-vida");
  };

  const onChangeNome = (e) => {
    setaNome(e.target.value);
  };

  const onChangeDescricao = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxDescriptionWidthText) {
      setaDescricao(inputValue);
    }else {
      setaDescricao(inputValue.slice(0, maxDescriptionWidthText));
    }
  };

  const onChangeCustoProjeto = (e) => {
    setaCustoProjeto(e.target.value);
  };

  const onChangeValorPoupadoMes = (e) => {
    setaValorPoupadoMes(e.target.value);
  };

  const onChangeJuros = (e) => {
    setaJuros(e.target.value);
  };

  const onChangePrazoAnos = (e) => {
    setaPrazoAnos(e.target.value);
  };

  const onChangePrazoMeses = (e) => {
    setaPrazoMeses(e.target.value);
  };

  const validaNome = () => {
    if (validator.isEmpty(nome, { ignore_whitespace: true })) {
      setaNomeErro("Nome do projeto é obrigatório.");
      return false;
    }

    setaNomeErro(false);
    return true;
  };

  const validaCustoProjeto = () => {
    if (validator.isEmpty(custoProjeto, { ignore_whitespace: true })) {
      setaCustoProjetoErro("Campo obrigatório");
      return false;
    }

    if (!isPositiveBRL(custoProjeto)) {
      setaCustoProjetoErro("Valor monetário inválido");
      return false;
    }

    setaCustoProjetoErro(false);
    return true;
  };

  const validaValorPoupadoMes = () => {
    if (validator.isEmpty(valorPoupadoMes, { ignore_whitespace: true })) {
      setaValorPoupadoMesErro("Campo obrigatório");
      return false;
    }

    if (!isPositiveBRL(valorPoupadoMes)) {
      setaValorPoupadoMesErro("Valor monetário inválido");
      return false;
    }

    setaValorPoupadoMesErro(false);
    return true;
  };

  const validaJuros = () => {
    if (validator.isEmpty(juros, { ignore_whitespace: true })) {
      setaJurosErro("Campo obrigatório");
      return false;
    }

    if (!validator.isDecimal(juros, { locale: "pt-BR" })) {
      setaJurosErro("Porcentagem inválida.");
      return false;
    }

    setaJurosErro(false);
    return true;
  };

  const validaPrazoAnos = () => {
    if (validator.isEmpty(prazoAnos, { ignore_whitespace: true })) {
      setaPrazoAnos("0");
    } else if (!validator.isInt(prazoAnos) || prazoAnos < 0) {
      setaPrazoAnosErro("Ano: Valor inválido");
      return false;
    }

    setaPrazoAnosErro(false);
    return true;
  };

  const validaPrazoMeses = () => {
    if (validator.isEmpty(prazoMeses, { ignore_whitespace: true })) {
      setaPrazoMeses("0");
    } else if (!validator.isInt(prazoMeses) || prazoMeses < 0) {
      setaPrazoMesesErro("Mês: Valor inválido");
      return false;
    } else if (prazoMeses > 11) {
      setaPrazoMesesErro("Mês: maior que 11, use o campo anos.");
      return false;
    }

    setaPrazoMesesErro(false);
    return true;
  };

  const onBlurNome = () => {
    validaNome();
  };

  const onBlurCustoProjeto = () => {
    validaCustoProjeto();
  };

  const onBlurValorPoupadoMes = () => {
    validaValorPoupadoMes();
  };

  const onBlurJuros = () => {
    validaJuros();
  };

  const onBlurPrazoAnos = () => {
    validaPrazoAnos();
  };

  const onBlurPrazoMeses = () => {
    validaPrazoMeses();
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    const nomeValido = validaNome();
    const dataValida = validaData();
    const custoProjetoValido = validaCustoProjeto();
    const valorPoupadoMesValido = validaValorPoupadoMes();
    const jurosValido = validaJuros();
    const prazoAnosValido = validaPrazoAnos();
    const prazoMesesValido = validaPrazoMeses();
    const prazoValido = prazoAnos > 0 || prazoMeses > 0;
    if (!prazoValido) {
      setaPrazoAnosErro("Especifique um prazo.");
    }

    if (
      nomeValido &&
      dataValida &&
      custoProjetoValido &&
      valorPoupadoMesValido &&
      jurosValido &&
      prazoAnosValido &&
      prazoMesesValido &&
      prazoValido
    ) {
      setaFormularioValido(true);
      setaSalvarProjeto(new Date());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  // primeiro acesso, se recebeu um ID pela URL
  useEffect(() => {
    let id;

    if (props.match && props.match.params && props.match.params.id) {
      id = props.match.params.id;
    }

    if (id) {
      setaProjetoId(id);
    }
  }, [props.match.params]);

  // primeiro acesso, popula formulário com informações do ID
  useEffect(() => {
    const getProjeto = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/projetos/${projetoId}`;

      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
          logFront(err, "getProjeto()"); 
        } else {
          console.error(err);
            const responseApp = await logApp(axiosInstance, err);
            await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
      
            props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        const dados = response.data;
        setaNome(dados.nome || "");
        setaImagem(dados.imagemProjeto);

        const m = moment(dados.inicioProjeto);
        setaDia(m.format("D"));
        setaMes(m.format("M"));
        setaAno(m.format("YYYY"));

        setaDescricao(dados.descricao || "");
        setaPrazoAnos(String(dados.prazoAnos));
        setaPrazoMeses(String(dados.prazoMeses));

        setaCustoProjeto(toBRLValueOnly(dados.custoProjeto));
        setaValorPoupadoMes(toBRLValueOnly(dados.valorPoupadoMes));
        setaJuros(toDecimal(dados.percentual));
      } else if (response.status === 404) {
        props.history.push("/404");
        return;
      } else {
        const responseApp = await logApp(axiosInstance, response);
        await logClient(axiosInstance, null, response, responseApp.data.trace_id, uri, response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(response.message);
        return response.message;
      }
    };

    if (projetoId) {
      getProjeto();
    }
  }, [projetoId]);

    // --- evento para upload ---
    const onUploadStart = (value) => {
      setIsUploading(true);
      setaImagemUrl(value);
    };

    const onUploadEnd = data => {
      setaImagem(data.uploadObject);
    };

  // salvar
  useEffect(() => {
    let anoPad = pad(ano, 4);
    let mesPad = pad(mes, 2);
    let diaPad = pad(dia, 2);
    const inicioProjeto = `${anoPad}-${mesPad}-${diaPad}`;

    let dados = {
      clienteId,
      nome,
      inicioProjeto,
      descricao,
      prazoAnos: parseInt(prazoAnos),
      prazoMeses: parseInt(prazoMeses),
      custoProjeto: fromBRL(custoProjeto),
      valorPoupadoMes: fromBRL(valorPoupadoMes),
      percentual: fromPercent(juros),
      imageId: imagem ? imagem.id : null
    };

    const postProjeto = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/projetos`;
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
          logFront(err, "postProjeto()"); 
        } else {
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          console.error(err);
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        props.history.push("/planeje/projetos-de-vida", { criouSucesso: true });
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      }
    };

    const putProjeto = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/projetos/${projetoId}`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
          logFront(err, "putProjeto()"); 
        } else {
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          console.error(err);
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        props.history.push("/planeje/projetos-de-vida", {
          editouSucesso: true,
        });
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      }
    };

    if (formularioValido) {
      if (projetoId) {
        putProjeto();
      } else {
        postProjeto();
      }
    }
  }, [salvarProjeto]);

  const onUsarValores = (
    novoCustoProjeto,
    novoJuros,
    novoPrazoAnos,
    novoPrazoMeses,
    novoValorPoupadoMes
  ) => {
    setaCustoProjeto(novoCustoProjeto);
    setaJuros(novoJuros);
    setaPrazoAnos(novoPrazoAnos);
    setaPrazoMeses(novoPrazoMeses);
    setaValorPoupadoMes(novoValorPoupadoMes);
  };

  // --- eventos para os campos de data ---
  const validaData = () => {
    if (validator.isEmpty(dia, { ignore_whitespace: true })) {
      setaDataErro("Dia obrigatório.");
      return false;
    }

    if (validator.isEmpty(ano, { ignore_whitespace: true })) {
      setaDataErro("Ano é obrigatório.");
      return false;
    }

    if (!validator.isInt(dia, { min: 1, max: 31 })) {
      setaDataErro("Dia inválido.");
      return false;
    }

    if (mes < 1 || mes > 12) {
      setaDataErro("Mês inválido.");
      return false;
    }

    if (!validator.isInt(ano)) {
      setaDataErro("Ano inválido.");
      return false;
    }

    let anoPad = pad(ano, 4);
    let mesPad = pad(mes, 2);
    let diaPad = pad(dia, 2);

    const data = `${anoPad}-${mesPad}-${diaPad}`;
    const m = moment(data, "YYYY-MM-DD", true);
    if (!m.isValid()) {
      setaDataErro("Data inválida.");
      return false;
    }

    setaDataErro(false);
    return true;
  };

  useEffect(() => {
    if (dataErro !== null) {
      validaData();
    }
  }, [dia, mes, ano]);

  // --- eventos para o campo Dia ---
  const onChangeDia = (e) => {
    setaDia(e.target.value);
  };

  const onBlurDia = () => {
    validaData();
  };

  // --- eventos para o campo Mes ---
  const onChangeMes = (e) => {
    setaMes(e.target.value);
  };

  // --- eventos para o campo Ano ---
  const onChangeAno = (e) => {
    setaAno(e.target.value);
  };

  const onBlurAno = (e) => {
    validaData();
  };
  
  const camposPrazo = (
    <FormGroup>
      <Label className="required">Em quanto tempo?</Label>
      <InputGroup>
        <Input
          type="text"
          className="text-right"
          style={{ maxWidth: 80 }}
          value={prazoAnos}
          onChange={onChangePrazoAnos}
          onBlur={onBlurPrazoAnos}
          valid={prazoAnosErro === false}
          invalid={!!prazoAnosErro}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>anos</InputGroupText>
        </InputGroupAddon>
        <Input
          type="text"
          className="text-right"
          style={{ maxWidth: 80 }}
          value={prazoMeses}
          onChange={onChangePrazoMeses}
          onBlur={onBlurPrazoMeses}
          valid={prazoMesesErro === false}
          invalid={!!prazoMesesErro}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>meses</InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <FormErrMsg>{prazoAnosErro}</FormErrMsg>
      <FormErrMsg>{prazoMesesErro}</FormErrMsg>
    </FormGroup>
  );

  return (
    <div className="animated fadeIn">
      <Container>
        <Form onSubmit={onSubmitForm}>
          <Card>
            <CardHeader>
              <i className="fa fa-umbrella-beach" />
              <strong>Especifique</strong>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Label className="required">Nome</Label>
                <Input
                  type="text"
                  placeholder="Nome do projeto"
                  value={nome}
                  onChange={onChangeNome}
                  onBlur={onBlurNome}
                  valid={nomeErro === false}
                  invalid={!!nomeErro}
                />
                <FormErrMsg>{nomeErro}</FormErrMsg>
              </FormGroup>
              <h4>Caso deseje, você pode mudar a imagem
                base, você pode fazer upload de uma nova.
              </h4>
              <br></br>
              <FileUpload
                  onUploadStart={onUploadStart}
                  onUploadEnd={onUploadEnd}
                  predicado="uma foto"
                  accept="image/*"          
                />
                <br></br>

                <h6>Foque em imagens com as proporções de 720x840</h6>
                <br></br>
                <br></br>
                {imagemUrl && (
                  <img
                    src={URL.createObjectURL(imagemUrl)}
                    alt="Imagem"
                    width="280"
                    height="240"
                  />
                )}
                <br></br>
                <br></br>
                <br></br>

              <FormGroup>
                <Label>Início em</Label>
                <Row form>
                  <Col xs={"auto"}>
                    <FormGroup>
                      <Label className="required">Dia</Label>
                      <Input
                        type="text"
                        value={dia}
                        onChange={onChangeDia}
                        onBlur={onBlurDia}
                        valid={dataErro === false}
                        invalid={!!dataErro}
                        style={{ maxWidth: 150 }}
                      />
                      <FormErrMsg>{dataErro}</FormErrMsg>
                    </FormGroup>
                  </Col>
                  <Col xs={2}>
                    <FormGroup>
                      <Label className="required">Mês</Label>
                      <Input
                        type="select"
                        value={mes}
                        onChange={onChangeMes}
                        valid={dataErro === false}
                        invalid={!!dataErro}
                      >
                        {MESES.map((mes, i) => (
                          <option value={i + 1} key={`mes_opt_${i}`}>
                            {mes}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs={"auto"}>
                    <Label className="required">Ano</Label>
                    <Input
                      type="text"
                      value={ano}
                      onChange={onChangeAno}
                      onBlur={onBlurAno}
                      valid={dataErro === false}
                      invalid={!!dataErro}
                      style={{ maxWidth: 100 }}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup row className="my-0">
                <Col md="9">
                  <FormGroup>
                    <Label>Descrição</Label>
                    <Input
                      type="textarea"
                      placeholder="Descreva em poucas palavras."
                      value={descricao}
                      onChange={onChangeDescricao}
                      onKeyPress={(e) => {
                        const editedText = e.target.textContent + e.key;
                        if (editedText.length > maxDescriptionWidthText) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
              </FormGroup>
              <Fragment>
                <FormGroup>
                  <Label className="required">Quanto custa?</Label>
                  <FormGroup row>
                    <Col md={"auto"}>
                      <Monetary
                        value={custoProjeto}
                        onChange={onChangeCustoProjeto}
                        onBlur={onBlurCustoProjeto}
                        valid={custoProjetoErro === false}
                        invalid={!!custoProjetoErro}
                      />
                      <FormErrMsg>{custoProjetoErro}</FormErrMsg>
                    </Col>
                    <Col md={"auto"}>
                      <BtnModalCalculadoraSonhos
                        custoProjeto={custoProjeto}
                        prazoAnos={prazoAnos}
                        prazoMeses={prazoMeses}
                        juros={juros}
                        valorPoupadoMes={valorPoupadoMes}
                        onUsarValores={onUsarValores}
                      />
                    </Col>
                  </FormGroup>
                </FormGroup>
                <FormGroup row className="my-0">
                  <Col md={"auto"}>
                    <FormGroup>
                      <Label className="required">
                        Quanto vou guardar/mês?
                      </Label>
                      <Monetary
                        value={valorPoupadoMes}
                        onChange={onChangeValorPoupadoMes}
                        onBlur={onBlurValorPoupadoMes}
                        valid={valorPoupadoMesErro === false}
                        invalid={!!valorPoupadoMesErro}
                      />
                      <FormErrMsg>{valorPoupadoMesErro}</FormErrMsg>
                    </FormGroup>
                  </Col>
                  <Col md={"auto"}>
                    <FormGroup>
                      <Label className="required">Com juros de</Label>
                      <Percent
                        value={juros}
                        onChange={onChangeJuros}
                        onBlur={onBlurJuros}
                        valid={jurosErro === false}
                        invalid={!!jurosErro}
                      />
                      <FormText className="help-block">% ao mês</FormText>
                      <FormErrMsg>{jurosErro}</FormErrMsg>
                    </FormGroup>
                  </Col>
                  <Col md={"auto"}>{camposPrazo}</Col>
                </FormGroup>
              </Fragment>

              {formularioValido === false && (
                <Alert color="danger" style={{ display: "inline-block" }}>
                  Não foi possível salvar. {formularioErro}
                </Alert>
              )}
            </CardBody>
            <CardFooter>
              <Button color="primary">Salvar</Button>
              <Button color="link" onClick={onClickCancelBtn}>
                Cancelar
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
    </div>
  );
}

export default withRouter(Especifique);
