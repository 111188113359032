import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";

function Page500() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const traceId = queryParams.get('trace_id');

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <div className="clearfix">
              <h1 className="float-left display-3 mr-4">500</h1>
              <h4 className="pt-3">Erro na Aplicação</h4>
              <p className="text-muted float-left">
                {traceId ? (
                  <>    
                    Trace ID: {traceId}
                  </> 
                ) : (
                  "A página ou recurso temporariamente indisponível."
                )}
                <br />
                <br />
                <a href="/">Página inicial</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Page500;
