import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Jumbotron, Button, Container, Input, Table, Alert } from "reactstrap";
import { getAxiosAuth, getTokenClientId, toBRL, logFront } from "../../../utils";
import {
  GraficoReduzirDespesas,
  GraficoReduzirDespesasDetalhado,
} from "../../../components";
import moment from "moment";
import "moment/locale/pt-br";
import BtnModalRemoverMeta from "./BtnModalRemoverMeta";
import { CATEGORIAS_DESPESAS } from "../../../options";

function ReduzirDespesas(props) {
  const [carregandoDados, setaCarregandoDados] = useState(true);
  const [erroObterDados, setaErroObterDados] = useState(false);
  const [carregouDados, setaCarregouDados] = useState(false);
  const [despesasTotais, setaDespesasTotais] = useState();
  const [valorMetaTotal, setaValorMetaTotal] = useState();
  const [valoresMetaPorCategoria, setaValoresMetaPorCategoria] = useState();
  const [exibirDetalhado, setaExibirDetalhado] = useState();
  const [opcoesMeses, setaOpcoesMeses] = useState();
  const [opcoesAnos, setaOpcoesAnos] = useState();
  const [valorMes, setaValorMes] = useState();
  const [valorAno, setaValorAno] = useState();
  const [
    despesasTotaisPorCategoria,
    setaDespesasTotaisPorCategoria,
  ] = useState();
  const [
    despesasTotaisPorCategoriaData,
    setaDespesasTotaisPorCategoriaData,
  ] = useState();
  const [mediasDespesas, setaMediasDespesas] = useState([]);
  const [totalDespesasMedia, setaTotalDespesasMedia] = useState(0.0);
  const [meses, setaMeses] = useState([]);
  const [mesesNomes, setaMesesNomes] = useState([]);

  let salvouSucesso = false;

  if (props.history && props.history.location && props.history.location.state) {
    salvouSucesso = props.history.location.state.salvouSucesso;
  }

  useEffect(() => {
    if (salvouSucesso) {
      toast("Meta salva com sucesso!");
    }
  }, []);

  const clickIniciarInputHandler = (event) => {
    event.stopPropagation();
    const { history } = props;
    history.push("/equilibre/reduzir-despesas/especifique");
  };

  const getMetaAtual = async (signal) => {
    
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/reducao`;

    let dataInicioProjeto,
      response = await axiosInstance.get(uri, {signal: signal});
    if (response && response.status === 200) {
      setaCarregouDados(true);
      setaValorMetaTotal(response.data.valorMetaTotal);
      const exibirDetalhado = "categorias" in response.data;
      setaExibirDetalhado(exibirDetalhado);
      if (exibirDetalhado) {
        setaValoresMetaPorCategoria(
          response.data.categorias.map((categoria) => ({
            categoria: categoria.categoria,
            valor: categoria.valorMetaCategoria,
          }))
        );
      }
      dataInicioProjeto = response.data.inicio;
    }

    return dataInicioProjeto;
  };

  const processaDespesas = (despesas) => {
    let meses = Array.from(
      despesas.reduce((acc, despesa) => {
        acc.add(
          JSON.stringify({
            ano: despesa.ano,
            mes: despesa.mes,
          })
        );
        return acc;
      }, new Set())
    ).map((text) => JSON.parse(text));
    setaMeses(meses);

    let mesesNomes = meses.map((data) =>
      moment()
        .date(1)
        .month(data.mes - 1)
        .year(data.ano)
        .format("MMMM")
    );
    setaMesesNomes(mesesNomes);

    let despesasTotais = despesas.reduce((acc, despesa) => {
      let registro = acc.find(
        (registro) =>
          registro.ano === despesa.ano && registro.mes === despesa.mes
      );
      if (!registro) {
        acc.push({ ano: despesa.ano, mes: despesa.mes, valor: despesa.valor });
      } else {
        registro.valor += despesa.valor;
      }

      return acc;
    }, []);

    setaDespesasTotais(despesasTotais);

    const opcoesMeses = despesasTotais
      .reduce((acc, despesa) => {
        if (acc.indexOf(despesa.mes) < 0) {
          acc.push(despesa.mes);
        }
        return acc;
      }, [])
      .map((mes) => ({
        value: mes,
        label: moment()
          .date(1)
          .month(mes - 1)
          .format("MMMM"),
      }))
      .map((mes) => (
        <option value={mes.value} key={`mes_${mes.value}`}>
          {mes.label}
        </option>
      ));
    setaOpcoesMeses(opcoesMeses);

    const opcoesAnos = despesasTotais
      .reduce((acc, despesa) => {
        if (acc.indexOf(despesa.ano) < 0) {
          acc.push(despesa.ano);
        }
        return acc;
      }, [])
      .map((ano) => (
        <option value={ano} key={`ano_${ano}`}>
          {ano}
        </option>
      ));

    setaOpcoesAnos(opcoesAnos);

    const despesasTotaisPorCategoria = despesas.reduce((acc, despesa) => {
      let registro = acc.find(
        (registro) =>
          registro.ano === despesa.ano &&
          registro.mes === despesa.mes &&
          registro.categoria === despesa.categoria
      );
      if (!registro) {
        acc.push({
          ano: despesa.ano,
          mes: despesa.mes,
          categoria: despesa.categoria,
          valor: despesa.valor,
        });
      } else {
        registro.valor += despesa.valor;
      }
      return acc;
    }, []);

    setaDespesasTotaisPorCategoria(despesasTotaisPorCategoria);

    if (despesasTotais.length >= 1) {
      const valorMes = despesasTotais[despesasTotais.length - 1].mes;
      setaValorMes(valorMes);

      const valorAno = despesasTotais[despesasTotais.length - 1].ano;
      setaValorAno(valorAno);
    }

    if (meses.length > 0) {
      let totalDespesasMedia =
        despesasTotais.reduce((acc, despesa) => despesa.valor + acc, 0.0) /
        meses.length;
      setaTotalDespesasMedia(totalDespesasMedia);

      let mediasDespesas = CATEGORIAS_DESPESAS.map((categoria) => {
        const valorMediaCategoria =
          despesasTotaisPorCategoria
            .filter((despesa) => despesa.categoria === categoria.nome)
            .reduce((acc, despesa) => acc + despesa.valor, 0) / meses.length;

        return {
          categoria: categoria.nome,
          valorMedia: valorMediaCategoria,
        };
      });
      setaMediasDespesas(mediasDespesas);
    }
  };

  const getDespesas = async (dataInicioProjeto) => {
    let filtroDataFinal = moment().endOf("month").format("YYYY-MM-DD");
    let filtroDataInicial = moment
      .max(moment(dataInicioProjeto), moment().date(1).subtract(6, "M"))
      .format("YYYY-MM-DD");

    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });
    const clienteId = getTokenClientId();

    const uri = `/clientes/${clienteId}/relatorios/dre`;
    const dados = {
      filtroDataInicial,
      filtroDataFinal,
    };

    let response = await axiosInstance.get(uri, { params: dados });

    if (
      response &&
      response.status === 200 &&
      "despesasPorCategorias" in response.data
    ) {
      processaDespesas(response.data.despesasPorCategorias);
    }
  };

  const getDados = async (signal) => {
    setaErroObterDados(false);
    setaCarregandoDados(true);

    setaCarregouDados(false);
    setaDespesasTotais(null);
    setaValorMetaTotal(null);
    setaValoresMetaPorCategoria(null);
    setaExibirDetalhado(null);
    setaOpcoesMeses(null);
    setaOpcoesAnos(null);
    setaValorMes(null);
    setaValorAno(null);
    setaDespesasTotaisPorCategoria(null);
    setaDespesasTotaisPorCategoriaData(null);

    let dataInicioProjeto;

    try {
      dataInicioProjeto = await getMetaAtual(signal);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return;
      }
      console.error(error);
      logFront(error, "getDados()");
      setaErroObterDados(error.response ? error.response.status : true);
    } finally {
      setaCarregandoDados(false);
    }

    setaCarregandoDados(true);
    try {
      await getDespesas(dataInicioProjeto);
    } catch (error) {
      console.error(error);
      logFront(error, "setaCarregandoDados()");
      setaErroObterDados(error.response ? error.response.status : true);
    } finally {
      setaCarregandoDados(false);
    }
  };

  useEffect(() => {
    let abortController;
    abortController = new AbortController();
    let signal = abortController.signal;
    getDados(signal);
    return () => abortController.abort();
  }, []);

  const valorAnoInputHandler = (event) => {
    setaValorAno(parseInt(event.target.value));
  };

  const valorMesInputHandler = (event) => {
    setaValorMes(parseInt(event.target.value));
  };

  useEffect(() => {
    if (!despesasTotaisPorCategoria) {
      return;
    }
    
    setaDespesasTotaisPorCategoriaData(
      despesasTotaisPorCategoria.filter(
        (despesaTotal) =>
          despesaTotal.ano === valorAno && despesaTotal.mes === valorMes
      )
    );
  }, [despesasTotaisPorCategoria, valorAno, valorMes]);

  const deleteMetaInputHandler = () => {
    toast("Meta removida com sucesso!");
    getDados();
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 2999 }}
      />

      {carregandoDados && (
        <Container>
          <div className="shimmer line br animate"></div>
          <div className="shimmer chart br animate"></div>
          <div className="shimmer line br animate"></div>
        </Container>
      )}

      {!carregandoDados && !erroObterDados && !carregouDados && (
        <Container>
          <Jumbotron>
            <h1 className="display-4">Reduzir Despesas</h1>
            <p className="lead">
              Equilibre o seu padrão de vida e aumente a sua capacidade de
              poupança!
            </p>
            <hr className="my-2" />
            <p>
              Defina agora um limite para as suas despesas e começe a monitorar.
            </p>
            <p className="lead">
              <Button
                size="lg"
                color="primary"
                onClick={clickIniciarInputHandler}
              >
                Definir meta
              </Button>
            </p>
          </Jumbotron>
        </Container>
      )}

      {!carregandoDados && !erroObterDados && carregouDados && (
        <Container>
          <h4 className="text-center mb-3">Reduzir Despesas</h4>
          <Table size="sm" striped bordered>
            <thead>
              <tr>
                <th style={{ width: "auto" }}>Categoria</th>
                {mesesNomes.map((mesNome) => (
                  <th className="text-right" key={`col_${mesNome}`}>
                    {mesNome}
                  </th>
                ))}
                <th className="text-right table-warning">Média</th>
                <th
                  className="text-right table-primary"
                  style={{ width: "auto" }}
                >
                  Meta
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="align-middle">Total Despesas</th>
                {despesasTotais &&
                  despesasTotais.map((despesa, idx) => (
                    <td
                      className="align-middle text-nowrap text-right"
                      key={`col_total_mes_${idx}`}
                    >
                      {toBRL(despesa.valor)}
                    </td>
                  ))}
                <td className="align-middle text-nowrap text-right table-warning">
                  {toBRL(totalDespesasMedia)}
                </td>
                <td className="align-middle text-nowrap text-right table-primary">
                  {toBRL(valorMetaTotal)}
                </td>
              </tr>
              {CATEGORIAS_DESPESAS.map((categoria, categoriaIdx) => (
                <tr key={`categoria_${categoriaIdx}`}>
                  <th className="align-middle">
                    <i
                      className={categoria.icone}
                      style={{ width: "1rem", marginRight: "0.5rem" }}
                    />
                    {categoria.nome}
                  </th>
                  {meses.map((data, idx) => {
                    const despesa = despesasTotaisPorCategoria.find(
                      (despesa) =>
                        despesa.categoria === categoria.nome &&
                        despesa.ano === data.ano &&
                        despesa.mes === data.mes
                    );

                    return (
                      <td
                        className="align-middle text-nowrap text-right"
                        key={`despesa_${categoria.nome}_${data.ano}_${data.mes}_${idx}`}
                      >
                        {despesa ? toBRL(despesa.valor) : null}
                      </td>
                    );
                  })}
                  <td className="align-middle text-nowrap text-right table-warning">
                    {mediasDespesas &&
                      mediasDespesas.find(
                        (media) => media.categoria === categoria.nome
                      ) &&
                      toBRL(
                        mediasDespesas.find(
                          (media) => media.categoria === categoria.nome
                        ).valorMedia
                      )}
                  </td>
                  <td className="align-middle text-right table-primary">
                    {valoresMetaPorCategoria &&
                      valoresMetaPorCategoria.find(
                        (meta) => meta.categoria === categoria.nome
                      ) &&
                      toBRL(
                        valoresMetaPorCategoria.find(
                          (meta) => meta.categoria === categoria.nome
                        ).valor
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="text-right mb-3">
            <Button color="primary" onClick={clickIniciarInputHandler}>
              Editar meta
            </Button>
            <BtnModalRemoverMeta onDelete={deleteMetaInputHandler} />
          </div>
          <GraficoReduzirDespesas
            valorMetaTotal={valorMetaTotal}
            despesasMensais={despesasTotais}
          />
          {exibirDetalhado && (
            <>
              {opcoesMeses && opcoesMeses.length > 1 && (
                <div className="form-inline justify-content-center mb-3">
                  <Input
                    type="select"
                    placeholder="Mês"
                    value={valorMes}
                    onChange={valorMesInputHandler}
                  >
                    {opcoesMeses}
                  </Input>
                  <Input
                    type="select"
                    placeholder="Ano"
                    value={valorAno}
                    onChange={valorAnoInputHandler}
                  >
                    {opcoesAnos}
                  </Input>
                </div>
              )}
              
              <GraficoReduzirDespesasDetalhado
                datas={despesasTotais}
                despesasTotaisPorCategoria={despesasTotaisPorCategoriaData}
                valoresMetaPorCategoria={valoresMetaPorCategoria}
              />
            </>
          )}
        </Container>
      )}

      {erroObterDados && (
        <>
          <Alert color="danger" style={{ display: "inline-block" }}>
            Não foi possível obter suas receitas.
            {erroObterDados === true
              ? " Problema com a conexão."
              : ` Problema interno no servidor (código ${erroObterDados}).`}
          </Alert>
          <p>
            <Button onClick={() => getDados()} color="primary">
              Tentar novamente
            </Button>
          </p>
        </>
      )}
    </div>
  );
}

export default withRouter(ReduzirDespesas);
