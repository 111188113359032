import React, { Fragment, useState, useEffect } from "react";
import { Button, Popover, PopoverHeader, PopoverBody, Badge } from "reactstrap";
import { withRouter } from "react-router-dom";
import { getAxiosAuth, getTokenClientId, logFront } from "../../utils";
import Select from "react-select";
import PropTypes from "prop-types";

function TagsFilterSelector(props) {
  const clienteId = getTokenClientId();
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [tagsOpcoes, setaTagsOpcoes] = useState([]);

  useEffect(() => {
    const getTagsOpcoes = async () => {
      const axiosInstance = getAxiosAuth(() => {
        // callback: redireciona não conseguir renovar tokens
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/tags`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        }
        console.error(err, response);
        logFront(err, "getTagsOpcoes()");
        return;
      }

      if (response && response.data && "tags" in response.data) {
        const novasTagsOpcoes = response.data.tags.map(tag => ({
          value: tag.id,
          label: tag.nome
        }));

        setaTagsOpcoes(novasTagsOpcoes);
      }
    };
    getTagsOpcoes();
  }, []);

  const onChangeTags = (newValue, actionMeta) => {
    if (newValue === null) {
      newValue = [];
    }
    props.onChange && props.onChange(newValue);
  };

  const togglePopover = e => {
    setPopoverOpen(!isPopoverOpen);
    e.stopPropagation();
  };

  return (
    <Fragment>
      <Button
        color="link"
        className="dropdown-toggle"
        id="popover-tags-data"
        onClick={togglePopover}
      >
        {(!props.tags || props.tags.length === 0) && "Filtrar por tags"}
        {props.tags && props.tags.length > 0 && (
          <strong>Filtrando por tags</strong>
        )}
      </Button>
      <Popover
        placement="bottom"
        isOpen={isPopoverOpen}
        toggle={togglePopover}
        target="popover-tags-data"
      >
        <PopoverHeader className="text-center p2">
          <span className="h5">Tags</span>
        </PopoverHeader>
        <PopoverBody className="p2" style={{ minWidth: 250 }}>
          <Select
            isMulti
            placeholder="Selecione"
            options={tagsOpcoes}
            onChange={onChangeTags}
            value={props.tags}
            noOptionsMessage={() => "Sem tags"}
          />
        </PopoverBody>
      </Popover>
      <div>
        {props.tagShow ? props.tags.map((tag)=>{
          return <Badge style={{fontSize:"14px"}} color="secondary" pill key={tag.id}>{tag.label}</Badge>
        }) : null}
      </div>
    </Fragment>
  );
}

TagsFilterSelector.propTypes = {
  tagShow: PropTypes.bool,
  onChange: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.object)
};

export default withRouter(TagsFilterSelector);
