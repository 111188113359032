import React, { Fragment, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import PropTypes from "prop-types";
import { getAxiosAuth, getTokenClientId, logApp, logClient } from "../../../utils";
import { withRouter } from "react-router-dom";

function BtnModalRemoverProjeto(props) {
  const clienteId = getTokenClientId();
  const [aberto, setaAberto] = useState(false);

  // --- eventos para o modal ---
  const toggle = e => {
    e && e.stopPropagation();
    setaAberto(!aberto);
  };

  // --- eventos para o formulário ---
  const onSubmitForm = async e => {
    e.preventDefault();

    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/projetos/${props.id}`;
    let response;

    try {
      response = await axiosInstance.delete(uri);
    } catch (err) {
      if (err.response) {
        response = err.response;
      } else {
        console.error(err, response);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }
    }

    setaAberto(false);
    props.onDelete && props.onDelete();
  };

  return (
    <Fragment>
      <Button size="sm" color="light" title="Remover projeto" onClick={toggle}>
        <i className="fas fa-times" /> Remover
      </Button>
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>Remover Projeto</ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <p>
              Deseja confirmar a remoção do projeto{" "}
              <strong>{props.nome}</strong>?
            </p>
            <p>
              Todas as contribuições serão removidas podendo afetar dados passados
            </p>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={onSubmitForm}>
            Remover
          </Button>
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalRemoverProjeto.propTypes = {
  id: PropTypes.number.isRequired,
  nome: PropTypes.string.isRequired,
  onDelete: PropTypes.func
};

export default withRouter(BtnModalRemoverProjeto);
