import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getAxiosAuth, logFront } from "../../../utils";
import calcularPorcentagem from "./calcularPercentual";
import { LoadingSpinner } from "../../../components";

const TestePerfilResults = ({ history, clienteId, data }) => {
  const [percentuais, setPercentuais] = useState({});
  const [loading, setLoading] = useState(false);
  const axios = getAxiosAuth(() => {
    history.push("/login");
  });

  useEffect(() => {
    setPercentuais(calcularPorcentagem(data));
  }, [data]);

  function calcularMediaNotas(data, tipo) {
    const notasTipo = data
      .filter((item) => item.tipo === tipo && item.valor !== null)
      .map((item) => item.valor);

    if (notasTipo.length === 0) {
      return 0;
    }

    const somaNotas = notasTipo.reduce((acc, nota) => acc + nota, 0);
    const media = somaNotas / notasTipo.length;
    return media.toFixed(2);
  }

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const chartData = {
    labels: Object.keys(percentuais),
    datasets: [{
      data: Object.values(percentuais),
      backgroundColor: [
        "blue",   //POUPADOR
        "orange",   //GASTADOR
        "yellow", //DESCONTROLADO
        "gray", //DESLIGADO
        "green",   //FINANCISTA
        // Adicione mais cores conforme necessário
      ],
    },],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      tooltips: false,
      pieceLabel: {
        render: (args) => {
          const { label, value, percentage } = args;
          return `${label}: ${value} (${percentage}%)`;
        },
      },
    },
  };

  const segmentLabelOptions = {
    render: ({label, value, percentage}) => 
      `${label}: ${value} (${percentage}%)`,
    fontColor: '#fff',
    precision: 2,
  };

  async function postReset(reset) {
    setLoading(true);
    const uri = `/clientes/${clienteId}/perfil?reset=${reset}`;
    let response;

    try {
      response = await axios.post(uri);
      if (response.status === 200) {
        console.log('Valores enviados com sucesso!');
      } else {
        console.error('Falha ao enviar valores:', response.status);
        logFront(response, "postReset()");
      }
    } catch (error) {
      console.error('Erro durante a requisição:', error);
      logFront(response, "postReset()");
    }
    
    setLoading(false);
  }

  const sortedTypes = Object.keys(percentuais).sort((a, b) => percentuais[b] - percentuais[a]);

  const handleConfirmRefazer = async () => {
    if (loading) return;

    await postReset(true);
    handleCloseModal();
    window.location.reload();
  };

  const typeColorMap = {
    "POUPADOR": "blue",
    "GASTADOR": "orange",
    "DESCONTROLADO": "yellow",
    "DESLIGADO": "gray",
    "FINANCISTA": "green",
    // Add more types and colors as needed
  };

  const ConfirmationModal = () => {
    return (
      <Modal isOpen={isModalOpen} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Confirmação</ModalHeader>
        <ModalBody>
          Tem certeza de que deseja refazer o teste ?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleConfirmRefazer}>
            {loading ? <LoadingSpinner /> : 'Confirmar'}
          </Button>{" "}
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <div>
      <h4>
        <b>Seu Perfil Financeiro é:</b>
      </h4>
      <div className="wrap-chart" style={{ border: "1px solid black", display: "flex", flexWrap: "wrap" }}>
        <div className="wrap-chart-div1">
          {sortedTypes.map((tipo, index) => (
            <div key={index} style={{ padding: "10px", gap: "10px" }} className="d-flex flex-wrap align-items-center">
              <div style={{ minWidth: "160px" }}>
                <p><b>{tipo.toUpperCase()}</b></p>
              </div>
              <div style={{ width: "20px" }}>
                <p><b>{Math.round(calcularMediaNotas(data, tipo))}</b></p>
              </div>
              <div style={{ minWidth: "200px", maxWidth: "400px" }} className="flex-grow-1 ml-3">
                <div className="progress-container">
                  <div
                    style={{
                      height: "100%",
                      width: `${percentuais[tipo]}%`,
                      backgroundColor: typeColorMap[tipo.toUpperCase()],
                    }}
                  />
                </div>
              </div>
              <div>
                <p><b>{`${Math.round(percentuais[tipo])}%`}</b></p>
              </div>
            </div>
          ))}
        </div>
        <div className="chartpie-container">
          <Pie
            data={chartData}
            options={{
              ...chartOptions,
              plugins: {
                ...chartOptions.plugins,
                pieceLabel: segmentLabelOptions,
              },
            }}
          />
        </div>
      </div>
      <div style={{ textAlign: "center", marginBottom: "40px", marginTop: "20px" }}>
        <button
          onClick={handleOpenModal}
          style={{
            color: "white",
            background: "red",
            padding: "5px",
            borderRadius: "10px",
            borderColor: "rgba(0,0,0,.0)"
          }}
        >Refazer teste</button>
      </div>
      {isModalOpen && <ConfirmationModal />}
    </div>
  );
};

export default TestePerfilResults;