import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { getAxiosAuth, toBRL, toPercent } from "../../utils";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Line } from "react-chartjs-2";
import { pad, wasRequestCancelled, getClienteId, logApp, logClient } from "../../utils";
import moment from "moment";
import PropTypes from "prop-types";
import "moment/locale/pt-br";

function GraficoFluxoCaixaMensal(props) {
  const clienteId = getClienteId();
  const [receitas, setReceitas] = useState([]);
  const [despesas, setDespesas] = useState([]);
  const [contribuicoesDiarias, setContribuicoesDiarias] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const anoPad = pad(props.ano.toString(), 4);
  const mesPad = pad(props.mes.toString(), 2);
  const dataPesquisa = `${anoPad}-${mesPad}-01`;
  const m = moment(dataPesquisa);
  const mesTexto = m.month(props.mes - 1).format("MMMM");
  const legendas = [];
  const ultimoDiaMes = Number(m.endOf("month").format("DD"));
  for (let i = 1; i <= ultimoDiaMes; ++i) {
    legendas.push(i);
  }


const getContribuicoesDiarias = async () => {
  const axiosInstance = getAxiosAuth(() => {
    props.history.push("/login");
  });

  const uri = `/clientes/${clienteId}/contribuicoes`;
  const dados = {
    filtroMes: props.mes,
    filtroAno: props.ano,
    filtroTags: getFiltroTags()
  };
  let response;

  
  try {
    response = await axiosInstance.get(uri, { params: dados });
  } catch (err) {
    if (!wasRequestCancelled(err)) {
      console.error(err);
    }

    const responseApp = await logApp(axiosInstance, err);
    await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);

    props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);

    // alert(err.message);
    return err.message;
  } 



  if (response.data && "contribuicoes" in response.data) {
    let contribuicoesDiarias = [];
    for (let dia = 1; dia <= ultimoDiaMes; ++dia) {
      let total_dia = response.data.contribuicoes.reduce((acc, contribuicao) => {
        const diaContribuicao = Number(
          moment(contribuicao.dataContribuicao).format("DD")
        );
        if (
          diaContribuicao === dia ||
          (dia === ultimoDiaMes && diaContribuicao > ultimoDiaMes)
        ) {
          acc += contribuicao.valor
        }
        return acc;
      }, 0.0);
      contribuicoesDiarias.push(total_dia);
    }
    setContribuicoesDiarias(contribuicoesDiarias);
  }
};

  const getFiltroTags = () =>
    props.tags ? props.tags.map(tag => tag.value) : [];

  const getReceitas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas`;
    const dados = {
      filtroMes: props.mes,
      filtroAno: props.ano,
      filtroTags: getFiltroTags()
    };

    try {
      const response = await axiosInstance.get(uri, { params: dados });
      if (response.data && "items" in response.data) {
        let receitas = [];
        for (let dia = 1; dia <= ultimoDiaMes; ++dia) {
          let total_dia = response.data.items.reduce((acc, receita) => {
            const diaReceita = Number(
              moment(receita.dataReceita).format("DD")
            );
            if (
              diaReceita === dia ||
              (dia === ultimoDiaMes && diaReceita > ultimoDiaMes)
            ) {
              let totalDeducoes = receita.deducoes
                ? receita.deducoes.reduce((acc, deducao) => {
                    return acc + Number(deducao.valor);
                  }, 0.0)
                : 0.0;
              acc += receita.valor - totalDeducoes;
            }
            return acc;
          }, 0.0);
          receitas.push(total_dia);
        }
        setReceitas(receitas);
      }
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }

      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);

      // alert(err.message);
      return err.message;
    }
  };

  const getDespesas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/despesas`;
    const dados = {
      filtroMes: props.mes,
      filtroAno: props.ano,
      filtroTags: getFiltroTags()
    };

    try {
      const response = await axiosInstance.get(uri, { params: dados });
 
      if (response.data && "categorias" in response.data) {
        let despesas = [];
        for (let dia = 1; dia <= ultimoDiaMes; ++dia) {
          let total_dia = response.data.categorias.reduce((acc, categoria) => {
            const totalDia = categoria.despesas.reduce((acc, despesa) => {
              const diaDespesa = Number(
                moment(despesa.dataDespesa).format("DD")
              );
              if (
                diaDespesa === dia ||
                (dia === ultimoDiaMes && diaDespesa > ultimoDiaMes)
              ) {
                if (despesa.despesaTemporaria) {
                  const dataDespesa = despesa.dataDespesa;
                  const mesDespesa = moment(dataDespesa).format("M");
                  const anoDespesa = moment(dataDespesa).format("YYYY");
                  const formatDespesa = moment([anoDespesa, mesDespesa - 1]);
                  const formatAtual = moment([
                    props.ano,
                    props.mes - 1
                  ]);
                  const parcelasPagas = formatAtual.diff(
                    formatDespesa,
                    "month",
                    true
                  );
                  if (
                    ((despesa.totalParcelas - despesa.parcela) -
                      parcelasPagas) <= 0
                  ) {
                    acc += 0;
                  } else {
                    acc += despesa.valor;
                  }
                } else {
                  acc += despesa.valor;
                }
              }
              return acc;
            }, 0.0);
            return acc + totalDia;
          }, 0.0);
          despesas.push(total_dia);
        }
        setDespesas(despesas);
      }
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }

      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
      
      // alert(err.message);
      return err.message;
    }
  };

  const getDados = async () => {
    await getReceitas();
    await getDespesas();
    await getContribuicoesDiarias();
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
  }, [props.mes, props.ano, props.tags]);

  useEffect(() => {
    if (isLoading) {
      getDados();
    }
  }, [isLoading]);

  const chart = {
    labels: legendas,
    datasets: [
      {
        label: "Receitas",
        backgroundColor: "transparent",
        borderColor: "#84C027",
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: receitas
      },
      {
        label: "Despesas",
        backgroundColor: "transparent",
        borderColor: "#f86c6b",
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: despesas
      },
      {
        label: "Investimentos",
        backgroundColor: "transparent",
        borderColor: "#3498db",
        pointHoverBackgroundColor: "#fff",
        borderWidth: 3,
        data: contribuicoesDiarias
      }
    ]
  };

  const chartOpts = {
    maintainAspectRatio: false,
    legend: {
      // display: false
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const { datasetIndex } = tooltipItem;
          let label = data.datasets[datasetIndex].label;
          let percentageText = "";

          if (datasetIndex === 2 || datasetIndex === 3 || datasetIndex === 4) {
            let total = 0;

            if (datasetIndex === 2) {
              total += data.datasets[2].data[tooltipItem.index];
            }

            if (datasetIndex === 3) {
              total += data.datasets[3].data[tooltipItem.index];
            }

            if (datasetIndex === 4) {
              total += data.datasets[4].data[tooltipItem.index];
            }

            if (total > 0) {
              percentageText = `(${toPercent(tooltipItem.yLabel / total)})`;
            }
          }

          let textLabel = `${label}: ${toBRL(
            tooltipItem.yLabel
          )} ${percentageText}`;
          return textLabel;
        }
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            maxTicksLimit: 5,
            callback: function(value) {
              return toBRL(value);
            }
          }
        }
      ]
    },
    elements: {
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="text-center">
          Fluxo de Caixa
          {props.mostrarData && (
            <Fragment>
              &nbsp;de {mesTexto}/{props.ano}
            </Fragment>
          )}
        </CardTitle>

        <div className="chart-wrapper" style={{ height: 300, marginTop: 40 }}>
          {isLoading && (
            <div
              className="text-muted d-flex flex-column align-items-center justify-content-center"
              style={{ minHeight: "100%" }}
            >
              <div className="spinner-border" role="status">
                <span className="sr-only">Carregando...</span>
              </div>
            </div>
          )}
          {!isLoading && <Line data={chart} options={chartOpts} height={300} />}
        </div>
      </CardBody>
    </Card>
  );
}

GraficoFluxoCaixaMensal.propTypes = {
  mes: PropTypes.number.isRequired,
  ano: PropTypes.number.isRequired,
  tags: PropTypes.arrayOf(PropTypes.object),
  mostrarData: PropTypes.bool
};

export default withRouter(GraficoFluxoCaixaMensal);
