import React, { useState, useEffect } from "react";
import { Container, Table } from "reactstrap";
import { toBRL } from "../../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  getAxiosAuth,
  wasRequestCancelled,
  getTokenClientId, 
  logApp, 
  logClient,
} from "../../../utils";
import { DateCarousel } from "../../../components";
import { withRouter } from "react-router-dom";
import BtnModalFormContribuicao from "./BtnModalFormContribuicao";
import LinhaContribuicao from "./LinhaContribuicao";

function Contribuicoes(props) {
  const clienteId = getTokenClientId();
  const m = moment();
  const [totalContribuicoes, setaTotalContribuicoes] = useState(0.0);
  const [contribuicoes, setaContribuicoes] = useState([]);
  const [mes, setaMes] = useState(Number(m.format("M")));
  const [ano, setaAno] = useState(Number(m.format("YYYY")));
  const [inseriu, setaInseriu] = useState(null);
  const [atualizou, setaAtualizou] = useState(null);
  const [removeu, setaRemoveu] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onAdd = () => {
    setaInseriu(new Date());
  };

  const onEdit = () => {
    setaAtualizou(new Date());
  };

  const onDelete = () => {
    setaRemoveu(new Date());
  };

  const onChangeDate = (mes, ano) => {
    setaMes(mes);
    setaAno(ano);
  };

  const getContribuicoes = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });
    const uri = `/clientes/${clienteId}/contribuicoes`;
    const dados = { filtroMes: mes, filtroAno: ano , organize: 1 };
    let response;

    setIsLoading(true);

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    setIsLoading(false);

    if (response.data && "totalContribuicoes" in response.data) {
      setaTotalContribuicoes(response.data.totalContribuicoes);
    }

    if (response.data && "contribuicoes" in response.data) {
      setaContribuicoes(response.data.contribuicoes);
    }
  };

  useEffect(() => {
    if (inseriu) {
      toast("Investimento adicionado!");
      getContribuicoes();
    }
  }, [inseriu]);

  useEffect(() => {
    if (atualizou) {
      toast("Investimento atualizado!");
      getContribuicoes();
    }
  }, [atualizou]);

  useEffect(() => {
    if (removeu) {
      toast("Investimento removido!");
      getContribuicoes();
    }
  }, [removeu]);

  // primeiro acesso, e quando altera data
  useEffect(() => {
    getContribuicoes();
  }, [mes, ano]);

  return (
    <div className="animated fadeIn">
      <Container className="">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          style={{ zIndex: 1049 }}
        />
        <div className="text-center">
          <DateCarousel mes={mes} ano={ano} onChange={onChangeDate} />
        </div>

        <Table size="sm" hover>
          <thead className="thead-dark">
            <tr>
              <th />
              <th>Total aplicado</th>
              <th className="d-none d-sm-table-cell" />
              <th className="text-right text-nowrap">
                {toBRL(totalContribuicoes)}
              </th>
              <th className="d-none d-sm-table-cell" />
            </tr>
          </thead>
          <tbody className="">
            <tr className="table-info">
              <th className="text-left" style={{ paddingLeft: 10, width: 60 }}>
                Dia
              </th>
              <th className="text-left">Descrição</th>
              <th className="d-none d-sm-table-cell" style={{ width: 20 }} />
              <th className="text-right text-nowrap" style={{ width: 120 }}>
                Valor
              </th>
              <th
                className="text-right d-none d-sm-table-cell"
                style={{ width: 100 }}
              >
                <BtnModalFormContribuicao mes={mes} ano={ano} onAdd={onAdd} />
              </th>
            </tr>
            <tr>
              <td colSpan={5} className="text-right d-table-cell d-sm-none">
                <BtnModalFormContribuicao mes={mes} ano={ano} onAdd={onAdd} />
              </td>
            </tr>

            {isLoading && (
              <tr>
                <td colSpan={5}>
                  <div className="text-muted text-center mt-3">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                  </div>
                </td>
              </tr>
            )}

            {!isLoading && contribuicoes.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <p className="text-muted text-center mt-3">
                    Sem contribuições no período.
                  </p>
                </td>
              </tr>
            )}

            {!isLoading &&
              contribuicoes.length > 0 &&
              contribuicoes.map(contribuicao => (
                <LinhaContribuicao
                  key={`contribuicao_${contribuicao.id}`}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  {...contribuicao}                 
                />
              ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default withRouter(Contribuicoes);
