import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  GraficoFluxoCaixa,
  IndiceCotacaoDolar,
  IndiceCotacaoSELIC,
  IndiceIPCA,
  GraficoFluxoCaixaMensal,
  GraficoTopDespesasBarras,
} from "../../components";
import moment from "moment";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import { getAxiosAuth, getTokenClientId, logApp, logClient } from "../../utils";

function Dashboard(props) {
  const m = moment();
  const ano = Number(m.format("YYYY"));
  const mes = Number(m.format("MM"));
  const clienteId = getTokenClientId();
  const [topDespesas, setTopDespesas] = useState([]);

  useEffect(() => {
    const getTopDespesas = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/dashboard/top-despesas`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
        } else {
          console.error(err);
          
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance, err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
    
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200 && "despesas" in response.data) {
        setTopDespesas(response.data.despesas);
      }
    };

    getTopDespesas();
  }, []);

  return (
    <div className="animated fadeIn mb-5">
      <Container>
        <Card>
          <CardBody>
            <Row>
              <Col sm={3}>
                <IndiceCotacaoDolar />
              </Col>
              <Col sm={3}>
                <IndiceCotacaoSELIC />
              </Col>
              <Col sm={3}>
                <IndiceIPCA />
              </Col>
              <Col sm={3}>
                <div className="callout callout-danger">
                  <div className="h3 text-muted text-right mb-2 float-right">
                    <i className="far fa-clock" />
                  </div>
                  <div className="h5 mb-0">Expectativa de Vida</div>
                  <a
                    href="https://www.terra.com.br/noticias/infograficos/calcule-sua-expectativa-de-vida/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small className="text-uppercase font-weight-bold">
                      Descubra aqui
                    </small>
                  </a>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>{/*
        <Card>
          <CardBody style={{ display: "flex", justifyContent: "center" }}>
            <ReactPlayer url="https://www.youtube.com/watch?v=_wqVabMfqu0" />
          </CardBody>
        </Card> */}
        <GraficoTopDespesasBarras topDespesas={topDespesas} />
        <GraficoFluxoCaixa />
        <GraficoFluxoCaixaMensal mes={mes} ano={ano} mostrarData />
      </Container>
    </div>
  );
}

export default withRouter(Dashboard);
