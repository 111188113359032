import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Badge,
  Button,
} from "reactstrap";
import {
  toPercentColor,
  getTokenClientId,
  getAxiosAuth,
  wasRequestCancelled,
  logApp, 
  logClient,
} from "../../../utils";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";

const TIPO_EQUILIBRAR = 0;
const TIPO_PLANEJAR = 1;
const TIPO_INVESTIR = 2;

function BlocoIndice(props) {
  const css = ["mb-0"];
  let color = "";

  if (props.tipo === TIPO_PLANEJAR) {
    css.push("card-accent-warning");
    color = "warning";
  } else if (props.tipo === TIPO_INVESTIR) {
    css.push("card-accent-primary");
    color = "primary";
  } else {
    css.push("card-accent-success");
    color = "success";
  }

  return (
    <Card className={css.join(" ")} style={{ height: "100%" }}>
      <CardHeader>
        <h5 className="float-left mb-0 font-weight-bold">
          {props.titulo}
          <small className="text-muted d-block mt-2">{props.subtitulo}</small>
        </h5>
        <div className="card-header-actions">
          <Badge
            color={color}
            pill
            className="float-right p-2"
            style={{ fontSize: "1.2rem" }}
          >
            {!isNaN(props.valor) && toPercentColor(props.valor)}
            {isNaN(props.valor) && props.valor}
          </Badge>
        </div>
      </CardHeader>
      <CardBody className="text-justify card-body-indices">
        {props.children}
      </CardBody>
    </Card>
  );
}

function Indices(props) {
  const clienteId = getTokenClientId();
  const [indiceEndividamento, setaIndiceEndividamento] = useState(0.0);
  const [indicePadraoDeVida, setaIndicePadraoDeVida] = useState(0.0);
  const [
    indiceCoberturaDespesasMensais,
    setaIndiceCoberturaDespesasMensais,
  ] = useState(0);
  const [indiceImobilizacao, setaIndiceImobilizacao] = useState(0.0);
  const [indiceCasaPropria, setaIndiceCasaPropria] = useState(0.0);
  const [indiceVeicular, setaIndiceVeicular] = useState(0.0);
  const [indiceAtivosTrabalhando, setaIndiceAtivosTrabalhando] = useState(0.0);
  const [indiceLiquidez, setaIndiceLiquidez] = useState(0.0);
  const [indiceLiberdadeFinanceira, setaIndiceLiberdadeFinanceira] = useState(
    0.0
  );

  //const [receitas, setaReceitas] = useState([]);
  //const [totalReceitas, setaTotalReceitas] = useState(0.0);
  //const [totalDespesas, setaTotalDespesas] = useState(0.0);
  const [patrimonios, setaPatrimonios] = useState([]);
  const [dividas, setaDividas] = useState([]);
  const [financiamentos, setaFinanciamentos] = useState([]);
  const [investimentos, setaInvestimentos] = useState([]);
  const [calcular, setaCalcular] = useState();

  
  const [totalDespesasMA, setaTotalDespesasMA] = useState(0.0);
  const [receitasMA, setaReceitasMA] = useState([]);
  const [totalReceitasMA, setaTotalReceitasMA] = useState(0.0);

  const totalPatrimonios = patrimonios
    .map((p) => p.valor)
    .reduce((acc, cur) => acc + cur, 0);
  const totalInvestimentos = investimentos
    .map((i) => i.valor)
    .reduce((acc, cur) => acc + cur, 0);
  const totalInvestimentosLiquidezImediata = investimentos
    .filter((investimento) => investimento.liquidez === "Imediata")
    .map((i) => i.valor)
    .reduce((acc, cur) => acc + cur, 0);
  const totalInvestimentosLiquidez3Meses = investimentos
    .filter((investimento) => investimento.liquidez === "Até 3 meses")
    .map((i) => i.valor)
    .reduce((acc, cur) => acc + cur, 0);
  const totalAtivos =
    patrimonios
      .filter((p) => p.receitaMensal >= p.custoMensal)
      .map((p) => p.valor)
      .reduce((acc, cur) => acc + cur, 0) + totalInvestimentos;
  const totalDividas = dividas
    .map((d) => d.valorTotalHoje)
    .reduce((acc, cur) => acc + cur, 0);
  const totalFinanciamentos = financiamentos
    .map((f) => {
      if (f.financiamentoCanceladoEm) {
        return 0.0;
      }
      let valorRestante = 0.0;
      const parcelasRestantes = f.totalParcelas - f.parcela;
      if (parcelasRestantes > 0) {
        valorRestante = parcelasRestantes * f.valorParcela;
      }
      return valorRestante;
    })
    .reduce((acc, cur) => acc + cur, 0);

  const m = moment();
  const mes = Number(m.format("M"));
  const ano = Number(m.format("YYYY"));
  const ma = moment().subtract(1, 'months');
  const mesma = Number(ma.format("M"));
  const anoma = Number(ma.format("YYYY"));

  /*const getReceitas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "totalLiquido" in response.data) {
      setaTotalReceitas(response.data.totalLiquido);
    }

    if (response.data && "items" in response.data) {
      setaReceitas(response.data.items);
    }
  };

  const getDespesas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/despesas`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      props.history.push("/500");
      return;
    }

    if (response.data && "totalDespesas" in response.data) {
      setaTotalDespesas(response.data.totalDespesas);
    }
  };*/

  const getPatrimonios = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/patrimonios`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "patrimonios" in response.data) {
      setaPatrimonios(response.data.patrimonios);
    }
  };

  const getDividas = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/dividas`;
    let response;

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "dividas" in response.data) {
      setaDividas(response.data.dividas);
    }
  };

  const getFinanciamentos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/financiamentos`;
    let response;

    try {
      response = await axiosInstance.get(uri);
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "financiamentos" in response.data) {
      setaFinanciamentos(response.data.financiamentos);
    }
  };

  const getInvestimentos = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/investimentos`;
    const dados = { filtroMes: mes, filtroAno: ano };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "investimentos" in response.data) {
      setaInvestimentos(response.data.investimentos);
    }
  };

  
  //Mês anterior
  const getDespesasMA = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/despesas`;
    const dados = { filtroMes: mesma, filtroAno: anoma };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "totalDespesas" in response.data) {
      setaTotalDespesasMA(response.data.totalDespesas);
    }
  };

  const getReceitasMA = async () => {
    const axiosInstance = getAxiosAuth(() => {
      // callback: redireciona se não conseguir renovar tokens
      props.history.push("/login");
    });

    const uri = `/clientes/${clienteId}/receitas`;
    const dados = { filtroMes: mesma, filtroAno: anoma };
    let response;

    try {
      response = await axiosInstance.get(uri, { params: dados });
    } catch (err) {
      if (!wasRequestCancelled(err)) {
        console.error(err);
      }
      const responseApp = await logApp(axiosInstance, err);
      await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
      // alert(err.message);
      return err.message;
    }

    if (response.data && "totalLiquido" in response.data) {
      setaTotalReceitasMA(response.data.totalLiquido);
    }

    if (response.data && "items" in response.data) {
      setaReceitasMA(response.data.items);
    }
  };
  


  const getDados = async () => {
    //await getReceitas();
    //await getDespesas();
    await getPatrimonios();
    await getDividas();
    await getFinanciamentos();
    await getInvestimentos();

    
    await getDespesasMA();
    await getReceitasMA();

    setaCalcular(new Date());
  };

  useEffect(() => {
    getDados();
  }, []);

  useEffect(() => {
    if (!calcular) {
      return;
    }

    let valor;

    // Índice de Endividamento
    valor = (totalDividas + totalFinanciamentos) / (totalPatrimonios + totalInvestimentos);
    setaIndiceEndividamento(isNaN(valor) ? 0 : valor);

    // Índice Padrão de Vida
    valor = totalDespesasMA / totalReceitasMA;
    setaIndicePadraoDeVida(isNaN(valor) ? 0 : valor);

    // Índice de Coberturas Despesas Mensais
    valor = Math.round( (totalInvestimentosLiquidezImediata + totalInvestimentosLiquidez3Meses) / totalDespesasMA);
    setaIndiceCoberturaDespesasMensais(isNaN(valor) ? 0 : valor);

    // Índice de Imobilização
    valor =
      patrimonios
        .filter(
          (p) =>
            p.tipo === "Minha casa própria" ||
            p.tipo === "Outros Imóveis" ||
            p.tipo === "Veículos/Autos" ||
            p.tipo === "Participação acionária"
        )
        .reduce((acc, cur) => acc + cur.valor, 0) /
      (totalPatrimonios + totalInvestimentos);
    setaIndiceImobilizacao(isNaN(valor) ? 0 : valor);

    // Índice Casa Própria
    valor =
      patrimonios
        .filter((p) => p.tipo === "Minha casa própria")
        .reduce((acc, cur) => acc + cur.valor, 0) /
      (totalPatrimonios + totalInvestimentos);
    setaIndiceCasaPropria(isNaN(valor) ? 0 : valor);

    // Índice Veicular
    valor =
      patrimonios
        .filter((p) => p.tipo === "Veículos/Autos")
        .reduce((acc, cur) => acc + cur.valor, 0) /
      (totalPatrimonios + totalInvestimentos);
    setaIndiceVeicular(isNaN(valor) ? 0 : valor);

    // Índice Ativos Trabalhando
    valor = (totalAtivos + totalInvestimentos) / (totalPatrimonios + totalInvestimentos);
    setaIndiceAtivosTrabalhando(isNaN(valor) ? 0 : valor);

    // Índice de Liquidez
    valor =
    totalPatrimonios + totalInvestimentos === 0
        ? 0
        : (totalInvestimentosLiquidezImediata + totalInvestimentosLiquidez3Meses) /
          (totalPatrimonios + totalInvestimentos);
    setaIndiceLiquidez(isNaN(valor) ? 0 : valor);

    // Índice Liberdade Financeira
    valor =
      receitasMA
        .filter((r) => r.tipo === "Passiva")
        .reduce((acc, cur) => acc + cur.valor, 0) / totalDespesasMA;
    setaIndiceLiberdadeFinanceira(isNaN(valor) ? 0 : valor);
  }, [calcular]);

  return (
    <Container style={{ maxWidth: 798 }}>
      <Row className="mb-3">
        <Col lg={10}>
          <h4 className=" ml-3 text-center">
            Índices do Checkup Financeiro 
          </h4>
        </Col>
        <Col lg={2}>
        <Link to="/planejamento-financeiro/historico-indices"><Button outline color="primary" className="no-margin">Histórico de indices</Button></Link>
        </Col>
      </Row>
      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_EQUILIBRAR}
            titulo="Índice de Endividamento"
            subtitulo="Dívidas / Bens Totais"
            valor={indiceEndividamento}
          >
            <p>
              Indica que {toPercentColor(indiceEndividamento)} do seu patrimônio
              são financiados por dívidas, podendo causar uma falsa impressão de
              tranquilidade.
            </p>
            <p>
              Quanto mais alto for este índice, menor é o seu patrimônio líquido
              (todos os seus bens, descontadas as suas dívidas).
            </p>
            <p>
              O ideal é <strong>mantê-lo próximo de zero</strong>, pois as taxas
              de juros tem sido muito altas no Brasil.
            </p>
            <p>
              LEMBRE-SE que este índice aponta um percentual do seu patrimonio
              que NÃO É SEU.
            </p>
          </BlocoIndice>
        </Col>
      </Row>

      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_EQUILIBRAR}
            titulo="Índice Padrão de Vida"
            subtitulo="Despesas Mensais / Receitas Totais"
            valor={indicePadraoDeVida}
          >
            <p>
              Indica como está dimensionado seu padrão de despesas, comparado
              com suas receitas. Mede a sua Capacidade de Poupança mensal.
            </p>
            <p>
              Quanto menor este índice, significa que está poupando mais, e
              então poderá comprar mais ativos e gerar mais renda passiva.
            </p>
            <p>
              Quando maior que 100%, você está gastando mais do que ganha e deve
              agir imediatamente.
            </p>
            <p>
              EQUILIBRE O SEU PADRÃO DE VIDA PARA AUMENTAR A SUA CAPACIDADE DE
              POUPANÇA.
            </p>
            <p>IDEAL QUE ESTE INDICE FIQUE SEMPRE INFERIOR A 80%.</p>
          </BlocoIndice>
        </Col>
      </Row>

      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_EQUILIBRAR}
            titulo="Reserva Estratégica de Segurança"
            subtitulo="Ativos CP / Despesa Mensal"
            valor={
              indiceCoberturaDespesasMensais === 1
                ? `${indiceCoberturaDespesasMensais} mês`
                : `${indiceCoberturaDespesasMensais} meses`
            }
          >
            <p>
              Calcula como está sua reserva de segurança, caso aconteça um
              imprevisto, como a perda do emprego, crise, doença ou acidente.
              Indica por quantos meses você conseguiria sobreviver com seus
              Ativos de Curto Prazo, que são aqueles com liquidez imediata.
            </p>
            <p>
              <strong>Nunca deixe esse indicador ficar abaixo de 6.</strong>
            </p>
            <p>
              Quando este índice ficar acima de 6, você deve procurar novos
              investimentos para aumentar a sua rentabilidade, pois está
              desperdiçando dinheiro deixando de realizar investimentos mais
              inteligentes.
            </p>
          </BlocoIndice>
        </Col>
      </Row>

      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_EQUILIBRAR}
            titulo="Índice de Imobilização"
            subtitulo="Imóveis totais / Bens totais"
            valor={indiceImobilizacao}
          >
            <p>
              Indica que {toPercentColor(indiceImobilizacao)} do seu patrimônio
              está investido em Imóveis, incluindo sua casa própria.
            </p>
            <p>
              <strong>
                Um alto índice de imobilização pode gerar falta de liquidez nos
                períodos de crises e recessões.
              </strong>
            </p>
            <p>
              Avalie cada um dos imóveis individualmente e veja quais estão
              rendendo e quais estão gerando apenas despesas.
            </p>
            <p>
              <strong>NUNCA</strong> deixe esse índice superar 70% dos seu
              patrimônio.
            </p>
          </BlocoIndice>
        </Col>
      </Row>

      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_EQUILIBRAR}
            titulo="Índice Casa Própria"
            subtitulo="Casa Própria / Bens totais"
            valor={indiceCasaPropria}
          >
            <p>
              Indica que {toPercentColor(indiceCasaPropria)} do seu patrimônio
              estão imobilizados na casa própria, e não estão rendendo R$ 1,00
              centavo sequer.
            </p>
            <p>
              Será que você mora em uma casa compatível com o seu patrimônio?
            </p>
          </BlocoIndice>
        </Col>
      </Row>

      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_EQUILIBRAR}
            titulo="Índice Veicular"
            subtitulo="Automóveis / Bens totais"
            valor={indiceVeicular}
          >
            <p>
              Indica que {toPercentColor(indiceVeicular)} do seu patrimônio
              foram destinados para a aquisição dos seus veículos.
            </p>
            <p>
              Será que faz sentido ter mais de 20% de todo seu patrimônio em
              carros que não geram dinheiro algum a você? Certamente não!
            </p>
          </BlocoIndice>
        </Col>
      </Row>

      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_PLANEJAR}
            titulo="Índice Ativos Trabalhando"
            subtitulo="Bens Ativos / Bens totais"
            valor={indiceAtivosTrabalhando}
          >
            <p>
              Indica que {toPercentColor(indiceAtivosTrabalhando)} do seu
              patrimônio está gerando dinheiro. São realmente ATIVOS
              FINANCEIROS.
            </p>
            <p>
              O restante gera apenas despesas, e isso pode comprometer o seu
              processo de enriquecimento.
            </p>
            <p>
              É hora de avaliar decisões tomadas no passado quanto ao
              dimensionamento e escolha de seus bens atuais (casa, carro, etc.).
            </p>
            <p>
              <strong>
                Quanto mais aumentar este índice, mais seu dinheiro trabalhará
                para você!
              </strong>
            </p>
          </BlocoIndice>
        </Col>
      </Row>

      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_PLANEJAR}
            titulo="Índice de Liquidez Patrimonial"
            subtitulo="Ativos CP / Total de Patrimônio"
            valor={indiceLiquidez}
          >
            <p>
              Liquidez é a capacidade de transformar um bem em dinheiro vivo. 
              Tem a ver com a disponibilidade dos recursos para utilização 
              imediata.
            </p>
            <p>
              Indica o quanto do patrimônio do cliente estará disponível para 
              utilização imediata. 
            </p>
            <p>
              Se o indicador estiver em branco, sinaliza que você não possui
              nenhuma liquidez em seu patrimônio.
            </p>
          </BlocoIndice>
        </Col>
      </Row>

      <Row noGutters className="mb-4">
        <Col lg={12}>
          <BlocoIndice
            tipo={TIPO_PLANEJAR}
            titulo="Índice Independência Financeira"
            subtitulo="Renda Passiva / Despesas Mensais"
            valor={indiceLiberdadeFinanceira}
          >
            <p>
              Indica que {toPercentColor(indiceLiberdadeFinanceira)} das suas
              Despesas conseguem ser pagas através de rendimentos de seus
              investimentos.
            </p>
            <p>
              <strong>
                Índice acima de 100% significa que atingiu a sua Liberdade
                Financeira, e não depende mais do trabalho para pagar as contas
                mensais.
              </strong>
            </p>
            <p>
              EXISTEM 2 FORMAS DE AUMENTAR ESTE ÍNDICE: COMPRANDO ATIVOS E
              AUMENTANDO A RENDA PASSIVA, OU REDUZINDO O SEU PADRÃO DE VIDA.
            </p>
            <p>
              <strong>
                O calculo é realizado utilizando os valores referente ao 
                mês anterior ao periodo selecionado.
              </strong>
            </p>
          </BlocoIndice>
        </Col>
      </Row>
      <br />
    </Container>
  );
}

export default withRouter(Indices);
