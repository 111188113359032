import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Button,
  ButtonGroup,
  Alert
} from "reactstrap";
import { withRouter } from "react-router-dom";
import HistoricoSaudeCampos from "./HistoricoSaudeCampos";
import HistoricoFamiliarCampos from "./HistoricoFamiliarCampos";
import validator from "validator";
import {
  getAxiosAuth,
  getTokenClientId,
  fromDecimal,
  toDecimal, 
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { maxDescriptionWidthText, maxWidthText } from "../../../options";

function HistoricoSaude(props) {
  const clienteId = getTokenClientId();
  const [temConjuge, setaTemConjuge] = useState();
  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [salvar, setaSalvar] = useState();
  const [obter, setaObter] = useState();
  const [usaMedicamentos, setaUsaMedicamentos] = useState();
  const [descrevaMedicamentos, setaDescrevaMedicamentos] = useState("");
  const [descrevaMedicamentosErro, setaDescrevaMedicamentosErro] = useState();
  const [fezCirurgia, setaFezCirurgia] = useState();
  const [descrevaCirurgia, setaDescrevaCirurgia] = useState("");
  const [descrevaCirurgiaErro, setaDescrevaCirurgiaErro] = useState();
  const [temDepressao, setaTemDepressao] = useState();
  const [descrevaDepressao, setaDescrevaDepressao] = useState("");
  const [descrevaDepressaoErro, setaDescrevaDepressaoErro] = useState();
  const [temDiabetes, setaTemDiabetes] = useState();
  const [descrevaDiabetes, setaDescrevaDiabetes] = useState("");
  const [descrevaDiabetesErro, setaDescrevaDiabetesErro] = useState();
  const [temFamiliarCancer, setaTemFamiliarCancer] = useState();
  const [descrevaFamiliarCancer, setaDescrevaFamiliarCancer] = useState("");
  const [
    descrevaFamiliarCancerErro,
    setaDescrevaFamiliarCancerErro
  ] = useState();
  const [praticaEsportesRadicais, setaPraticaEsportesRadicais] = useState();
  const [descrevaEsportesRadicais, setaDescrevaEsportesRadicais] = useState("");
  const [
    descrevaEsportesRadicaisErro,
    setaDescrevaEsportesRadicaisErro
  ] = useState();
  const [usaOculos, setaUsaOculos] = useState();
  const [descrevaOculos, setaDescrevaOculos] = useState("");
  const [descrevaOculosErro, setaDescrevaOculosErro] = useState();
  const [peso, setaPeso] = useState("");
  const [pesoErro, setaPesoErro] = useState();
  const [altura, setaAltura] = useState("");
  const [alturaErro, setaAlturaErro] = useState();
  const [fumante, setaFumante] = useState();

  const [usaMedicamentosConjuge, setaUsaMedicamentosConjuge] = useState();
  const [
    descrevaMedicamentosConjuge,
    setaDescrevaMedicamentosConjuge
  ] = useState("");
  const [
    descrevaMedicamentosErroConjuge,
    setaDescrevaMedicamentosErroConjuge
  ] = useState();
  const [fezCirurgiaConjuge, setaFezCirurgiaConjuge] = useState();
  const [descrevaCirurgiaConjuge, setaDescrevaCirurgiaConjuge] = useState("");
  const [
    descrevaCirurgiaErroConjuge,
    setaDescrevaCirurgiaErroConjuge
  ] = useState();
  const [temDepressaoConjuge, setaTemDepressaoConjuge] = useState();
  const [descrevaDepressaoConjuge, setaDescrevaDepressaoConjuge] = useState("");
  const [
    descrevaDepressaoErroConjuge,
    setaDescrevaDepressaoErroConjuge
  ] = useState();
  const [temDiabetesConjuge, setaTemDiabetesConjuge] = useState();
  const [descrevaDiabetesConjuge, setaDescrevaDiabetesConjuge] = useState("");
  const [
    descrevaDiabetesErroConjuge,
    setaDescrevaDiabetesErroConjuge
  ] = useState();
  const [temFamiliarCancerConjuge, setaTemFamiliarCancerConjuge] = useState();
  const [
    descrevaFamiliarCancerConjuge,
    setaDescrevaFamiliarCancerConjuge
  ] = useState("");
  const [
    descrevaFamiliarCancerErroConjuge,
    setaDescrevaFamiliarCancerErroConjuge
  ] = useState();
  const [
    praticaEsportesRadicaisConjuge,
    setaPraticaEsportesRadicaisConjuge
  ] = useState();
  const [
    descrevaEsportesRadicaisConjuge,
    setaDescrevaEsportesRadicaisConjuge
  ] = useState("");
  const [
    descrevaEsportesRadicaisErroConjuge,
    setaDescrevaEsportesRadicaisErroConjuge
  ] = useState();
  const [usaOculosConjuge, setaUsaOculosConjuge] = useState();
  const [descrevaOculosConjuge, setaDescrevaOculosConjuge] = useState("");
  const [descrevaOculosErroConjuge, setaDescrevaOculosErroConjuge] = useState();
  const [pesoConjuge, setaPesoConjuge] = useState("");
  const [pesoErroConjuge, setaPesoErroConjuge] = useState();
  const [alturaConjuge, setaAlturaConjuge] = useState("");
  const [alturaErroConjuge, setaAlturaErroConjuge] = useState();
  const [fumanteConjuge, setaFumanteConjuge] = useState();
  const [nomePai, setaNomePai] = useState("");
  const [nomePaiErro, setaNomePaiErro] = useState();
  const [idadePai, setaIdadePai] = useState("");
  const [idadePaiErro, setaIdadePaiErro] = useState();
  const [estadoSaudePai, setaEstadoSaudePai] = useState("");
  const [falecidoPai, setaFalecidoPai] = useState();
  const [nomeMae, setaNomeMae] = useState("");
  const [nomeMaeErro, setaNomeMaeErro] = useState();
  const [idadeMae, setaIdadeMae] = useState("");
  const [idadeMaeErro, setaIdadeMaeErro] = useState();
  const [estadoSaudeMae, setaEstadoSaudeMae] = useState("");
  const [falecidoMae, setaFalecidoMae] = useState();
  const [nomePaiConjuge, setaNomePaiConjuge] = useState("");
  const [nomePaiErroConjuge, setaNomePaiErroConjuge] = useState();
  const [idadePaiConjuge, setaIdadePaiConjuge] = useState("");
  const [idadePaiErroConjuge, setaIdadePaiErroConjuge] = useState();
  const [estadoSaudePaiConjuge, setaEstadoSaudePaiConjuge] = useState("");
  const [falecidoPaiConjuge, setaFalecidoPaiConjuge] = useState();
  const [nomeMaeConjuge, setaNomeMaeConjuge] = useState("");
  const [nomeMaeErroConjuge, setaNomeMaeErroConjuge] = useState();
  const [idadeMaeConjuge, setaIdadeMaeConjuge] = useState("");
  const [idadeMaeErroConjuge, setaIdadeMaeErroConjuge] = useState();
  const [estadoSaudeMaeConjuge, setaEstadoSaudeMaeConjuge] = useState("");
  const [falecidoMaeConjuge, setaFalecidoMaeConjuge] = useState();
  const [irmaos, setaIrmaos] = useState([]);
  const [irmaosConjuge, setaIrmaosConjuge] = useState([]);

  const onChangeDescrevaMedicamentos = (conjuge, e) => {
    conjuge
      ? setaDescrevaMedicamentosConjuge(e.target.value)
      : setaDescrevaMedicamentos(e.target.value);
  };

  const validaDescrevaMedicamentos = conjuge => {
    const preenchido = conjuge ? usaMedicamentosConjuge : usaMedicamentos;
    const valor = conjuge ? descrevaMedicamentosConjuge : descrevaMedicamentos;
    const setaErro = conjuge
      ? setaDescrevaMedicamentosErroConjuge
      : setaDescrevaMedicamentosErro;

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurDescrevaMedicamentos = conjuge => {
    validaDescrevaMedicamentos(conjuge);
  };

  const onChangeDescrevaCirurgia = (conjuge, e) => {
    conjuge
      ? setaDescrevaCirurgiaConjuge(e.target.value)
      : setaDescrevaCirurgia(e.target.value);
  };

  const validaDescrevaCirurgia = conjuge => {
    const preenchido = conjuge ? fezCirurgiaConjuge : fezCirurgia;
    const valor = conjuge ? descrevaCirurgiaConjuge : descrevaCirurgia;
    const setaErro = conjuge
      ? setaDescrevaCirurgiaErroConjuge
      : setaDescrevaCirurgiaErro;

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório.");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurDescrevaCirurgia = conjuge => {
    validaDescrevaCirurgia(conjuge);
  };

  const onChangeDescrevaDepressao = (conjuge, e) => {
    conjuge
      ? setaDescrevaDepressaoConjuge(e.target.value)
      : setaDescrevaDepressao(e.target.value);
  };

  const validaDescrevaDepressao = conjuge => {
    const preenchido = conjuge ? temDepressaoConjuge : temDepressao;
    const valor = conjuge ? descrevaDepressaoConjuge : descrevaDepressao;
    const setaErro = conjuge
      ? setaDescrevaDepressaoErroConjuge
      : setaDescrevaDepressaoErro;

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurDescrevaDepressao = conjuge => {
    validaDescrevaDepressao(conjuge);
  };

  const onChangeDescrevaDiabetes = (conjuge, e) => {
    conjuge
      ? setaDescrevaDiabetesConjuge(e.target.value)
      : setaDescrevaDiabetes(e.target.value);
  };

  const validaDescrevaDiabetes = conjuge => {
    const preenchido = conjuge ? temDiabetesConjuge : temDiabetes;
    const valor = conjuge ? descrevaDiabetesConjuge : descrevaDiabetes;
    const setaErro = conjuge
      ? setaDescrevaDiabetesErroConjuge
      : setaDescrevaDiabetesErro;

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório.");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurDescrevaDiabetes = conjuge => {
    validaDescrevaDiabetes(conjuge);
  };

  const onChangeDescrevaFamiliarCancer = (conjuge, e) => {
    conjuge
      ? setaDescrevaFamiliarCancerConjuge(e.target.value)
      : setaDescrevaFamiliarCancer(e.target.value);
  };

  const validaDescrevaFamiliarCancer = conjuge => {
    const preenchido = conjuge ? temFamiliarCancerConjuge : temFamiliarCancer;
    const valor = conjuge
      ? descrevaFamiliarCancerConjuge
      : descrevaFamiliarCancer;
    const setaErro = conjuge
      ? setaDescrevaFamiliarCancerErroConjuge
      : setaDescrevaFamiliarCancerErro;

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório.");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurDescrevaFamiliarCancer = conjuge => {
    validaDescrevaFamiliarCancer(conjuge);
  };

  const onChangeDescrevaEsportesRadicais = (conjuge, e) => {
    conjuge
      ? setaDescrevaEsportesRadicaisConjuge(e.target.value)
      : setaDescrevaEsportesRadicais(e.target.value);
  };

  const validaDescrevaEsportesRadicais = conjuge => {
    const preenchido = conjuge
      ? praticaEsportesRadicaisConjuge
      : praticaEsportesRadicais;
    const valor = conjuge
      ? descrevaEsportesRadicaisConjuge
      : descrevaEsportesRadicais;
    const setaErro = conjuge
      ? setaDescrevaEsportesRadicaisErroConjuge
      : setaDescrevaEsportesRadicaisErro;

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório.");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurDescrevaEsportesRadicais = conjuge => {
    validaDescrevaEsportesRadicais(conjuge);
  };

  const onChangeDescrevaOculos = (conjuge, e) => {
    conjuge
      ? setaDescrevaOculosConjuge(e.target.value)
      : setaDescrevaOculos(e.target.value);
  };

  const validaDescrevaOculos = conjuge => {
    const preenchido = conjuge ? usaOculosConjuge : usaOculos;
    const valor = conjuge ? descrevaOculosConjuge : descrevaOculos;
    const setaErro = conjuge
      ? setaDescrevaOculosErroConjuge
      : setaDescrevaOculosErro;

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório.");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurDescrevaOculos = conjuge => {
    validaDescrevaOculos(conjuge);
  };

  const onChangePeso = (conjuge, e) => {
    conjuge ? setaPesoConjuge(e.target.value) : setaPeso(e.target.value);
  };

  const validaPeso = conjuge => {
    const valor = conjuge ? pesoConjuge : peso;
    const preenchido = !validator.isEmpty(valor, { ignore_whitespace: true });
    const setaErro = conjuge ? setaPesoErroConjuge : setaPesoErro;

    if (
      preenchido &&
      (!validator.isDecimal(valor, { locale: "pt-BR" }) || valor < 0)
    ) {
      setaErro("Valor inválido");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurPeso = conjuge => {
    validaPeso(conjuge);
  };

  const onChangeAltura = (conjuge, e) => {
    conjuge ? setaAlturaConjuge(e.target.value) : setaAltura(e.target.value);
  };

  const validaAltura = conjuge => {
    const valor = conjuge ? alturaConjuge : altura;
    const preenchido = !validator.isEmpty(valor, { ignore_whitespace: true });
    const setaErro = conjuge ? setaAlturaErroConjuge : setaAlturaErro;

    if (
      preenchido &&
      (!validator.isDecimal(valor, { locale: "pt-BR" }) || valor < 0)
    ) {
      setaErro("Valor inválido");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurAltura = conjuge => {
    validaAltura(conjuge);
  };

  const onChangeFumante = (conjuge, e) => {
    const valor = e.target.checked;
    conjuge ? setaFumanteConjuge(valor) : setaFumante(valor);
  };

  const onChangeUsaMedicamentos = (conjuge, e) => {
    const valor = e.target.value === "true";
    conjuge ? setaUsaMedicamentosConjuge(valor) : setaUsaMedicamentos(valor);
  };

  const onChangeFezCirurgia = (conjuge, e) => {
    const valor = e.target.value === "true";
    conjuge ? setaFezCirurgiaConjuge(valor) : setaFezCirurgia(valor);
  };

  const onChangeTemDepressao = (conjuge, e) => {
    const valor = e.target.value === "true";
    conjuge ? setaTemDepressaoConjuge(valor) : setaTemDepressao(valor);
  };

  const onChangeTemDiabetes = (conjuge, e) => {
    const valor = e.target.value === "true";
    conjuge ? setaTemDiabetesConjuge(valor) : setaTemDiabetes(valor);
  };

  const onChangeTemFamiliarCancer = (conjuge, e) => {
    const valor = e.target.value === "true";
    conjuge
      ? setaTemFamiliarCancerConjuge(valor)
      : setaTemFamiliarCancer(valor);
  };

  const onChangePraticaEsportesRadicais = (conjuge, e) => {
    const valor = e.target.value === "true";
    conjuge
      ? setaPraticaEsportesRadicaisConjuge(valor)
      : setaPraticaEsportesRadicais(valor);
  };

  const onChangeUsaOculos = (conjuge, e) => {
    const valor = e.target.value === "true";
    conjuge ? setaUsaOculosConjuge(valor) : setaUsaOculos(valor);
  };

  const onChangeNomeFamiliar = (conjuge, mae, e) => {
    const valor = e.target.value.slice(0,maxWidthText);
    const setaValor = conjuge
      ? mae
        ? setaNomeMaeConjuge
        : setaNomePaiConjuge
      : mae
      ? setaNomeMae
      : setaNomePai;

    setaValor(valor);
  };

  const estaLimpoFamiliar = (conjuge, mae) => {
    const nome = conjuge
      ? mae
        ? nomeMaeConjuge
        : nomePaiConjuge
      : mae
      ? nomeMae
      : nomePai;

    const idade = conjuge
      ? mae
        ? idadeMaeConjuge
        : idadePaiConjuge
      : mae
      ? idadeMae
      : idadePai;

    const estadoSaude = conjuge
      ? mae
        ? estadoSaudeMaeConjuge
        : estadoSaudePaiConjuge
      : mae
      ? estadoSaudeMae
      : estadoSaudePai;
    return (
      validator.isEmpty(nome, { ignore_whitespace: true }) &&
      validator.isEmpty(idade, { ignore_whitespace: true }) &&
      validator.isEmpty(estadoSaude, { ignore_whitespace: true })
    );
  };

  const validaNomeFamiliar = (conjuge, mae) => {
    const valor = conjuge
      ? mae
        ? nomeMaeConjuge
        : nomePaiConjuge
      : mae
      ? nomeMae
      : nomePai;
    const setaErro = conjuge
      ? mae
        ? setaNomeMaeErroConjuge
        : setaNomePaiErroConjuge
      : mae
      ? setaNomeMaeErro
      : setaNomePaiErro;

    const preenchido = !estaLimpoFamiliar(conjuge, mae);

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório.");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurNomeFamiliar = (conjuge, mae) => {
    validaNomeFamiliar(conjuge, mae);
  };

  const onChangeIdadeFamiliar = (conjuge, mae, e) => {
    const valor = e.target.value;
    const setaValor = conjuge
      ? mae
        ? setaIdadeMaeConjuge
        : setaIdadePaiConjuge
      : mae
      ? setaIdadeMae
      : setaIdadePai;

    setaValor(valor);
  };

  const validaIdadeFamiliar = (conjuge, mae) => {
    const valor = conjuge
      ? mae
        ? idadeMaeConjuge
        : idadePaiConjuge
      : mae
      ? idadeMae
      : idadePai;
    const setaErro = conjuge
      ? mae
        ? setaIdadeMaeErroConjuge
        : setaIdadePaiErroConjuge
      : mae
      ? setaIdadeMaeErro
      : setaIdadePaiErro;

    const preenchido = !estaLimpoFamiliar(conjuge, mae);

    if (preenchido && validator.isEmpty(valor, { ignore_whitespace: true })) {
      setaErro("Campo obrigatório.");
      return false;
    }

    if (preenchido && (!validator.isInt(valor) || valor < 0)) {
      setaErro("Valor inválido.");
      return false;
    }

    setaErro(preenchido ? false : null);
    return true;
  };

  const onBlurIdadeFamiliar = (conjuge, mae) => {
    validaIdadeFamiliar(conjuge, mae);
  };

  const onChangeEstadoSaudeFamiliar = (conjuge, mae, e) => {
    const valor = e.target.value.slice(0,maxDescriptionWidthText);
    const setaValor = conjuge
      ? mae
        ? setaEstadoSaudeMaeConjuge
        : setaEstadoSaudePaiConjuge
      : mae
      ? setaEstadoSaudeMae
      : setaEstadoSaudePai;

    setaValor(valor);
  };

  const onBlurEstadoSaudeFamiliar = (conjuge, mae) => {};

  const onChangeFalecidoFamiliar = (conjuge, mae, e) => {
    const valor = e.target.checked;
    const setaValor = conjuge
      ? mae
        ? setaFalecidoMaeConjuge
        : setaFalecidoPaiConjuge
      : mae
      ? setaFalecidoMae
      : setaFalecidoPai;

    setaValor(valor);
  };

  const NOVO_IRMAO = {
    id: null,
    nome: "",
    nomeErro: null,
    idade: "",
    idadeErro: null,
    estadoSaude: "",
    estadoSaudeErro: null,
    falecido: false,
    numeroFilhos: "",
    numeroFilhosErro: null,
    obs: ""
  };

  const onClickBtnAdicionarIrmao = (conjuge, e) => {
    e.preventDefault();

    const dados = conjuge ? irmaosConjuge : irmaos;
    const setaNovos = conjuge ? setaIrmaosConjuge : setaIrmaos;

    let novosIrmaos = [...dados, { ...NOVO_IRMAO }];

    setaNovos(novosIrmaos);
  };

  const onClickBtnRemoverIrmao = (conjuge, idx, e) => {
    e.preventDefault();

    const dados = conjuge ? irmaosConjuge : irmaos;
    const setaNovos = conjuge ? setaIrmaosConjuge : setaIrmaos;

    let novosIrmaos = [...dados];

    if (novosIrmaos.length === 1) {
      novosIrmaos = [{ ...NOVO_IRMAO }];
    } else {
      novosIrmaos.splice(idx, 1);
    }

    setaNovos(novosIrmaos);
  };

  const onChangeIrmaoCampo = (conjuge, idx, campo, valor) => {
    const dados = conjuge ? irmaosConjuge : irmaos;
    const setaNovos = conjuge ? setaIrmaosConjuge : setaIrmaos;

    let novosIrmaos = [...dados];

    novosIrmaos[idx][campo] = valor;

    setaNovos(novosIrmaos);
  };

  const onChangeIrmaoNome = (conjuge, idx, e) => {
    const valor = e.target.value.slice(0,maxWidthText)
    onChangeIrmaoCampo(conjuge, idx, "nome", valor);
  };

  const onChangeIrmaoIdade = (conjuge, idx, e) => {
    onChangeIrmaoCampo(conjuge, idx, "idade", e.target.value);
  };

  const onChangeIrmaoEstadoSaude = (conjuge, idx, e) => {
    const valor = e.target.value.slice(0,maxDescriptionWidthText);
    onChangeIrmaoCampo(conjuge, idx, "estadoSaude", valor);
  };

  const onChangeIrmaoFalecido = (conjuge, idx, e) => {
    onChangeIrmaoCampo(conjuge, idx, "falecido", e.target.checked);
  };

  const onChangeIrmaoNumeroFilhos = (conjuge, idx, e) => {
    onChangeIrmaoCampo(conjuge, idx, "numeroFilhos", e.target.value);
  };

  const onChangeIrmaoObs = (conjuge, idx, e) => {
    const valor = e.target.value.slice(0,maxDescriptionWidthText);
    onChangeIrmaoCampo(conjuge, idx, "obs", valor);
  };

  const estaLimpoIrmaos = conjuge => {
    let colecao = conjuge ? irmaosConjuge : irmaos;

    if (colecao.length > 1) {
      return false;
    } else if (colecao.length === 1) {
      const irmao = colecao[0];

      return (
        validator.isEmpty(irmao.nome, { ignore_whitespace: true }) &&
        validator.isEmpty(irmao.idade, { ignore_whitespace: true }) &&
        validator.isEmpty(irmao.numeroFilhos, { ignore_whitespace: true }) &&
        validator.isEmpty(irmao.obs, { ignore_whitespace: true })
      );
    }

    return true;
  };

  const validaIrmaoNome = (conjuge, idx) => {
    const dados = conjuge ? irmaosConjuge : irmaos;
    const setaNovos = conjuge ? setaIrmaosConjuge : setaIrmaos;
    let novosIrmaos = [...dados];

    const irmao = novosIrmaos[idx];

    if (!irmao) return;

    let valido = true;
    let preenchido = !estaLimpoIrmaos(conjuge);

    if (
      preenchido &&
      validator.isEmpty(irmao.nome, { ignore_whitespace: true })
    ) {
      irmao.nomeErro = "Campo obrigatório.";
      valido = false;
    }

    if (valido) {
      irmao.nomeErro = preenchido ? false : null;
    }

    setaNovos(novosIrmaos);

    return valido;
  };

  const onBlurIrmaoNome = (conjuge, idx) => {
    validaIrmaoNome(conjuge, idx);
  };

  const validaIrmaoIdade = (conjuge, idx) => {
    const dados = conjuge ? irmaosConjuge : irmaos;
    const setaNovos = conjuge ? setaIrmaosConjuge : setaIrmaos;
    let novosIrmaos = [...dados];

    const irmao = novosIrmaos[idx];

    if (!irmao) return;

    let valido = true;
    let preenchido = !estaLimpoIrmaos(conjuge);

    if (
      preenchido &&
      validator.isEmpty(irmao.idade, { ignore_whitespace: true })
    ) {
      irmao.idadeErro = "Campo obrigatório.";
      valido = false;
    }

    if (
      preenchido &&
      valido &&
      (!validator.isInt(irmao.idade) || irmao.idade < 0)
    ) {
      irmao.idadeErro = "Valor inválido.";
      valido = false;
    }

    if (valido) {
      irmao.idadeErro = preenchido ? false : null;
    }

    setaNovos(novosIrmaos);

    return valido;
  };

  const onBlurIrmaoIdade = (conjuge, idx) => {
    validaIrmaoIdade(conjuge, idx);
  };

  const onBlurIrmaoEstadoSaude = (conjuge, idx) => {};

  const validaIrmaoNumeroFilhos = (conjuge, idx) => {
    const dados = conjuge ? irmaosConjuge : irmaos;
    const setaNovos = conjuge ? setaIrmaosConjuge : setaIrmaos;
    let novosIrmaos = [...dados];

    const irmao = novosIrmaos[idx];

    if (!irmao) return;

    let valido = true;
    let preenchido = !estaLimpoIrmaos(conjuge);

    if (
      preenchido &&
      !validator.isEmpty(irmao.numeroFilhos, { ignore_whitespace: true }) &&
      (!validator.isInt(irmao.numeroFilhos) || irmao.numeroFilhos < 0)
    ) {
      irmao.numeroFilhosErro = "Valor inválido.";
      valido = false;
    }

    if (valido) {
      irmao.numeroFilhosErro = preenchido ? false : null;
    }

    setaNovos(novosIrmaos);

    return valido;
  };

  const onBlurIrmaoNumeroFilhos = (conjuge, idx) => {
    validaIrmaoNumeroFilhos(conjuge, idx);
  };

  const onSubmitForm = e => {
    e.preventDefault();

    let valido = true;

    let validadores = [
      validaDescrevaMedicamentos.bind(this, false),
      validaDescrevaCirurgia.bind(this, false),
      validaDescrevaDepressao.bind(this, false),
      validaDescrevaDiabetes.bind(this, false),
      validaDescrevaFamiliarCancer.bind(this, false),
      validaDescrevaEsportesRadicais.bind(this, false),
      validaDescrevaOculos.bind(this, false),
      validaPeso.bind(this, false),
      validaAltura.bind(this, false),
      validaNomeFamiliar.bind(this, false, false),
      validaIdadeFamiliar.bind(this, false, false),
      validaNomeFamiliar.bind(this, false, true),
      validaIdadeFamiliar.bind(this, false, true)
    ];

    irmaos.forEach((irmao, idx) => {
      validadores.push(validaIrmaoNome.bind(this, false, idx));
      validadores.push(validaIrmaoIdade.bind(this, false, idx));
      validadores.push(validaIrmaoNumeroFilhos.bind(this, false, idx));
    });

    if (temConjuge) {
      validadores = [
        ...validadores,
        validaDescrevaMedicamentos.bind(this, true),
        validaDescrevaCirurgia.bind(this, true),
        validaDescrevaDepressao.bind(this, true),
        validaDescrevaDiabetes.bind(this, true),
        validaDescrevaFamiliarCancer.bind(this, true),
        validaDescrevaEsportesRadicais.bind(this, true),
        validaDescrevaOculos.bind(this, true),
        validaPeso.bind(this, true),
        validaAltura.bind(this, true),
        validaNomeFamiliar.bind(this, true, false),
        validaIdadeFamiliar.bind(this, true, false),
        validaNomeFamiliar.bind(this, true, true),
        validaIdadeFamiliar.bind(this, true, true)
      ];

      irmaos.forEach((irmao, idx) => {
        validadores.push(validaIrmaoNome.bind(this, true, idx));
        validadores.push(validaIrmaoIdade.bind(this, true, idx));
        validadores.push(validaIrmaoNumeroFilhos.bind(this, true, idx));
      });
    }

    validadores.forEach(validador => {
      if (!validador()) {
        valido = false;
      }
    });

    if (valido) {
      setaFormularioValido(true);
      setaSalvar(new Date());
    } else {
      setaFormularioValido(false);
      setaFormularioErro("Verifique os campos.");
    }
  };

  // primeiro acesso: descobre se tem conjuge
  useEffect(() => {
    const getConjuge = async () => {
      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });

      const uri = `/clientes/${clienteId}/conjuge`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
          logFront(err, "getConjuge()");
        } else {
          console.error(err);
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          // alert(err.message);
          return err.message;
        }

        if (response.status !== 200 && response.status !== 404) {
          console.error(err, response);
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        setaTemConjuge(true);
      } else {
        setaTemConjuge(false);
      }

      setaObter(new Date());
    };

    getConjuge();
  }, []);

  // primeiro acesso: obter dados
  useEffect(() => {
    const getSaude = async () => {
      // TODO:
      // setaIrmaos([{ ...NOVO_IRMAO }]);
      // setaIrmaosConjuge([{ ...NOVO_IRMAO }]);

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/saude`;
      let response;

      try {
        response = await axiosInstance.get(uri);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
          logFront(err, "getSaude()");
        } else {
          console.error(err);
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          // alert(err.message);
          return err.message;
        }
      }

      const dados = response.data;

      const principal = dados.saude || {};
      const conjuge = dados.saudeConjuge || {};

      setaUsaMedicamentos(principal.usaMedicamentos);
      setaDescrevaMedicamentos(principal.descrevaMedicamentos || "");
      setaFezCirurgia(principal.fezCirurgia);
      setaDescrevaCirurgia(principal.descrevaCirurgia || "");
      setaTemDepressao(principal.temDepressao);
      setaDescrevaDepressao(principal.descrevaDepressao || "");
      setaTemDiabetes(principal.temDiabetes);
      setaDescrevaDiabetes(principal.descrevaDiabetes || "");
      setaTemFamiliarCancer(principal.temFamiliarCancer);
      setaDescrevaFamiliarCancer(principal.descrevaFamiliarCancer || "");
      setaPraticaEsportesRadicais(principal.praticaEsportesRadicais);
      setaDescrevaEsportesRadicais(principal.descrevaEsportesRadicais || "");
      setaUsaOculos(principal.usaOculos);
      setaDescrevaOculos(principal.descrevaOculos || "");
      setaPeso(principal.peso ? toDecimal(principal.peso) : "");
      setaAltura(principal.altura ? toDecimal(principal.altura) : "");
      setaFumante(principal.fumante);
      setaNomePai(principal.nomePai || "");
      setaIdadePai(String(principal.idadePai || ""));
      setaEstadoSaudePai(principal.estadoSaudePai || "");
      setaFalecidoPai(principal.paiFalecido);
      setaNomeMae(principal.nomeMae || "");
      setaIdadeMae(String(principal.idadeMae || ""));
      setaEstadoSaudeMae(principal.estadoSaudeMae || "");
      setaFalecidoMae(principal.maeFalecida);
      setaIrmaos(
        principal.irmaos &&
          Array.isArray(principal.irmaos) &&
          principal.irmaos.length > 0
          ? principal.irmaos.map(irmao => ({
              ...NOVO_IRMAO,
              id: irmao.id,
              nome: irmao.nome || "",
              idade: String(irmao.idade || ""),
              estadoSaude: irmao.estadoSaude || "",
              falecido: irmao.falecido,
              numeroFilhos: String(irmao.numeroFilhos || ""),
              obs: irmao.obs || ""
            }))
          : [{ ...NOVO_IRMAO }]
      );

      setaUsaMedicamentosConjuge(conjuge.usaMedicamentos);
      setaDescrevaMedicamentosConjuge(conjuge.descrevaMedicamentos || "");
      setaFezCirurgiaConjuge(conjuge.fezCirurgia);
      setaDescrevaCirurgiaConjuge(conjuge.descrevaCirurgia || "");
      setaTemDepressaoConjuge(conjuge.temDepressao);
      setaDescrevaDepressaoConjuge(conjuge.descrevaDepressao || "");
      setaTemDiabetesConjuge(conjuge.temDiabetes);
      setaDescrevaDiabetesConjuge(conjuge.descrevaDiabetes || "");
      setaTemFamiliarCancerConjuge(conjuge.temFamiliarCancer);
      setaDescrevaFamiliarCancerConjuge(conjuge.descrevaFamiliarCancer || "");
      setaPraticaEsportesRadicaisConjuge(conjuge.praticaEsportesRadicais);
      setaDescrevaEsportesRadicaisConjuge(
        conjuge.descrevaEsportesRadicais || ""
      );
      setaUsaOculosConjuge(conjuge.usaOculos);
      setaDescrevaOculosConjuge(conjuge.descrevaOculos || "");
      setaPesoConjuge(conjuge.peso ? toDecimal(conjuge.peso) : "");
      setaAlturaConjuge(conjuge.altura ? toDecimal(conjuge.altura) : "");
      setaFumanteConjuge(conjuge.fumante);
      setaNomePaiConjuge(conjuge.nomePai || "");
      setaIdadePaiConjuge(String(conjuge.idadePai || ""));
      setaEstadoSaudePaiConjuge(conjuge.estadoSaudePai || "");
      setaFalecidoPaiConjuge(conjuge.paiFalecido);
      setaNomeMaeConjuge(conjuge.nomeMae || "");
      setaIdadeMaeConjuge(String(conjuge.idadeMae || ""));
      setaEstadoSaudeMaeConjuge(conjuge.estadoSaudeMae || "");
      setaFalecidoMaeConjuge(conjuge.maeFalecida);
      setaIrmaosConjuge(
        conjuge.irmaos &&
          Array.isArray(conjuge.irmaos) &&
          conjuge.irmaos.length > 0
          ? conjuge.irmaos.map(irmao => ({
              ...NOVO_IRMAO,
              id: irmao.id,
              nome: irmao.nome || "",
              idade: String(irmao.idade || ""),
              estadoSaude: irmao.estadoSaude || "",
              falecido: irmao.falecido,
              numeroFilhos: String(irmao.numeroFilhos || ""),
              obs: irmao.obs || ""
            }))
          : [{ ...NOVO_IRMAO }]
      );

      if (formularioValido) {
        toast("Histórico de saúde salvo com sucesso!");
      }
    };

    if (obter) {
      getSaude();
    }
  }, [obter]);

  // salvar
  useEffect(() => {
    const putSaude = async () => {
      const dados = {
        principal: {
          usaMedicamentos,
          descrevaMedicamentos,
          fezCirurgia,
          descrevaCirurgia,
          temDepressao,
          descrevaDepressao,
          temDiabetes,
          descrevaDiabetes,
          temFamiliarCancer,
          descrevaFamiliarCancer,
          praticaEsportesRadicais,
          descrevaEsportesRadicais,
          usaOculos,
          descrevaOculos,
          peso: fromDecimal(peso),
          altura: fromDecimal(altura),
          fumante,
          nomePai,
          idadePai: parseInt(idadePai),
          estadoSaudePai,
          paiFalecido: falecidoPai,
          nomeMae,
          idadeMae: parseInt(idadeMae),
          estadoSaudeMae,
          maeFalecida: falecidoMae,
          irmaos: estaLimpoIrmaos(false)
            ? []
            : irmaos.map(irmao => ({
                id: irmao.id,
                nome: irmao.nome,
                idade: parseInt(irmao.idade),
                estadoSaude: irmao.estadoSaude,
                falecido: irmao.falecido,
                numeroFilhos: parseInt(irmao.numeroFilhos),
                obs: irmao.obs
              }))
        },
        conjuge: {}
      };

      if (temConjuge) {
        dados.conjuge = {
          ...dados.conjuge,
          usaMedicamentos: usaMedicamentosConjuge,
          descrevaMedicamentos: descrevaMedicamentosConjuge,
          fezCirurgia: fezCirurgiaConjuge,
          descrevaCirurgia: descrevaCirurgiaConjuge,
          temDepressao: temDepressaoConjuge,
          descrevaDepressao: descrevaDepressaoConjuge,
          temDiabetes: temDiabetesConjuge,
          descrevaDiabetes: descrevaDiabetesConjuge,
          temFamiliarCancer: temFamiliarCancerConjuge,
          descrevaFamiliarCancer: descrevaFamiliarCancerConjuge,
          praticaEsportesRadicais: praticaEsportesRadicaisConjuge,
          descrevaEsportesRadicais: descrevaEsportesRadicaisConjuge,
          usaOculos: usaOculosConjuge,
          descrevaOculos: descrevaOculosConjuge,
          peso: fromDecimal(pesoConjuge),
          altura: fromDecimal(alturaConjuge),
          fumante: fumanteConjuge,
          nomePai: nomePaiConjuge,
          idadePai: parseInt(idadePaiConjuge),
          estadoSaudePai: estadoSaudePaiConjuge,
          paiFalecido: falecidoPaiConjuge,
          nomeMae: nomeMaeConjuge,
          idadeMae: parseInt(idadeMaeConjuge),
          estadoSaudeMae: estadoSaudeMaeConjuge,
          maeFalecida: falecidoMaeConjuge,
          irmaos: estaLimpoIrmaos(true)
            ? []
            : irmaosConjuge.map(irmao => ({
                id: irmao.id,
                nome: irmao.nome,
                idade: parseInt(irmao.idade),
                estadoSaude: irmao.estadoSaude,
                falecido: irmao.falecido,
                numeroFilhos: parseInt(irmao.numeroFilhos),
                obs: irmao.obs
              }))
        };
      }

      const axiosInstance = getAxiosAuth(() => {
        props.history.push("/login");
      });
      const uri = `/clientes/${clienteId}/saude`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err.response);
          logFront(err, "putSaude()");
        } else {
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
    
          console.error(err);
          // alert(err.message);
          return err.message;
        }
      }

      if (response.status === 200) {
        setaObter(new Date());
      } else if (response.status === 400) {
        let mensagem = "Problema interno no servidor.";

        setaFormularioErro(mensagem);
        setaFormularioValido(false);
      } else if (response.status === 500) {
        const responseApp = await logApp(axiosInstance, response);
      await logClient(axiosInstance, null, response, responseApp.data.trace_id, uri, response.status);
 
      props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(response.message);
        return response.message;
      } else {
        props.history.push("/404");
      }
    };

    if (formularioValido) {
      putSaude();
    }
  }, [salvar]);

  return (
    <div className="animated fadeIn">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        style={{ zIndex: 1999 }}
      />
      <Form className="form-horizontal" onSubmit={onSubmitForm}>
        <Row>
          <Col
            sm={12}
            md={12}
            lg={temConjuge ? 6 : 12}
            style={{ flexBasis: "auto" }}
          >
            <Card>
              <CardHeader>
                <i className="fas fa-file-medical" />
                <strong>Principal</strong>
              </CardHeader>
              <CardBody>
                <HistoricoSaudeCampos
                  usaMedicamentos={usaMedicamentos}
                  onChangeUsaMedicamentos={onChangeUsaMedicamentos.bind(
                    this,
                    false
                  )}
                  descrevaMedicamentos={descrevaMedicamentos}
                  descrevaMedicamentosErro={descrevaMedicamentosErro}
                  onChangeDescrevaMedicamentos={onChangeDescrevaMedicamentos.bind(
                    this,
                    false
                  )}
                  onBlurDescrevaMedicamentos={onBlurDescrevaMedicamentos.bind(
                    this,
                    false
                  )}
                  fezCirurgia={fezCirurgia}
                  onChangeFezCirurgia={onChangeFezCirurgia.bind(this, false)}
                  descrevaCirurgia={descrevaCirurgia}
                  descrevaCirurgiaErro={descrevaCirurgiaErro}
                  onChangeDescrevaCirurgia={onChangeDescrevaCirurgia.bind(
                    this,
                    false
                  )}
                  onBlurDescrevaCirurgia={onBlurDescrevaCirurgia.bind(
                    this,
                    false
                  )}
                  temDepressao={temDepressao}
                  onChangeTemDepressao={onChangeTemDepressao.bind(this, false)}
                  descrevaDepressao={descrevaDepressao}
                  descrevaDepressaoErro={descrevaDepressaoErro}
                  onChangeDescrevaDepressao={onChangeDescrevaDepressao.bind(
                    this,
                    false
                  )}
                  onBlurDescrevaDepressao={onBlurDescrevaDepressao.bind(
                    this,
                    false
                  )}
                  temDiabetes={temDiabetes}
                  onChangeTemDiabetes={onChangeTemDiabetes.bind(this, false)}
                  descrevaDiabetes={descrevaDiabetes}
                  descrevaDiabetesErro={descrevaDiabetesErro}
                  onChangeDescrevaDiabetes={onChangeDescrevaDiabetes.bind(
                    this,
                    false
                  )}
                  onBlurDescrevaDiabetes={onBlurDescrevaDiabetes.bind(
                    this,
                    false
                  )}
                  temFamiliarCancer={temFamiliarCancer}
                  onChangeTemFamiliarCancer={onChangeTemFamiliarCancer.bind(
                    this,
                    false
                  )}
                  descrevaFamiliarCancer={descrevaFamiliarCancer}
                  descrevaFamiliarCancerErro={descrevaFamiliarCancerErro}
                  onChangeDescrevaFamiliarCancer={onChangeDescrevaFamiliarCancer.bind(
                    this,
                    false
                  )}
                  onBlurDescrevaFamiliarCancer={onBlurDescrevaFamiliarCancer.bind(
                    this,
                    false
                  )}
                  praticaEsportesRadicais={praticaEsportesRadicais}
                  onChangePraticaEsportesRadicais={onChangePraticaEsportesRadicais.bind(
                    this,
                    false
                  )}
                  descrevaEsportesRadicais={descrevaEsportesRadicais}
                  descrevaEsportesRadicaisErro={descrevaEsportesRadicaisErro}
                  onChangeDescrevaEsportesRadicais={onChangeDescrevaEsportesRadicais.bind(
                    this,
                    false
                  )}
                  onBlurDescrevaEsportesRadicais={onBlurDescrevaEsportesRadicais.bind(
                    this,
                    false
                  )}
                  usaOculos={usaOculos}
                  onChangeUsaOculos={onChangeUsaOculos.bind(this, false)}
                  descrevaOculos={descrevaOculos}
                  descrevaOculosErro={descrevaOculosErro}
                  onChangeDescrevaOculos={onChangeDescrevaOculos.bind(
                    this,
                    false
                  )}
                  onBlurDescrevaOculos={onBlurDescrevaOculos.bind(this, false)}
                  peso={peso}
                  pesoErro={pesoErro}
                  onChangePeso={onChangePeso.bind(this, false)}
                  onBlurPeso={onBlurPeso.bind(this, false)}
                  altura={altura}
                  alturaErro={alturaErro}
                  onChangeAltura={onChangeAltura.bind(this, false)}
                  onBlurAltura={onBlurAltura.bind(this, false)}
                  fumante={fumante}
                  onChangeFumante={onChangeFumante.bind(this, false)}
                />
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <i className="fas fa-briefcase-medical" />
                <strong>Histórico Familiar</strong>
              </CardHeader>
              <CardBody>
                <Card>
                  <CardHeader>
                    <i className="fas fa-male" />
                    <strong>Pai</strong>
                  </CardHeader>
                  <CardBody>
                    <HistoricoFamiliarCampos
                      nome={nomePai}
                      nomeErro={nomePaiErro}
                      onChangeNome={onChangeNomeFamiliar.bind(
                        this,
                        false,
                        false
                      )}
                      onBlurNome={onBlurNomeFamiliar.bind(this, false, false)}
                      idade={idadePai}
                      idadeErro={idadePaiErro}
                      onChangeIdade={onChangeIdadeFamiliar.bind(
                        this,
                        false,
                        false
                      )}
                      onBlurIdade={onBlurIdadeFamiliar.bind(this, false, false)}
                      onChangeEstadoSaude={onChangeEstadoSaudeFamiliar.bind(
                        this,
                        false,
                        false
                      )}
                      onBlurEstadoSaude={onBlurEstadoSaudeFamiliar.bind(
                        this,
                        false,
                        false
                      )}
                      falecido={falecidoPai}
                      onChangeFalecido={onChangeFalecidoFamiliar.bind(
                        this,
                        false,
                        false
                      )}
                      required={!estaLimpoFamiliar(false, false)}
                      placeholder="Nome do pai"
                    />
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <i className="fas fa-female" />
                    <strong>Mãe</strong>
                  </CardHeader>
                  <CardBody>
                    <HistoricoFamiliarCampos
                      nome={nomeMae}
                      nomeErro={nomeMaeErro}
                      onChangeNome={onChangeNomeFamiliar.bind(
                        this,
                        false,
                        true
                      )}
                      onBlurNome={onBlurNomeFamiliar.bind(this, false, true)}
                      idade={idadeMae}
                      idadeErro={idadeMaeErro}
                      onChangeIdade={onChangeIdadeFamiliar.bind(
                        this,
                        false,
                        true
                      )}
                      onBlurIdade={onBlurIdadeFamiliar.bind(this, false, true)}
                      estadoSaude={estadoSaudeMae}
                      onChangeEstadoSaude={onChangeEstadoSaudeFamiliar.bind(
                        this,
                        false,
                        true
                      )}
                      onBlurEstadoSaude={onBlurEstadoSaudeFamiliar.bind(
                        this,
                        false,
                        true
                      )}
                      falecido={falecidoMae}
                      onChangeFalecido={onChangeFalecidoFamiliar.bind(
                        this,
                        false,
                        true
                      )}
                      required={!estaLimpoFamiliar(false, true)}
                      placeholder="Nome da mãe"
                    />
                  </CardBody>
                </Card>

                {irmaos.map((irmao, idx) => (
                  <Card key={`irmao_${idx}`}>
                    <CardHeader>
                      <i className="fas fa-child" />
                      <strong>Irmão</strong>

                      <div className="card-header-actions">
                        <ButtonGroup>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={onClickBtnAdicionarIrmao.bind(this, false)}
                          >
                            <i className="fas fa-plus" />
                          </Button>
                          <Button
                            color="secondary"
                            size="sm"
                            onClick={onClickBtnRemoverIrmao.bind(
                              this,
                              false,
                              idx
                            )}
                          >
                            <i className="fas fa-minus" />
                          </Button>
                        </ButtonGroup>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <HistoricoFamiliarCampos
                        required={!estaLimpoIrmaos(false)}
                        mostrarCamposIrmao
                        nome={irmao.nome}
                        nomeErro={irmao.nomeErro}
                        onChangeNome={onChangeIrmaoNome.bind(this, false, idx)}
                        onBlurNome={onBlurIrmaoNome.bind(this, false, idx)}
                        idade={irmao.idade}
                        idadeErro={irmao.idadeErro}
                        onChangeIdade={onChangeIrmaoIdade.bind(
                          this,
                          false,
                          idx
                        )}
                        onBlurIdade={onBlurIrmaoIdade.bind(this, false, idx)}
                        estadoSaude={irmao.estadoSaude}
                        estadoSaudeErro={irmao.estadoSaudeErro}
                        onChangeEstadoSaude={onChangeIrmaoEstadoSaude.bind(
                          this,
                          false,
                          idx
                        )}
                        onBlurEstadoSaude={onBlurIrmaoEstadoSaude.bind(
                          this,
                          false,
                          idx
                        )}
                        falecido={irmao.falecido}
                        onChangeFalecido={onChangeIrmaoFalecido.bind(
                          this,
                          false,
                          idx
                        )}
                        numeroFilhos={irmao.numeroFilhos}
                        numeroFilhosErro={irmao.numeroFilhosErro}
                        onChangeNumeroFilhos={onChangeIrmaoNumeroFilhos.bind(
                          this,
                          false,
                          idx
                        )}
                        onBlurNumeroFilhos={onBlurIrmaoNumeroFilhos.bind(
                          this,
                          false,
                          idx
                        )}
                        obs={irmao.obs}
                        onChangeObs={onChangeIrmaoObs.bind(this, false, idx)}
                      />
                    </CardBody>
                  </Card>
                ))}

                {formularioValido === false && (
                  <Alert color="danger" style={{ display: "inline-block" }}>
                    Não foi possível salvar. {formularioErro}
                  </Alert>
                )}
              </CardBody>
              <CardFooter>
                <Button type="submit" color="primary">
                  Salvar
                </Button>
              </CardFooter>
            </Card>
          </Col>

          {temConjuge && (
            <Col sm={12} md={12} lg={6} style={{ flexBasis: "auto" }}>
              <Card>
                <CardHeader>
                  <i className="fas fa-heartbeat" />
                  <strong>Cônjuge</strong>
                </CardHeader>
                <CardBody>
                  <HistoricoSaudeCampos
                    conjuge
                    usaMedicamentos={usaMedicamentosConjuge}
                    onChangeUsaMedicamentos={onChangeUsaMedicamentos.bind(
                      this,
                      true
                    )}
                    descrevaMedicamentos={descrevaMedicamentosConjuge}
                    descrevaMedicamentosErro={descrevaMedicamentosErroConjuge}
                    onChangeDescrevaMedicamentos={onChangeDescrevaMedicamentos.bind(
                      this,
                      true
                    )}
                    onBlurDescrevaMedicamentos={onBlurDescrevaMedicamentos.bind(
                      this,
                      true
                    )}
                    fezCirurgia={fezCirurgiaConjuge}
                    onChangeFezCirurgia={onChangeFezCirurgia.bind(this, true)}
                    descrevaCirurgia={descrevaCirurgiaConjuge}
                    descrevaCirurgiaErro={descrevaCirurgiaErroConjuge}
                    onChangeDescrevaCirurgia={onChangeDescrevaCirurgia.bind(
                      this,
                      true
                    )}
                    onBlurDescrevaCirurgia={onBlurDescrevaCirurgia.bind(
                      this,
                      true
                    )}
                    temDepressao={temDepressaoConjuge}
                    onChangeTemDepressao={onChangeTemDepressao.bind(this, true)}
                    descrevaDepressao={descrevaDepressaoConjuge}
                    descrevaDepressaoErro={descrevaDepressaoErroConjuge}
                    onChangeDescrevaDepressao={onChangeDescrevaDepressao.bind(
                      this,
                      true
                    )}
                    onBlurDescrevaDepressao={onBlurDescrevaDepressao.bind(
                      this,
                      true
                    )}
                    temDiabetes={temDiabetesConjuge}
                    onChangeTemDiabetes={onChangeTemDiabetes.bind(this, true)}
                    descrevaDiabetes={descrevaDiabetesConjuge}
                    descrevaDiabetesErro={descrevaDiabetesErroConjuge}
                    onChangeDescrevaDiabetes={onChangeDescrevaDiabetes.bind(
                      this,
                      true
                    )}
                    onBlurDescrevaDiabetes={onBlurDescrevaDiabetes.bind(
                      this,
                      true
                    )}
                    temFamiliarCancer={temFamiliarCancerConjuge}
                    onChangeTemFamiliarCancer={onChangeTemFamiliarCancer.bind(
                      this,
                      true
                    )}
                    descrevaFamiliarCancer={descrevaFamiliarCancerConjuge}
                    descrevaFamiliarCancerErro={
                      descrevaFamiliarCancerErroConjuge
                    }
                    onChangeDescrevaFamiliarCancer={onChangeDescrevaFamiliarCancer.bind(
                      this,
                      true
                    )}
                    onBlurDescrevaFamiliarCancer={onBlurDescrevaFamiliarCancer.bind(
                      this,
                      true
                    )}
                    praticaEsportesRadicais={praticaEsportesRadicaisConjuge}
                    onChangePraticaEsportesRadicais={onChangePraticaEsportesRadicais.bind(
                      this,
                      true
                    )}
                    descrevaEsportesRadicais={descrevaEsportesRadicaisConjuge}
                    descrevaEsportesRadicaisErro={
                      descrevaEsportesRadicaisErroConjuge
                    }
                    onChangeDescrevaEsportesRadicais={onChangeDescrevaEsportesRadicais.bind(
                      this,
                      true
                    )}
                    onBlurDescrevaEsportesRadicais={onBlurDescrevaEsportesRadicais.bind(
                      this,
                      true
                    )}
                    usaOculos={usaOculosConjuge}
                    onChangeUsaOculos={onChangeUsaOculos.bind(this, true)}
                    descrevaOculos={descrevaOculosConjuge}
                    descrevaOculosErro={descrevaOculosErroConjuge}
                    onChangeDescrevaOculos={onChangeDescrevaOculos.bind(
                      this,
                      true
                    )}
                    onBlurDescrevaOculos={onBlurDescrevaOculos.bind(this, true)}
                    peso={pesoConjuge}
                    pesoErro={pesoErroConjuge}
                    onChangePeso={onChangePeso.bind(this, true)}
                    onBlurPeso={onBlurPeso.bind(this, true)}
                    altura={alturaConjuge}
                    alturaErro={alturaErroConjuge}
                    onChangeAltura={onChangeAltura.bind(this, true)}
                    onBlurAltura={onBlurAltura.bind(this, true)}
                    fumante={fumanteConjuge}
                    onChangeFumante={onChangeFumante.bind(this, true)}
                  />
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <i className="fas fa-briefcase-medical" />
                  <strong>Histórico Familiar do Cônjuge</strong>
                </CardHeader>
                <CardBody>
                  <Card>
                    <CardHeader>
                      <i className="fas fa-male" />
                      <strong>Pai</strong>
                    </CardHeader>
                    <CardBody>
                      <HistoricoFamiliarCampos
                        nome={nomePaiConjuge}
                        nomeErro={nomePaiErroConjuge}
                        onChangeNome={onChangeNomeFamiliar.bind(
                          this,
                          true,
                          false
                        )}
                        onBlurNome={onBlurNomeFamiliar.bind(this, true, false)}
                        idade={idadePaiConjuge}
                        idadeErro={idadePaiErroConjuge}
                        onChangeIdade={onChangeIdadeFamiliar.bind(
                          this,
                          true,
                          false
                        )}
                        onBlurIdade={onBlurIdadeFamiliar.bind(
                          this,
                          true,
                          false
                        )}
                        estadoSaude={estadoSaudePaiConjuge}
                        onChangeEstadoSaude={onChangeEstadoSaudeFamiliar.bind(
                          this,
                          true,
                          false
                        )}
                        onBlurEstadoSaude={onBlurEstadoSaudeFamiliar.bind(
                          this,
                          true,
                          false
                        )}
                        falecido={falecidoPaiConjuge}
                        onChangeFalecido={onChangeFalecidoFamiliar.bind(
                          this,
                          true,
                          false
                        )}
                        required={!estaLimpoFamiliar(true, false)}
                        placeholder="Nome do pai"
                      />
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <i className="fas fa-female" />
                      <strong>Mãe</strong>
                    </CardHeader>
                    <CardBody>
                      <HistoricoFamiliarCampos
                        nome={nomeMaeConjuge}
                        nomeErro={nomeMaeErroConjuge}
                        onChangeNome={onChangeNomeFamiliar.bind(
                          this,
                          true,
                          true
                        )}
                        onBlurNome={onBlurNomeFamiliar.bind(this, true, true)}
                        idade={idadeMaeConjuge}
                        idadeErro={idadeMaeErroConjuge}
                        onChangeIdade={onChangeIdadeFamiliar.bind(
                          this,
                          true,
                          true
                        )}
                        onBlurIdade={onBlurIdadeFamiliar.bind(this, true, true)}
                        estadoSaude={estadoSaudeMaeConjuge}
                        onChangeEstadoSaude={onChangeEstadoSaudeFamiliar.bind(
                          this,
                          true,
                          true
                        )}
                        onBlurEstadoSaude={onBlurEstadoSaudeFamiliar.bind(
                          this,
                          true,
                          true
                        )}
                        falecido={falecidoMaeConjuge}
                        onChangeFalecido={onChangeFalecidoFamiliar.bind(
                          this,
                          true,
                          true
                        )}
                        required={!estaLimpoFamiliar(true, true)}
                        placeholder="Nome da mãe"
                      />
                    </CardBody>
                  </Card>
                  {irmaosConjuge.map((irmao, idx) => (
                    <Card key={`irmao_${idx}`}>
                      <CardHeader>
                        <i className="fas fa-child" />
                        <strong>Irmão</strong>

                        <div className="card-header-actions">
                          <ButtonGroup>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={onClickBtnAdicionarIrmao.bind(
                                this,
                                true
                              )}
                            >
                              <i className="fas fa-plus" />
                            </Button>
                            <Button
                              color="secondary"
                              size="sm"
                              onClick={onClickBtnRemoverIrmao.bind(
                                this,
                                true,
                                idx
                              )}
                            >
                              <i className="fas fa-minus" />
                            </Button>
                          </ButtonGroup>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <HistoricoFamiliarCampos
                          required={!estaLimpoIrmaos(true)}
                          mostrarCamposIrmao
                          nome={irmao.nome}
                          nomeErro={irmao.nomeErro}
                          onChangeNome={onChangeIrmaoNome.bind(this, true, idx)}
                          onBlurNome={onBlurIrmaoNome.bind(this, true, idx)}
                          idade={irmao.idade}
                          idadeErro={irmao.idadeErro}
                          onChangeIdade={onChangeIrmaoIdade.bind(
                            this,
                            true,
                            idx
                          )}
                          onBlurIdade={onBlurIrmaoIdade.bind(this, true, idx)}
                          estadoSaude={irmao.estadoSaude}
                          estadoSaudeErro={irmao.estadoSaudeErro}
                          onChangeEstadoSaude={onChangeIrmaoEstadoSaude.bind(
                            this,
                            true,
                            idx
                          )}
                          onBlurEstadoSaude={onBlurIrmaoEstadoSaude.bind(
                            this,
                            true,
                            idx
                          )}
                          falecido={irmao.falecido}
                          onChangeFalecido={onChangeIrmaoFalecido.bind(
                            this,
                            true,
                            idx
                          )}
                          numeroFilhos={irmao.numeroFilhos}
                          numeroFilhosErro={irmao.numeroFilhosErro}
                          onChangeNumeroFilhos={onChangeIrmaoNumeroFilhos.bind(
                            this,
                            true,
                            idx
                          )}
                          onBlurNumeroFilhos={onBlurIrmaoNumeroFilhos.bind(
                            this,
                            true,
                            idx
                          )}
                          obs={irmao.obs}
                          onChangeObs={onChangeIrmaoObs.bind(this, true, idx)}
                        />
                      </CardBody>
                    </Card>
                  ))}
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
}

export default withRouter(HistoricoSaude);
