import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import validator from "validator";
import "moment/locale/pt-br";
import {
  isPositiveBRL,
  getAxiosAuth,
  fromBRL,
  toBRLValueOnly,
  getTokenClientId,
  getTimestamp, 
  logApp, 
  logClient,
  logFront,
} from "../../../utils";
import { withRouter } from "react-router-dom";
import {
  MESES,
  TIPOS_PROTECOES,
  TIPOS_PROTECOES_COM_CAPITAL_SEGURADO,
  TIPOS_PROTECOES_COM_MES_VENCIMENTO
} from "../../../options";
import {
  FormErrMsg,
  Monetary,
  FileUpload,
  BtnModalRemoverUpload
} from "../../../components";
import BtnDownloadApolice from "./BtnDownloadApolice";
import PropTypes from "prop-types";

function BtnModalFormProtecao(props) {
  const clienteId = getTokenClientId();
  const [aberto, setaAberto] = useState(false);
  const [tipo, setaTipo] = useState("");
  const [tipoErro, setaTipoErro] = useState(null);
  const [seguradora, setaSeguradora] = useState("");
  const [seguradoraErro, setaSeguradoraErro] = useState(null);
  const [premioMensal, setaPremioMensal] = useState("");
  const [premioMensalErro, setaPremioMensalErro] = useState(null);
  const [capitalSegurado, setaCapitalSegurado] = useState("");
  const [capitalSeguradoErro, setaCapitalSeguradoErro] = useState(null);
  const [mesVencimento, setaMesVencimento] = useState("");
  const [documentoApolice, setaDocumentoApolice] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const [formularioValido, setaFormularioValido] = useState(null);
  const [formularioErro, setaFormularioErro] = useState("");
  const [enviarFormulario, setaEnviarFormulario] = useState(null);
  const [continuarInserindo, setaContinuarInserindo] = useState(false);
  const isFirstRun = useRef(true);

  // --- eventos para o modal ---
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
  }, []);

  const limparFormulario = () => {
    // limpando mensagens de erro e validação
    setaTipoErro(null);
    setaSeguradoraErro(null);
    setaPremioMensalErro(null);
    setaCapitalSeguradoErro(null);
    setaFormularioErro(null);
    setaFormularioValido(null);
    setaEnviarFormulario(null);
    setaContinuarInserindo(null);
    setaDocumentoApolice(null);

    // limpando campos
    setaTipo("");
    setaSeguradora("");
    setaPremioMensal("");
    setaCapitalSegurado("");
    setaMesVencimento();
  };

  const popularFormularioEditar = () => {
    setaTipo(props.tipo);
    setaSeguradora(props.seguradora);
    setaPremioMensal(toBRLValueOnly(props.premioMensal));
    setaCapitalSegurado(toBRLValueOnly(props.capitalSegurado));
    setaMesVencimento(props.mesVencimento || "");
    setaDocumentoApolice(props.upload);
  };

  const toggle = e => {
    e && e.stopPropagation();
    setaAberto(!aberto);

    if (!aberto) {
      // limpando estados para props de entrada
      limparFormulario();

      if (props.id) {
        popularFormularioEditar();
      }
    }
  };

  // --- eventos para o formulário ---
  const onSubmitKeepInsertingForm = e => {
    setaContinuarInserindo(true);

    return onSubmitForm(e);
  };

  const onSubmitCloseForm = e => {
    setaContinuarInserindo(false);

    return onSubmitForm(e);
  };

  const onSubmitForm = e => {
    e.preventDefault();

    if (isUploading) {
      return;
    }

    // validando campos
    const tipoValido = validaTipo();
    const seguradoraValida = validaSeguradora();
    const premioMensalValido = validaPremioMensal();
    const capitalSeguradoValido = validaCapitalSegurado();

    if (
      tipoValido &&
      seguradoraValida &&
      premioMensalValido &&
      capitalSeguradoValido
    ) {
      setaFormularioValido(true);
      setaEnviarFormulario(getTimestamp());
    } else {
      setaFormularioErro("Verifique os campos acima.");
      setaFormularioValido(false);
    }
  };

  useEffect(() => {
    const postProtecao = async (axiosInstance, dados) => {
      const uri = `/clientes/${clienteId}/protecoes`;
      let response;

      try {
        response = await axiosInstance.post(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          console.error(err, response);
          const responseApp = await logApp(axiosInstance, err);
          await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
    
          props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
          // alert(err.message);
          return err.message;
        }
      }

      // limpa ou fecha modal
      if (continuarInserindo) {
        limparFormulario();
      } else {
        setaAberto(false);
      }

      props.onAdd && props.onAdd();
    };

    const putProtecao = async (axiosInstance, dados, protecaoId) => {
      const uri = `/clientes/${clienteId}/protecoes/${protecaoId}`;
      let response;

      try {
        response = await axiosInstance.put(uri, dados);
      } catch (err) {
        if (err.response) {
          response = err.response;
          logFront(err, "putProtecao()");
        }
        console.error(err, response);
        const responseApp = await logApp(axiosInstance, err);
        await logClient(axiosInstance,err ,err.response, responseApp.data.trace_id, uri, err.response.status);
  
        props.history.push("/500" + "?trace_id=" + responseApp.data.trace_id);
 
        // alert(err.message);
        return err.message;
      }

      setaAberto(false);
      props.onEdit && props.onEdit(props.id);
    };

    const salvarDados = async () => {
      // preparando dados para enviar
      const dados = {
        tipo,
        seguradora,
        premioMensal: fromBRL(premioMensal),
        capitalSegurado: fromBRL(capitalSegurado),
        mesVencimento: mesVencimento ? mesVencimento : null,
        uploadId: documentoApolice ? documentoApolice.id : null,
        conjuge: props.conjuge
      };

      const axiosInstance = getAxiosAuth(() => {
        return props.history.push("/login");
      });

      if (props.id) {
        await putProtecao(axiosInstance, dados, props.id);
      } else {
        await postProtecao(axiosInstance, dados);
      }
    };

    if (formularioValido) {
      salvarDados();
    }
  }, [enviarFormulario]);

  // --- eventos para o campo Tipo ---
  const onChangeTipo = e => {
    setaTipo(e.target.value);
  };

  const validaTipo = () => {
    if (validator.isEmpty(tipo, { ignore_whitespace: true })) {
      setaTipoErro("Defina um tipo.");
      return false;
    }

    setaTipoErro(false);
    return true;
  };

  useEffect(() => {
    if (aberto && !isFirstRun.current) {
      validaTipo();
    }
  }, [tipo]);

  // --- eventos para o campo Seguradora ---
  const onChangeSeguradora = e => {
    setaSeguradora(e.target.value);
  };

  const validaSeguradora = () => {
    if (validator.isEmpty(seguradora, { ignore_whitespace: true })) {
      setaSeguradoraErro("Campo obrigatório.");
      return false;
    }

    setaSeguradoraErro(false);
    return true;
  };

  const onBlurSeguradora = () => validaSeguradora();

  // --- eventos para o campo Prêmio Mensal ---
  const onChangePremioMensal = e => {
    setaPremioMensal(e.target.value);
  };

  const validaPremioMensal = () => {
    if (validator.isEmpty(premioMensal, { ignore_whitespace: true })) {
      setaPremioMensalErro("Campo obrigatório.");
      return false;
    }

    if (!isPositiveBRL(premioMensal)) {
      setaPremioMensalErro("Valor monetário inválido.");
      return false;
    }

    setaPremioMensalErro(false);
    return true;
  };

  const onBlurPremioMensal = () => validaPremioMensal();

  // --- eventos para o campo Capital Segurado ---
  const onChangeCapitalSegurado = e => {
    setaCapitalSegurado(e.target.value);
  };

  const validaCapitalSegurado = () => {
    if (validator.isEmpty(capitalSegurado, { ignore_whitespace: true })) {
      setaCapitalSeguradoErro(null);
      return true;
    }

    if (!isPositiveBRL(capitalSegurado)) {
      setaCapitalSeguradoErro("Valor monetário inválido.");
      return false;
    }

    setaCapitalSeguradoErro(false);
    return true;
  };

  const onBlurCapitalSegurado = () => validaCapitalSegurado();

  // --- eventos para o campo Mês de vencimento ---
  const onChangeMesVencimento = e => {
    setaMesVencimento(e.target.value);
  };

  // --- evento para upload ---
  const onUploadStart = () => {
    setIsUploading(true);
  };

  const onUploadEnd = async (data) => {
    try {
      setaDocumentoApolice(data.uploadObject);
      setIsUploading(false)
    } catch (error) {
      console.error("Error uploading OFX file:", error);
      logFront(error, "onUploadEnd()");
    }
  };

  const onUploadRemoved = () => {
    setaDocumentoApolice();
  };

  // --- elementos ---
  const trigger = props.id ? (
    <Button size="sm" color="light" style={{ marginRight: 5 }} onClick={toggle}>
      <i className="fas fa-pen" />
    </Button>
  ) : (
    <Button color="primary" size="sm" className="float-right" onClick={toggle}>
      <i className="fa fa-plus" />
      Adicionar
    </Button>
  );

  const exibirCapitalSegurado = !!(
    tipo && TIPOS_PROTECOES_COM_CAPITAL_SEGURADO.indexOf(tipo) >= 0
  );

  const exibirMesVencimento = !!(
    tipo && TIPOS_PROTECOES_COM_MES_VENCIMENTO.indexOf(tipo) >= 0
  );
    
  return (
    <Fragment>
      {trigger}
      <Modal isOpen={aberto} toggle={toggle} fade>
        <ModalHeader>
          {props.id
            ? `Editar Proteção ${props.conjuge ? "de Cônjuge" : ""}`
            : `Adicionar Proteção ${props.conjuge ? "para Cônjuge" : ""}`}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmitForm}>
            <FormGroup>
              <Label className="required">Tipo</Label>
              <Input
                type="select"
                value={tipo}
                onChange={onChangeTipo}
                valid={tipoErro === false}
                invalid={!!tipoErro}
              >
                <option value={""}>Selecione</option>
                {TIPOS_PROTECOES.map(tipo => (
                  <option value={tipo} key={`protecao_tipo_${tipo}`}>
                    {tipo}
                  </option>
                ))}
              </Input>
              <FormErrMsg>{tipoErro}</FormErrMsg>
            </FormGroup>
            <FormGroup>
              <Label>Apólice:</Label>
              <div style={{ display: "block" }}>
                <FileUpload
                  onUploadStart={onUploadStart}
                  onUploadEnd={onUploadEnd}
                  predicado="apólice"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label className="required">Seguradora</Label>
              <Input
                type="text"
                maxLength={255}
                value={seguradora}
                onChange={onChangeSeguradora}
                onBlur={onBlurSeguradora}
                valid={seguradoraErro === false}
                invalid={!!seguradoraErro}
              />
              <FormErrMsg>{seguradoraErro}</FormErrMsg>
            </FormGroup>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label className="required">Prêmio Mensal</Label>
                  <Monetary
                    value={premioMensal}
                    onChange={onChangePremioMensal}
                    onBlur={onBlurPremioMensal}
                    valid={premioMensalErro === false}
                    invalid={!!premioMensalErro}
                  />
                  <FormErrMsg>{premioMensalErro}</FormErrMsg>
                </FormGroup>
              </Col>
              {exibirCapitalSegurado && (
                <Col md={6}>
                  <FormGroup>
                    <Label>Capital Segurado</Label>
                    <Monetary
                      value={capitalSegurado}
                      onChange={onChangeCapitalSegurado}
                      onBlur={onBlurCapitalSegurado}
                      valid={capitalSeguradoErro === false}
                      invalid={!!capitalSeguradoErro}
                    />
                    <FormErrMsg>{capitalSeguradoErro}</FormErrMsg>
                  </FormGroup>
                </Col>
              )}
              {exibirMesVencimento && (
                <Col md={6}>
                  <FormGroup>
                    <Label>Mês de vencimento</Label>
                    <Input
                      type="select"
                      value={mesVencimento}
                      onChange={onChangeMesVencimento}
                    >
                      <option value={null}>Não definido.</option>
                      {MESES.map((mes, i) => (
                        <option value={i + 1} key={`mes_opt_${i}`}>
                          {mes}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              )}
            </Row>
            {documentoApolice && (
              <FormGroup>
                <Label>Apólice:</Label>
                <div style={{ display: "block" }}>
                  <BtnDownloadApolice
                    clienteId={clienteId}
                    uploadId={documentoApolice.id}
                    uploadFilename={documentoApolice.filename}
                  />
                  <BtnModalRemoverUpload
                    id={documentoApolice.id}
                    filename={documentoApolice.filename}
                    predicado="apólice"
                    onDelete={onUploadRemoved}
                  />
                </div>
              </FormGroup>
            )}
            {formularioValido === false && (
              <Alert color="danger">
                Não foi possível salvar proteção. {formularioErro}
              </Alert>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          {!props.id && (
            <Fragment>
              <Button
                color="primary"
                onClick={onSubmitCloseForm}
                disabled={isUploading}
              >
                Salvar e fechar
              </Button>
              <Button
                color="primary"
                onClick={onSubmitKeepInsertingForm}
                disabled={isUploading}
              >
                Salvar e adicionar outra
              </Button>
            </Fragment>
          )}
          {props.id && (
            <Button
              color="primary"
              onClick={onSubmitCloseForm}
              disabled={isUploading}
            >
              Salvar
            </Button>
          )}
          <Button color="link" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}

BtnModalFormProtecao.propTypes = {
  conjuge: PropTypes.bool,
  id: PropTypes.number,
  tipo: PropTypes.string,
  seguradora: PropTypes.string,
  premioMensal: PropTypes.number,
  capitalSegurado: PropTypes.number,
  mesVencimento: PropTypes.number,
  upload: PropTypes.object,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func
};

export default withRouter(BtnModalFormProtecao);
