import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { getAxiosAuth, getTokenClientId, logFront } from "../../utils";

function DropdownAtendimento(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [chamados, setaChamados] = useState([]);
  const [totalChamados, setaTotalChamados] = useState(0);

  const toggle = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const obterResumoChamados = async () => {
    const axiosInstance = getAxiosAuth();
    const clienteId = getTokenClientId();
    const uri = `/clientes/${clienteId}/atendimento/resumo`;

    let response;
    try {
      response = await axiosInstance.get(uri);
    } catch (error) {
      console.error(error);
      logFront(error, "obterResumoChamados()");
      return;
    }

    if (response && response.status === 200) {
      setaChamados(response.data.chamados);
      setaTotalChamados(response.data.totalChamados);
    }
  };

  useEffect(() => {
    obterResumoChamados();

    const handle = setInterval(obterResumoChamados, 30 * 1000);

    return () => clearInterval(handle);
  }, []);

  return (
    <Dropdown nav className="d-xs-down-none" isOpen={isOpen} toggle={toggle}>
      <DropdownToggle nav>
        {isOpen ? (
          <i className="fas fa-envelope-open-text" />
        ) : (
          <i className="fas fa-envelope" />
        )}
        {totalChamados > 0 && (
          <Badge pill color="danger">
            {totalChamados}
          </Badge>
        )}
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-lg">
        <DropdownItem header tag="div" className="text-center">
          <strong>Atendimento</strong>
        </DropdownItem>

        {totalChamados === 0 ? (
          <DropdownItem className="text-center text-muted">
            Sem novos chamados
          </DropdownItem>
        ) : (
          chamados.map((chamado) => (
            <DropdownItem
              key={`chamado_${chamado.id}`}
              onClick={() =>
                props.history.push(`/atendimento/andamento/${chamado.id}`)
              }
            >
              <div className="message">
                <div className="text-truncate font-weight-bold">
                  {chamado.titulo}
                </div>
                <div className="small text-muted text-truncate">
                  {chamado.mensagem}
                </div>
              </div>
            </DropdownItem>
          ))
        )}

        <DropdownItem
          onClick={() => props.history.push("/atendimento")}
          className="text-center"
        >
          <strong>
            <span className="fas fa-inbox" /> Todos os chamados
          </strong>
        </DropdownItem>
        <DropdownItem
          onClick={() => props.history.push("/atendimento/novo-chamado")}
          className="text-center"
        >
          <strong>
            <span className="fas fa-edit" /> Criar novo chamado
          </strong>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default withRouter(DropdownAtendimento);
